const initialState = {
  masterLoaded: false,
  masterLoading: false,
  masterData: [],
};

const masterDataReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "MASTER_LIST_REQUEST":
      return {
        ...state,
        masterLoading: true,
      };
    case "MASTER_LIST_SUCCESS":
      return {
        ...state,
        masterData: payload.masterData,
        masterLoading: false,
        masterLoaded: true,
      };
    case "VADHUVAR_LIST_FAIL":
      return {
        ...state,
        masterLoading: false,
        masterLoaded: false,
      };

    case "MASTER_LIST_CLEAR":
      return {
        ...state,
        masterData: [],
        masterLoading: false,
        masterLoaded: false,
      };

    default:
      return state;
  }
};
export default masterDataReducer;
