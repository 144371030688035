import React, { useState } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import Link from "@mui/material/Link";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Facebook, Instagram } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const Footer = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          marginTop: 2,
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
        component="footer"
      >
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Box display="flex">
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
              >
                <Link
                  component="button"
                  variant="body2"
                  href="/contact"
                  onClick={() => {
                    return navigate("/contact");
                  }}
                >
                  Contact
                </Link>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    return navigate("/term-and-condition");
                  }}
                >
                  Terms & Conditions
                </Link>

                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    return navigate("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </Link>
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={6}>
            {/* <Typography variant="body2" color="text.primary">
            
            </Typography> */}
            <Box display="flex" justifyContent="right">
              Follow Us
              <Link
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                <Facebook />
              </Link>
              <Link
                href="https://www.instagram.com/"
                target="_blank"
                color="inherit"
                sx={{ pl: 1, pr: 1 }}
              >
                <Instagram />
              </Link>
            </Box>
            {/* <Link href="https://www.twitter.com/" color="inherit">
              <Twitter />
            </Link> */}
          </Grid>
          <Grid item lg={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="body2" color="text.secondary">
                This{" "}
                <Link component="button" variant="body2">
                  https://www.vivahmelava.com
                </Link>{" "}
                website is strictly for matrimonial purpose only and not a
                dating website.
                {"Copyright © "} {new Date().getFullYear()} All rights reserved
                {"."}
                <br />
                Powered by : ABNScripts Technologies Pvt. Ltd,Pune
              </Typography>
              <br />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit">Suryavanshi bari </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography> */}
    </>
  );
};

export default Footer;
