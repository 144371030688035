import {
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions/types";

let getuser =
  localStorage.getItem("user") === undefined
    ? null
    : localStorage.getItem("user");
const userData = getuser;
const initialState = userData
  ? { isLoggedIn: true, user: userData, loginLoading: false }
  : { isLoggedIn: false, user: null, loginLoading: false };

export default function(state = initialState, action) {
  const { type, payload } = action;

  // console.log("auth ", action);

  switch (type) {
    case "LOGIN_OTP_REQUEST":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: true,
      };

    case "LOGIN_OTP_SEND_SUCCESS":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: false,
      };
    case "LOGIN_OTP_SEND_FAILED":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: false,
      };
    case "LOGIN_OTP_VERIFICATION_REQUEST":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: true,
      };
    case "LOGIN_OTP_VERIFICATION_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        loginLoading: false,
      };
    case "LOGIN_OTP_VERIFICATION_SUCCESS_TEMP":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: false,
      };
    case "LOGIN_OTP_VERIFICATION_FAILED":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: false,
      };

    case "REGISTER_OTP_REQUEST":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: true,
      };
    case "REGISTER_OTP_SEND_SUCCESS":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: false,
      };
    case "REGISTER_OTP_SEND_FAILED":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: false,
      };

    case "REGISTER_OTP_VERIFICATION_REQUEST":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: true,
      };
    case "REGISTER_OTP_VERIFICATION_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        loginLoading: false,
      };
    case "REGISTER_OTP_VERIFICATION_FAILED":
      return {
        ...state,
        isLoggedIn: false,
        loginLoading: false,
      };
    case "REGISTER_DONE_BUT_VERIFICATION_PENDING":
      return {
        ...state,
        isLoggedIn: true,
        loginLoading: false,
      };

    // case SEND_OTP_SUCCESS:
    //   return {
    //     ...state,
    //     isLoggedIn: false,
    //   };
    case SEND_OTP_FAILED:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
