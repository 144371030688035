const initialState = {
  vadhuVarRedirectionUrl: "",
};

const saveRedirectedUrlReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_VADHU_VAR_REDIRECTION_URL":
      return {
        ...state,
        vadhuVarRedirectionUrl: payload.vadhuVarRedirectionUrl,
      };
    case "CLEAR_VADHU_VAR_REDIRECTION_URL":
      return {
        ...state,
        vadhuVarRedirectionUrl: "",
      };
    default:
      return state;
  }
};
export default saveRedirectedUrlReducer;
