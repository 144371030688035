import React, { useState, useEffect } from "react";
import {
  getOrderIdForPayment,
  saveRazorPayPaymentDetails,
  updateVadhuVarData,
  makePayment,
} from "../../actions/vadhuVar-action";
import { showSuccessSnackbar } from "../../actions/snackbarActions";

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";
import { Backdrop, Card, CardActions, CardContent } from "@mui/material";
import Spinner from "react-spinner-material";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

const RazorpayPayment = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [razorPayDetails, setRazorPayDetails] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("process");
  const objectLocation = useLocation().state;
  const [paraObject, setParaObject] = useState(objectLocation.data);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { registrationFormLoading } = useSelector(
    (state) => state.vadhuVarReducer
  );
  const [configsData, setConfigsData] = useState({});
  const default_profile_images = IMAGE_API_URL + "thumbnail/get-vadhu-var";

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      fetch(`data/configs.json`)
        .then((res) => res.json())
        .then((data) => {
          setConfigsData({ ...data });
        })
        .catch((err) => {});
    }
  }, []);

  const displayRazorpay = async (amount) => {
    let payload = {
      bride_groom_id: paraObject.bride_groom_id,
      payment_type: "VADHU_VAR_REG",
      name: "vinod",
      amount: configsData.phonePay_amount,
      mobileNumber: "9730996626",
    };
    dispatch(makePayment(payload)).then((paymentResponse) => {
      if (paymentResponse.data.payment_status === "PAYMENT_INITIATED") {
        window.location = paymentResponse.data.redirectUrl;
      }
    });
  };
  const gridStyles = {
    marginTop: 10,
  };

  const btnOkGoToMyRegistration = () => {
    return navigate(`/user/my-profile`);
  };

  const savePaymentDatatoDb = (inputData) => {
    dispatch(saveRazorPayPaymentDetails(inputData)).then((res) => {});
  };
  const [openSpinner, setOpenSpinner] = React.useState(true);
  return (
    <>
      {registrationFormLoading ? (
        <>
          {" "}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>{" "}
        </>
      ) : (
        ""
      )}
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            {/* <Button>Left</Button> */}
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button> {t("payment_page.lbl_page_title")}</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right">
            {/* <Button>Right</Button> */}
          </Box>
        </Grid>
      </Grid>

      <Box style={gridStyles}>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} md={12}>
            {paymentStatus == "process" ? (
              <>
                <Card sx={{ maxWidth: "100%" }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {t("payment_page.lbl_page_title")}
                      {" - "}
                      {configsData && configsData.phonePay_amount + ".00 Rs"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("payment_page.lbl_information")}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={displayRazorpay}
                    >
                      {t("payment_page.lbl_make_a_payment")}
                    </Button>
                  </CardActions>
                </Card>
              </>
            ) : (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Box display="flex" justifyContent="center">
                    {paymentStatus == "success" ? (
                      <>
                        {paraObject.sourcePage == "VADHU_VAR_REG" ? (
                          <>
                            <Typography variant="body1" gutterBottom>
                              {t(
                                "matrimony_registration_page.lbl_success_message"
                              ) + paraObject.reg_number}
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body1" gutterBottom>
                            {t(
                              "matrimony_registration_page.lbl_payment_successfully_done"
                            )}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography variant="body1" gutterBottom>
                          {t("matrimony_registration_page.lbl_payment_failed") +
                            paraObject.reg_number}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        let data = {
                          refresh_or_clear: "refresh",
                        };
                        dispatch(updateVadhuVarData(data)).then(() => {
                          return navigate(`/user/my-profile`);
                        });
                      }}
                    >
                      {t("payment_page.lbl_btn_ok")}
                    </Button>
                  </Box>
                </Grid>{" "}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RazorpayPayment;
