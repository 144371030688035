import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  makeStyles,
} from "@material-ui/core";
//import Post from "./Post";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { createReview, getReview } from "../../actions/reviews-action";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    //padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    //padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  boxContainer: {
    display: "flex",
    gap: 20,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));

const ReviewAndRating = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(4.5);
  const [open, setOpen] = React.useState(false);
  const [OpenDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(
    false
  );

  const [userReview, setUserReview] = React.useState("");
  const [allRating, setAllRating] = React.useState(4.5);
  const [userRating, setUserRating] = React.useState(0);
  const dispatch = useDispatch();
  const [userReviews, setUserReviews] = useState([]);
  const [userReviewDetails, setUserReviewDetails] = useState();
  const [refreshKey, setRefreshKey] = useState(1);

  const [isWriteReviewEnable, setIsWriteReviewEnable] = useState(true);

  const handleClickBtnEdit = (selectedUser, actionType) => {
    console.log(actionType);

    console.log(selectedUser);

    //   {
    //     "id": 4,
    //     "user_id": 320,
    //     "rating": 4,
    //     "user_review": "testtt",
    //     "admin_review": null,
    //     "created_by": null,
    //     "reviewe_admin_name": "Admin",
    //     "review_user_name": "V Bhagat",
    //     "created_at": "25 Oct 2023 11:28 AM",
    //     "updated_at": null,
    //     "admin_review_created_at": null,
    //     "is_admin": 0,
    //     "is_edit": 1
    // }

    setUserReview(selectedUser.user_review);
    setUserRating(selectedUser.rating);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickAddReview = () => {
    setUserRating(0);
    setUserReview("");
    setIsWriteReviewEnable(true);
    setOpen(true);
  };

  const handleClickOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };
  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const btnEditReview = () => {
    setOpen(true);
  };

  const btnDeleteReviewConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };
  const handleChangeReview = (e) => {
    console.log(e);

    setUserReview({ value: e.target.value });
  };

  const handleSubmitReview = () => {
    let review_id = 0;
    if (!isWriteReviewEnable) {
      review_id = userReviewDetails["id"];
    }
    let requestPayload = {
      rating: userRating,
      user_review: userReview,
      review_id: review_id,
    };

    dispatch(createReview(requestPayload, t)).then((res) => {
      console.log("res", res);
      setOpen(false);
      getReviews(1);

      // dispatch(
      //   showSuccessSnackbar({
      //     hideDuration: 10000,
      //     status: "success",
      //     message:
      //       t("matrimony_registration_page.lbl_success_message") +
      //       " " +
      //       res.reg_number.toString(),
      //   })
      // );
    });
  };

  useEffect(() => {
    getLoginUserInfo();
  }, [refreshKey]);

  const getLoginUserInfo = () => {
    const promiseResult = new Promise((resolve, reject) => {
      const getLoginUserDetails = localStorage.getItem("user");
      if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
        let userObject = {};
        userObject = JSON.parse(getLoginUserDetails);
        resolve(userObject);
      } else {
        resolve();
      }
    });
    promiseResult.then((result) => {
      if (result) {
        getReviews(1);
      } else {
        getReviews(0);
      }
    });
  };

  const getReviews = (isLoginedUser) => {
    let requestPayload = {
      is_auth: isLoginedUser,
    };
    dispatch(getReview(requestPayload, t)).then((data) => {
      if (data["allReviews"].length > 0) {
        setUserReviews(data["allReviews"]);
      }
      if (data["userReviewDetails"].length > 0) {
        setIsWriteReviewEnable(false);
        setUserReviewDetails(data["userReviewDetails"][0]);
      }
    });
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} md={12}>
            <Item>
              <Typography component="legend">Ratings and reviews</Typography>
              <Typography variant="h6" gutterBottom>
                {allRating}
              </Typography>
              <Rating
                name="allRating"
                value={allRating}
                precision={0.5}
                size="large"
                readOnly
                // onChange={(event, newValue) => {
                //   setAllRating(newValue);
                // }}
              />
              <br />
              {isWriteReviewEnable ? (
                <>
                  {" "}
                  <Button
                    size="medium"
                    variant="outlined"
                    onClick={handleClickAddReview}
                  >
                    Write review
                  </Button>{" "}
                </>
              ) : (
                <> </>
              )}
            </Item>
          </Grid>
          {userReviewDetails ? (
            <>
              <Grid item lg={12} xs={12} md={12}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={userReviewDetails.review_user_name}
                        src="/static/images/avatar/1.jpg"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={userReviewDetails.review_user_name}
                      secondary={
                        <React.Fragment>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 3,
                            }}
                          >
                            <div>
                              <Rating
                                name="userRating"
                                readOnly
                                value={userReviewDetails.rating}
                              />
                            </div>
                            <div>{userReviewDetails.created_at}</div>
                          </Box>
                          {userReviewDetails.user_review}

                          {userReviewDetails.admin_review_created_at != null ? (
                            <>
                              <ListItemText
                                primary="Admin"
                                secondary={
                                  <React.Fragment>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 3,
                                      }}
                                    >
                                      <div>
                                        {
                                          userReviewDetails.admin_review_created_at
                                        }
                                      </div>
                                    </Box>
                                    {userReviewDetails.admin_review}

                                    {userReviewDetails.is_edit == 1 ? (
                                      <>
                                        {" "}
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: 2,
                                          }}
                                        >
                                          <div>
                                            <Button
                                              size="small"
                                              variant="outlined"
                                              onClick={handleClickOpen}
                                            >
                                              Edit
                                            </Button>
                                          </div>
                                          <div>
                                            <Button
                                              size="small"
                                              variant="outlined"
                                              onClick={
                                                btnDeleteReviewConfirmation
                                              }
                                            >
                                              Delete
                                            </Button>
                                          </div>
                                        </Box>
                                      </>
                                    ) : (
                                      <> </>
                                    )}
                                  </React.Fragment>
                                }
                              />
                            </>
                          ) : (
                            <></>
                          )}

                          {userReviewDetails.is_edit == 1 ? (
                            <>
                              {" "}
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                }}
                              >
                                <div>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() =>
                                      handleClickBtnEdit(
                                        userReviewDetails,
                                        "update"
                                      )
                                    }
                                  >
                                    Edit
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={btnDeleteReviewConfirmation}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </Box>
                            </>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <Divider />
                </List>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item lg={12} xs={12} md={12}>
            {userReviews &&
              userReviews.map((review) => (
                <>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={review.review_user_name}
                          src="/static/images/avatar/1.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={review.review_user_name}
                        secondary={
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 3,
                              }}
                            >
                              <div>
                                <Rating
                                  name="userRating"
                                  readOnly
                                  value={review.rating}
                                />
                              </div>
                              <div>{review.created_at}</div>
                            </Box>
                            {review.user_review}

                            {review.is_admin == 1 ? (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                  }}
                                >
                                  <div>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      //onClick={handleClickOpen}

                                      onClick={() =>
                                        handleClickBtnEdit(review, "update")
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                  <div>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={btnDeleteReviewConfirmation}
                                    >
                                      Delete
                                    </Button>
                                  </div>

                                  <div>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() =>
                                        handleClickBtnEdit(review, "reply")
                                      }
                                    >
                                      Reply
                                    </Button>
                                  </div>
                                </Box>
                              </>
                            ) : (
                              <></>
                            )}

                            {review.admin_review_created_at != null ? (
                              <>
                                <ListItemText
                                  primary="Admin"
                                  secondary={
                                    <React.Fragment>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: 3,
                                        }}
                                      >
                                        <div>
                                          {/* <Rating name="simple-controlled" value={value} /> */}
                                        </div>
                                        <div>
                                          {review.admin_review_created_at}
                                        </div>
                                      </Box>
                                      {review.admin_review}

                                      {review.is_admin == 1 ? (
                                        <>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: 2,
                                            }}
                                          >
                                            <div>
                                              <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={handleClickOpen}
                                              >
                                                Edit
                                              </Button>
                                            </div>
                                            <div>
                                              <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={
                                                  btnDeleteReviewConfirmation
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </Box>
                                        </>
                                      ) : (
                                        <> </>
                                      )}
                                    </React.Fragment>
                                  }
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        }
                      />
                    </ListItem>

                    <Divider />
                  </List>
                </>
              ))}
          </Grid>
        </Grid>
      </Box>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{
            "& .MuiTextField-root": { m: 0.5, width: "100%" },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Rating & Review
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12} md={12}>
                <Rating
                  name="simple-controlled"
                  value={userRating}
                  onChange={(event, newValue) => {
                    setUserRating(newValue);
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12} md={12}>
                <TextField
                  value={userReview}
                  // onChange={handleChangeReview}
                  id="txtReview"
                  label="Write review"
                  variant="outlined"
                  multiline={true}
                  onChange={(event) => setUserReview(event.target.value)}
                  rows={3}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" autoFocus onClick={handleSubmitReview}>
              Submit
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <div>
        {/* delete confimation */}
        <BootstrapDialog
          onClose={handleCloseDeleteConfirmation}
          aria-labelledby="customized-dialog-title"
          open={OpenDeleteConfirmation}
          sx={{
            "& .MuiTextField-root": { m: 0.5, width: "100%" },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Confirmation
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteConfirmation}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12} md={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Are you sure you want to delete review?
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Ok
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ReviewAndRating;
