import {
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAIL,
  STORE_CREATE,
  STORE_UPDATE,
  STORE_DELETE,
  STORE_ENABLE_DISABLE,
} from "../actions/types";
const initialState = {
  loaded: false,
  loading: false,
  error: false,
  stores: [],
  responseMessage: {},
};

const storeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STORE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STORE_LIST_SUCCESS:
      return {
        ...state,
        stores: payload.stores,
        error: false,
        loading: false,
        loaded: true,
      };
    case STORE_LIST_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case STORE_DELETE:
      return {
        ...state,
        error: false,
        loading: false,
        loaded: false,
      };
    case STORE_CREATE:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case STORE_UPDATE:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case STORE_ENABLE_DISABLE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default storeReducer;
