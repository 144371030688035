import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  makeStyles,
  Grid,
  Snackbar,
  LinearProgress,
  Box,
  AppBar,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { SRLWrapper } from "simple-react-lightbox";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import ToggleButton from "@mui/material/ToggleButton";
import { useNavigate } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import * as appConstant from "../../helpers/appConstant";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Avatar,
} from "@material-ui/core";
import Rightbar from "../PublicNoAuth/Rightbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";
//import SwipeableTextMobileStepper from "../../components/SwipeableTextMobileStepper";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import {
  approveReject,
  enableDisable,
  wishListAddRemove,
  approveRejectProfileImage,
} from "../../actions/vadhuVar-action";
import { userAction } from "../../actions/users-action";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertTitle from "@mui/material/AlertTitle";
import MuiAlert from "@material-ui/lab/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import configData from "../../config/configData.json";
import CropEasy from "../crop/CropEasy";
import fileUploderServices from "../../services/file-uploder-services";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { spacing } from "@mui/system";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { red } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  avatarImage: {
    width: 250,
    height: 250,
    borderRadius: 10,
  },
  custom: {
    fontWeight: "bold",
  },
  appTitleheader: {
    background: "#cfd8dc",
  },
}));

const UserDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAdd, setIsAdd] = useState(true);
  const vadhuVarObjectLocation = useLocation().state;
  const [vadhuVarObject, setVadhuVarObject] = React.useState(
    vadhuVarObjectLocation.data
  );

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(false);
  const [actionMessage, setActionMessage] = React.useState({});
  const [response, setResponse] = useState();

  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [progressFlag, setProgressFlag] = useState(false);
  const [fileUploaded, setFileUploaded] = useState({});
  const [isChangeProfilePhoto, setIsChangeProfilePhoto] = useState(false);
  const [isPaymentEnable, setIsPaymentEnable] = useState(false);
  const colorRed = red["500"];
  var imgType =
    vadhuVarObject.registration_status == appConstant.ACTION_APPROVED ? 1 : 0;
  if (imgType == 1) {
    if (vadhuVarObject.is_profile_img_approved == 0) {
      imgType =
        vadhuVarObjectLocation.parentRoute == "/user/bride-groom-list" ? 1 : 0;
    }
  }
  const imgPath = "get-vadhu-var";
  //?type=" + imgType + "&id=";
  const default_profile_images = IMAGE_API_URL + imgPath;

  useEffect(() => {
    paymentModule();
    // yuo can find all params from here
    if (vadhuVarObject !== undefined) {
    }
  }, []);

  function handleClick(event) {
    event.preventDefault();
  }
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const options = {
    icons: {
      autoplayIcon: null,
      downloadIcon: false,
    },

    settings: {
      //overlayColor: "rgb(25, 136, 124)",
      //  overlayColor: "rgb(225, 225, 230)",
      overlayColor: "#fafafa",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "#1b5245",
      iconColor: "rgba(126, 172, 139, 0.8)",
      showDownloadButton: false,
      showAutoplayButton: false,
    },
    caption: {
      captionColor: "#a6cfa5",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  };

  const action = () => {
    setOpen(true);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const [open, setOpen] = React.useState(false);
  const [remark, setRemark] = useState({
    value: "",
  });

  const handleChangeRemark = (e) => {
    setRemark({ value: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const paymentModule = () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (JSON.parse(userDetails.feature.isPaymentModuleEnable)) {
      setIsPaymentEnable(true);
    } else {
      setIsPaymentEnable(false);
    }
  };

  const submitActionData = () => {
    if (true) {
      //var rejected_remark = "";
      // if (actionMessage.action == appConstant.ACTION_PENDING) {
      // } else if (actionMessage.action == appConstant.ACTION_APPROVED) {
      //   rejected_remark = vadhuVarObject.image_path;
      // } else if (actionMessage.action == appConstant.ACTION_REJECTED) {
      //   rejected_remark = remark.value;
      // }
      let data = {
        user_details_id: vadhuVarObject.id,
        mobile_or_email: vadhuVarObject.mobile,
        acton_data: actionMessage.action,
        action_type: actionMessage.actionType,
        acton_remark: rejected_remark,
      };
      dispatch(userAction(data))
        .then((res) => {
          return navigate(vadhuVarObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    } else if (
      actionMessage.actionType == appConstant.ACTIONT_TYPE_ENABLE_DISABLE
    ) {
      let data = {
        bride_groom_id: vadhuVarObject.id,
        acton_name: actionMessage.action,
        registration_number: vadhuVarObject.registration_number,
        action_type: actionMessage.actionType,
      };
      dispatch(enableDisable(data))
        .then((res) => {
          return navigate(vadhuVarObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    } else if (
      actionMessage.actionType == appConstant.ACTIONT_TYPE_PROFILE_PHOTO
    ) {
      var rejected_remark = "";
      if (actionMessage.action == appConstant.ACTION_PENDING) {
      } else if (actionMessage.action == appConstant.ACTION_APPROVED) {
        rejected_remark = vadhuVarObject.image_path;
      } else if (actionMessage.action == appConstant.ACTION_REJECTED) {
        rejected_remark = remark.value;
      }
      let data = {
        bride_groom_id: vadhuVarObject.id,
        acton_name: actionMessage.action,
        registration_number: vadhuVarObject.registration_number,
        acton_remark: rejected_remark,
        action_type: actionMessage.actionType,
      };
      dispatch(approveRejectProfileImage(data))
        .then((res) => {
          return navigate(vadhuVarObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid></Grid>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12} md={12}>
                {actionMessage.confirmation}
              </Grid>
              {actionMessage &&
              actionMessage.action == appConstant.ACTION_REJECTED ? (
                <Grid item lg={12} xs={12} md={12}>
                  <TextField
                    id="txtRemark"
                    onChange={handleChangeRemark}
                    // id="outlined-basic"
                    label="Enter remark"
                    variant="outlined"
                    fullWidth
                    multiline={true}
                    rows={2}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<HighlightOffIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={submitActionData}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Root>
        <Grid
          container
          spacing={2}
          style={{
            background: "#cfd8dc",
          }}
        >
          <Grid item lg={2} xs={2} md={2}>
            <Box display="flex" justifyContent="left">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  return navigate(vadhuVarObjectLocation.parentRoute);
                }}
              >
                Back
              </Button>
            </Box>
          </Grid>
          <Grid item lg={8} xs={8} md={8}>
            <Box display="flex" justifyContent="center">
              <Button> User details</Button>
            </Box>
          </Grid>
          <Grid item lg={2} xs={2} md={2}>
            <Box display="flex" justifyContent="right">
              {vadhuVarObject.is_edit ? (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    return navigate(`/user/create-profile`, {
                      state: {
                        data: vadhuVarObjectLocation,
                        childRoute: window.location.pathname,
                      },
                    });
                  }}
                >
                  Edit
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item lg={9} xs={12} md={12}>
            <SRLWrapper options={options}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item lg={6} xs={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        className={classes.custom}
                        style={{ display: "inline-block" }}
                      >
                        Region
                      </Typography>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        {vadhuVarObject.region_name}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline-block" }}
                      >
                        City or village
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        {vadhuVarObject.city_name}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        className={classes.custom}
                        style={{ display: "inline-block" }}
                      >
                        First name:
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        {vadhuVarObject.first_name}
                      </Typography>
                    </Grid>

                    <Grid item lg={6} xs={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline-block" }}
                      >
                        Last name
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        {vadhuVarObject.last_name}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline-block" }}
                      >
                        Mobile or email-id
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        {vadhuVarObject.mobile}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} xs={6} md={6}>
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline-block" }}
                      >
                        Verified
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        <Button size="small" color="primary">
                          {vadhuVarObject.is_verified == 0
                            ? "Pending"
                            : vadhuVarObject.is_verified == 1
                            ? "Yes"
                            : "No"}
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item lg={3} xs={6} md={6}>
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline-block" }}
                      >
                        Status
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        <Button size="small" color="primary">
                          {vadhuVarObject.row_status == 0
                            ? "Deactive"
                            : "Active"}
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline-block" }}
                      >
                        Created Date
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        {vadhuVarObject.created_on}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline-block" }}
                      >
                        Is Bride Groom
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        gutterBottom
                        style={{ display: "inline-block", marginLeft: 6 }}
                      ></Typography> */}

                      <Typography
                        variant="body2"
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        <Button size="small" color="primary">
                          {vadhuVarObject.is_bride_groom_access == 0
                            ? "No"
                            : "Yes"}
                        </Button>
                      </Typography>

                      <Typography
                        variant="body2"
                        style={{ display: "inline-block", marginLeft: 6 }}
                      >
                        <Button
                          variant="outlined"
                          color="#2196f3"
                          size="small"
                          startIcon={<AddTaskIcon />}
                          onClick={() => {
                            let statuname =
                              vadhuVarObject.is_bride_groom_access == 1
                                ? "remove vadhu var listing page access"
                                : "give vadhu var listing page access";

                            let message =
                              "Are you sure you want to " + statuname + " ?";
                            let data = {
                              action:
                                vadhuVarObject.is_bride_groom_access == 1
                                  ? 0
                                  : 1,
                              confirmation: message,
                              actionType:
                                appConstant.ACTIONT_TYPE_IS_VADHU_VAR_PAGE_ACCESS,
                            };
                            setActionMessage(data);
                            action();
                          }}
                        >
                          {vadhuVarObject.is_bride_groom_access == 0
                            ? "Provide access"
                            : "Remove access"}
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                  {vadhuVarObject.is_approval ? (
                    <>
                      <Grid item lg={12} xs={12} md={12}>
                        <Divider></Divider>
                        <Box display="flex" justifyContent="center">
                          <CardActions>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<CheckCircleOutlineIcon />}
                              onClick={() => {
                                let data = {
                                  action: 1,
                                  confirmation:
                                    "Are you sure you want to approve ?",
                                  actionType:
                                    appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                                };
                                setActionMessage(data);
                                action();
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              startIcon={<HighlightOffIcon />}
                              onClick={() => {
                                let data = {
                                  action: 2,
                                  confirmation:
                                    "Are you sure you want to reject ?",
                                  actionType:
                                    appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                                };
                                setActionMessage(data);
                                action();
                              }}
                            >
                              Reject
                            </Button>

                            <Button
                              variant="outlined"
                              color="#2196f3"
                              size="small"
                              startIcon={<AddTaskIcon />}
                              onClick={() => {
                                let statuname =
                                  vadhuVarObject.row_status == 1
                                    ? "deactive"
                                    : "active";

                                let message =
                                  "Are you sure you want to " +
                                  statuname +
                                  " ?";
                                let data = {
                                  action:
                                    vadhuVarObject.row_status == 1 ? 0 : 1,
                                  confirmation: message,
                                  actionType:
                                    appConstant.ACTIONT_TYPE_ENABLE_DISABLE,
                                };
                                setActionMessage(data);
                                action();
                              }}
                            >
                              {vadhuVarObject.row_status == 0
                                ? "Active"
                                : "Deactive"}
                            </Button>
                          </CardActions>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </CardContent>
              </Card>
            </SRLWrapper>
          </Grid>
        </Grid>
      </Root>
    </>
  );
};

export default UserDetails;
