import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CardActions, Grid, makeStyles } from "@material-ui/core";
import BusinessListTemplate from "./BusinessListTemplate";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovedBusiness,
  businessListClear,
} from "../../actions/business-action";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { getMasterData } from "../../actions/master-data-action";
import Spinner from "react-spinner-material";
import Backdrop from "@mui/material/Backdrop";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  marginTopGrid: {
    marginTop: "10px",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const BusinessList = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );

  const [openSpinner, setOpenSpinner] = React.useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const {
    businessRegionId,
    businessLoaded,
    businessListReducer,
    businessLoading,
  } = useSelector((state) => state.businessReducer);

  const [businessList, setBusinessList] = useState(businessListReducer);
  const [regionList, setRegionList] = useState(1);
  const [region, setRegion] = React.useState(businessRegionId);
  const [getMasters, setMasters] = useState();

  const IMAGE_API_URL =
    process.env.REACT_APP_ENV == "DEV"
      ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
      : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

  const banner_image = IMAGE_API_URL + "thumbnail/banner?type=1&id=";

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      getLoginUserInfo();
      pageLoad();
    }
  }, []);
  const pageLoad = () => {
    let requestData = {
      mobile: 1,
      region_id: region == "" ? 0 : region,
    };
    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }
  };

  const getBusinessList = (inputRequestData) => {
    dispatch(getApprovedBusiness(inputRequestData)).then((res) => {
      setBusinessList(res);
    });
  };
  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];
    setRegionList(region_list);
  };
  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
    dispatch({
      type: "SET_BUSINESS_LIST_REGION_ID",
      payload: { selected_region: event.target.value },
    });
    dispatch({
      type: "BUSINESS_LIST_CLEAR",
    });

    setTimeout(function() {
      let requestData = {
        mobile_no: "1",
        region_id: event.target.value,
      };
      getBusinessList(requestData);
    }, 1000);
  };

  const getLoginUserInfo = () => {
    const promiseResult = new Promise((resolve, reject) => {
      const getLoginUserDetails = localStorage.getItem("user");
      if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
        let userObject = {};
        userObject = JSON.parse(getLoginUserDetails);
        resolve(userObject);
      }
    });
    promiseResult.then((result) => {
      setRegion(result.region_id);

      if (businessLoaded == false) {
        let requestData = {
          mobile_no: "1",
          region_id: result.region_id,
        };
        getBusinessList(requestData);
      }
    });
  };
  return (
    <>
      {businessLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>
        </>
      ) : (
        ""
      )}
      <Grid container spacing={2}>
        <Grid item lg={4} xs={4} md={4}>
          <Box display="flex" justifyContent="left">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Region</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={region}
                label="Region"
                variant="outlined"
                color="primary"
                onChange={handleChangeRegion}
              >
                <MenuItem key="0" value="0">
                  Select-All
                </MenuItem>
                {Object.keys(regionList).map((item, pos) => {
                  return (
                    <MenuItem key={pos} value={item}>
                      {regionList[item]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="right">
            <Button
              size="small"
              color="primary"
              onClick={() => {
                return navigate("/business-create");
              }}
            >
              Register your business
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div className={classes.marginTopGrid}>
        {businessList.length == 0 ? (
          <>
            <Grid item lg={12} xs={12} md={12}>
              <Alert variant="outlined" severity="warning">
                {regionList[region]} : No data found.
              </Alert>
            </Grid>
          </>
        ) : (
          ""
        )}

        {businessList.map((item) => (
          <BusinessListTemplate
            businessObject={item}
            // img="https://images.pexels.com/photos/7319337/pexels-photo-7319337.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            img={banner_image + item.banner_image}
          />
        ))}
      </div>
    </>
  );
};

export default BusinessList;
