import React, { useState, useCallback, useEffect } from "react";
import { Container, makeStyles, Grid, Box } from "@material-ui/core";
import { photos } from "./photos";
import { SRLWrapper } from "simple-react-lightbox";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import Post from "./Post";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const PhotoGalleryComponent = () => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {}, 1000);
  });
  const elements = [
    {
      src: "https://my/image.jpg",
      caption: "Lorem ipsum dolor sit amet",
      width: 1920,
      height: "auto",
    },
    {
      src: "https://my/second-image.jpg",
      thumbnail: "https://my/second-image-thumbnails.jpg",
      caption: "Commodo commodo dolore",
      width: 1024,
      height: "auto",
    },
    {
      src: "https://vimeo.com/458698330",
      thumbnail:
        "https://www.simple-react-lightbox.dev/docs/gallery/thumbnails/unsplash05.jpg",
      caption: "Vimeo video",
      autoplay: false,
      showControls: true,
    },
  ];
  const options = {
    settings: {
      //overlayColor: "rgb(25, 136, 124)",
      //  overlayColor: "rgb(225, 225, 230)",
      overlayColor: "#fafafa",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "#1b5245",
      iconColor: "rgba(126, 172, 139, 0.8)",
    },
    caption: {
      captionColor: "#a6cfa5",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  };
  const itemData = [
    {
      img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      title: "Breakfast",
    },
    {
      img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      title: "Burger",
    },
    {
      img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      title: "Camera",
    },
    {
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Coffee",
    },
    {
      img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      title: "Hats",
    },
    {
      img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      title: "Honey",
    },
    {
      img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      title: "Basketball",
    },
    {
      img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      title: "Fern",
    },
    {
      img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      title: "Mushrooms",
    },
    {
      img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      title: "Tomato basil",
    },
    {
      img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      title: "Sea star",
    },
    {
      img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      title: "Bike",
    },
  ];
  return (
    <>
      <Container className={classes.container}>
        {/* <Grid container spacing={3}> */}
        {/* <Grid item >
            <Post
              title="Choose the perfect design"
              img="https://images.pexels.com/photos/7319337/pexels-photo-7319337.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            />
            <Post
              title="Simply Recipes Less Stress. More Joy"
              img="https://images.pexels.com/photos/7363671/pexels-photo-7363671.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            />
            <Post
              title="What To Do In London"
              img="https://images.pexels.com/photos/7245535/pexels-photo-7245535.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            />
          </Grid> */}

        {/* {photos.map((item) => (
            <Grid item sx={3}>
              <Card
                sx={{
                  maxWidth: 345,
                  margin: 2,
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="150"
                    image={item.src}
                    alt="green iguana"
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))} */}
        {/* </Grid> */}
        <SRLWrapper options={options}>
          <ImageList cols={4}>
            {photos.map((item) => (
              <ImageListItem key={item.src}>
                <img
                  src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                  maxWidth="345"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </SRLWrapper>
        {/* <SRLWrapper options={options}>
          <div className={classes.dir1}>
            {photos.map((item) => (
              <Card
                sx={{
                  maxWidth: 250,
                  // display: "flex", direction: "row",
                  margin: 2,
                  // display: "flex",
                  // flexDirection: "column",
                  // flexGrow: 1,
                  // flexDirection: "column",
                  //alignItems: "stretch",
                }}
              >
                <CardActionArea
                // className={classes.cardActionArea}
                // style={{
                //   flexGrow: 1,
                //   flexDirection: "column",
                //   alignItems: "stretch",
                // }}
                >
                  <CardMedia
                    component="img"
                    height="150"
                    image={item.src}
                    alt="green iguana"
                  />
                </CardActionArea>
              </Card>
            ))}
          </div>
        </SRLWrapper> */}
      </Container>
    </>
  );
};

export default PhotoGalleryComponent;
