import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Paper, Avatar } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "../card.css";

import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";

import { getMyPostedJob } from "../../actions/job-actions";

//import configData from "../../config/configData.json";
import { Alert, AlertTitle } from "@mui/material";
import * as appConstant from "../../helpers/appConstant";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Spinner from "react-spinner-material";
import Backdrop from "@mui/material/Backdrop";
import PostAddIcon from "@mui/icons-material/PostAdd";
const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  avatarImage: {
    width: 200,
    height: 200,
    borderRadius: 100,
  },
  marginTopGrid: {
    marginTop: "10px",
  },
}));

const MyJobPost = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(1);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const { vadhuVar, loading, loaded } = useSelector(
    (state) => state.vadhuVarApprovalReducer
  );
  const [openSpinner, setOpenSpinner] = React.useState(true);
  const { commonLoading } = useSelector((state) => state.commonLoadingReducer);

  var userObject = {};
  const getLoginUserDetails = localStorage.getItem("user");
  if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
    userObject = JSON.parse(getLoginUserDetails);
  }
  const [user, setUser] = useState(userObject);
  const default_profile_images = IMAGE_API_URL + "thumbnail/get-vadhu-var";

  const columns = [
    { field: "title", headerName: "Title", width: 300, editable: true },
    {
      field: "company_name",
      headerName: "Company name",
      width: 250,
      editable: true,
    },
    {
      field: "education",
      headerName: "Education",
      width: 150,
      editable: true,
    },
    {
      field: "job_location",
      headerName: "Location",
      width: 100,
      editable: true,
    },
    {
      field: "employment_type_name",
      headerName: "Employment",
      width: 150,
      editable: true,
    },
    {
      headerName: "Connect",
      width: 150,
      renderCell: (cellValues) => {
        let mobile = "+91" + cellValues.row.contact_person_number;
        return (
          <>
            <Button
              startIcon={<WhatsAppIcon fontSize="small" />}
              color="primary"
              size="small"
              onClick={() => {
                window.open("https://wa.me/" + mobile, "_blank");
              }}
            >
              {cellValues.row.contact_person_number}
            </Button>
          </>
        );
      },
    },
    {
      field: "View",
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(event) => {
                handleView(cellValues);
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [profileNeedToApprove, setProfileNeedToApprove] = useState(0);

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    }
    let requestData = {
      mobile_no: "1",
    };
    getPostedJobList(requestData);
  }, [refreshKey]);

  const getPostedJobList = (inputRequestData) => {
    dispatch(getMyPostedJob(inputRequestData)).then((res) => {
      setItems(res);
    });
  };

  const handleView = (selectedRow) => {
    navigate(`/view-posted-job`, {
      state: { data: selectedRow.row, parentRoute: window.location.pathname },
    });
  };

  const manageCount = (inputList) => {
    // let approvedItem = inputList.filter((element) => {
    //   return element;
    // });
    // setApproved(approvedItem.length);
    // let pendingItem = inputList.filter((element) => {
    //   return element;
    // });
    // setPending(pendingItem.length);
    // let rejectedItem = inputList.filter((element) => {
    //   return element.registration_status === "Rejected";
    // });
    // setRejected(rejectedItem.length);
    // let profileNeedToApproveItem = inputList.filter((element) => {
    //   return parseInt(element.is_profile_img_approved) === 0;
    // });
    // setProfileNeedToApprove(profileNeedToApproveItem.length);
  };

  const handleTabPending = (e) => {
    // if (Object.keys(vadhuVar).length != 0) {
    //   let updatedItem = vadhuVar.filter((element) => {
    //     return element.registration_status === "Pending";
    //   });
    //   setItems(updatedItem);
    // }
  };
  const handleTabApproved = (e) => {
    // if (Object.keys(vadhuVar).length != 0) {
    //   let updatedItem = vadhuVar.filter((element) => {
    //     return element.registration_status === "Approved";
    //   });
    //   setApproved(updatedItem.length);
    //   setItems(updatedItem);
    // }
  };
  const handleTabRejected = (e) => {
    // if (Object.keys(vadhuVar).length != 0) {
    //   let updatedItem = vadhuVar.filter((element) => {
    //     return element.registration_status === "Rejected";
    //   });
    //   setItems(updatedItem);
    // }
  };
  const handleTabVerifyPhoto = () => {
    // if (Object.keys(vadhuVar).length != 0) {
    //   let updatedItem = vadhuVar.filter((element) => {
    //     return (
    //       parseInt(element.is_profile_img_newly_uploded) === 1 &&
    //       element.registration_status === "Approved"
    //     );
    //   });
    //   setItems(updatedItem);
    // }
  };
  return (
    <>
      {commonLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>
        </>
      ) : (
        ""
      )}
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                return navigate("/job-updates");
              }}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button>My job post</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right">
            <Button
              startIcon={<PostAddIcon />}
              size="small"
              color="primary"
              onClick={() => {
                return navigate("/job-posting");
              }}
            >
              Post Job
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div className={classes.marginTopGrid}>
        {items.length == 0 ? (
          <>
            <Grid item lg={12} xs={12} md={12}>
              <Alert variant="outlined" severity="warning">
                You have not posted any job yet.
              </Alert>
            </Grid>
          </>
        ) : (
          <Grid container spacing={2}>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={"Post (" + pending + ")"}
                  //onClick={handleTabPending}
                  {...a11yProps(0)}
                />
              </Tabs>

              <TabPanel value={value} index={0}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
            </Box>
          </Grid>
        )}
      </div>
    </>
  );
};

export default MyJobPost;
