import axiosInstance from "../middleware/axiosConfig";
const sendOtp = (requestData) => {
  return axiosInstance.post("send-otp", requestData);
};
const otpVerification = (requestData) => {
  return axiosInstance.post("save-user-details", requestData);
};

const otpVerificationWhileLogin = (requestData) => {
  return axiosInstance.post("login-otp-verification", requestData);
};
const login = (bodyFormData) => {
  return axiosInstance.post("login", bodyFormData);
};
// const otpVerification = (requestData) => {
//   let bodyFormData = {
//     country_code: "91",
//     mobile_no: requestData.mobile_no,
//     otp: requestData.otp,
//     otp_reference_id: requestData.otp_reference_id,
//   };
//   return axiosInstance.post("Auth/verify_otp", bodyFormData);
// };
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("storeDataObject");
};

const getMasterData = (requestData) => {
  return axiosInstance.post("get-master-data", requestData);
};
const saveContactUS = (requestData) => {
  return axiosInstance.post("contact-us", requestData);
};

export default {
  sendOtp,
  otpVerification,
  login,
  logout,
  otpVerificationWhileLogin,
  getMasterData,
  saveContactUS,
};
