const initialState = {
  educationId: 0,
  postedJobLoaded: false,
  postedJobListReducer: [],
  postedJobLoading: false,
};

const postedJobReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "POSTED_JOB_LIST_REQUEST":
      return {
        ...state,
        postedJobLoaded: true,
      };
    case "POSTED_JOB_LIST_SUCCESS":
      return {
        ...state,
        postedJobListReducer: payload.postedJobList,
        postedJobLoaded: true,
        postedJobLoading: false,
      };
    case "POSTED_JOB_LIST_FAILED":
      return {
        ...state,
        postedJobLoading: false,
      };

    case "SET_POSTED_JOB_LIST_EDUCATION_ID":
      return {
        ...state,
        educationId: payload.educationId,
      };
    case "POSTED_JOB_LIST_CLEAR":
      return {
        ...state,
        postedJobLoaded: false,
        postedJobListReducer: [],
      };

    default:
      return state;
  }
};
export default postedJobReducer;
