import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CallIcon from "@mui/icons-material/Call";
import { Box } from "@mui/material";
import { SRLWrapper } from "simple-react-lightbox";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
}));

const BusinessListTemplate = ({ img, businessObject }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <SRLWrapper>
        <CardActionArea>
          {/* <CardMedia
            className={classes.media}
            image={img}
            title={businessObject.business_name}
          /> */}

          <SRLWrapper>
            <Box
              component="img"
              sx={{
                height: 300,
                display: "block",
                // maxWidth: 400,
                overflow: "hidden",
                width: "100%",
              }}
              src={img}
              alt={businessObject.business_name}
            />
          </SRLWrapper>

          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item lg={6} xs={12} md={12}>
                <Typography variant="h5" gutterBottom>
                  {businessObject.business_name}
                </Typography>
              </Grid>

              <Grid item lg={6} xs={12} md={12}>
                <Typography
                  style={{ display: "inline-block" }}
                  variant="subtitle2"
                >
                  Catergory
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.cat_name}{" "}
                  {businessObject.category_name != "" ? (
                    <> ({businessObject.category_name}) </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Typography
                  style={{ display: "inline-block" }}
                  variant="subtitle2"
                >
                  Owner
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.business_owner_name}
                </Typography>
              </Grid>

              <Grid item lg={6} xs={6} md={6}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Region
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.region_name}
                  {businessObject.city_village != "" ? (
                    <> ({businessObject.city_village}) </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </SRLWrapper>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => {
            return navigate("/business-details", {
              state: {
                data: businessObject,
                parentRoute: window.location.pathname,
              },
            });
          }}
        >
          Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default BusinessListTemplate;
