import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Paper, Avatar } from "@material-ui/core";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import "../card.css";

import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import EditIcon from "@mui/icons-material/Edit";
import { getListForApproval } from "../../actions/vadhuVar-action";
import { getUserList } from "../../actions/users-action";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputLabel from "@mui/material/InputLabel";
//import configData from "../../config/configData.json";
import { Alert } from "@mui/material";
import * as appConstant from "../../helpers/appConstant";
import { useTranslation } from "react-i18next";
import { getMasterData } from "../../actions/master-data-action";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import master_data from "../../masterData/master_data.json";
const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  avatarImage: {
    width: 200,
    height: 200,
    borderRadius: 100,
  },
  marginTopGrid: {
    marginTop: "10px",
  },
}));

const UserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [items, setItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(1);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const { selected_region } = useSelector(
    (state) => state.userListRegionDataReducers
  );
  var userObject = {};
  const getLoginUserDetails = localStorage.getItem("user");
  if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
    userObject = JSON.parse(getLoginUserDetails);
  }

  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );
  const [user, setUser] = useState(userObject);
  //const [region, setRegion] = useState();

  const default_profile_images = IMAGE_API_URL + "thumbnail/get-vadhu-var";
  const columns = [
    // {
    //   field: "id",
    //   headerName: "Id",
    //   width: 50,
    //   editable: true,
    // },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 120,
      editable: true,
    },
    {
      field: "first_name",
      headerName: "First name",
      width: 150,
      editable: true,
    },
    { field: "last_name", headerName: "Last name", width: 150, editable: true },
    {
      field: "created_on",
      headerName: "Created Date",
      width: 170,
      editable: true,
    },
    {
      field: "updated_at",
      headerName: "Updated Date",
      width: 170,
      editable: true,
    },

    { field: "state_name", headerName: "State", width: 150, editable: true },
    {
      field: "district_name",
      headerName: "District",
      width: 150,
      editable: true,
    },
    {
      field: "is_bride_groom_access_status",
      headerName: "Vadhu var access",
      width: 150,
      editable: true,
    },
    {
      field: "city_name",
      headerName: "City",
      width: 150,
      editable: true,
    },
    {
      field: "is_verified",
      headerName: "Verified",
      width: 100,
      renderCell: (cellValues) => {
        let isVerified = cellValues.row.is_verified;
        return (
          <>
            <Button
              variant="text"
              size="small"
              color={
                isVerified == 0
                  ? "error"
                  : isVerified == 1
                  ? "primary"
                  : "error"
              }
              onClick={(event) => {}}
            >
              {isVerified == 0 ? "Pending" : isVerified == 1 ? "Yes" : "No"}
            </Button>
          </>
        );
      },
    },
    {
      field: "row_status",
      headerName: "Status",
      width: 100,
      renderCell: (cellValues) => {
        let isRowStatus = cellValues.row.row_status;
        return (
          <>
            <Button
              variant="text"
              size="small"
              color={isRowStatus == 0 ? "error" : "primary"}
              onClick={(event) => {}}
            >
              {isRowStatus == 1 ? "Active" : "Deactive"}
            </Button>
          </>
        );
      },
    },

    {
      headerName: "Connect",
      width: 100,
      renderCell: (cellValues) => {
        let mobile = "+91" + cellValues.row.mobile;
        return (
          <>
            <Button
              startIcon={<WhatsAppIcon fontSize="small" />}
              color="primary"
              size="small"
              onClick={() => {
                window.open("https://wa.me/" + mobile, "_blank");
              }}
            ></Button>
          </>
        );
      },
    },
    // {
    //   field: "Active",
    //   width: 120,
    //   renderCell: (cellValues) => {
    //     let rowStatus = cellValues.row.row_status;
    //     return (
    //       <Button
    //         variant="text"
    //         size="small"
    //         color={rowStatus == 1 ? "primary" : "secondary"}
    //         onClick={(event) => {}}
    //       >
    //         {rowStatus == 1 ? "Active" : "Deactive"}
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "View",
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(event) => {
                handleView(cellValues);
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [profileNeedToApprove, setProfileNeedToApprove] = useState(0);
  const [regionList, setRegionList] = useState(1);
  const [region, setRegion] = React.useState(selected_region);
  const [getMasters, setMasters] = useState();
  const [loginUser, setLoginUser] = useState({});
  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/home");
    } else {
      getLoginUserInfo();
    }
  }, [refreshKey]);

  const getLoginUserInfo = () => {
    const promiseResult = new Promise((resolve, reject) => {
      const getLoginUserDetails = localStorage.getItem("user");
      if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
        let userObject = {};
        userObject = JSON.parse(getLoginUserDetails);
        resolve(userObject);
      }
    });
    promiseResult.then((result) => {
      setLoginUser(result);
      if (result.roles == "super_admin" || result.roles == "admin") {
        pageLoad();
      } else {
        return navigate("/home");
      }
    });
  };

  const pageLoad = () => {
    let requestData = {
      mobile: 1,
      region_id: region == "" ? 0 : region,
    };
    getListData(requestData);
    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }
  };

  const getListData = (inputData) => {
    dispatch(getUserList(inputData, t)).then((data) => {
      setItems(data);
      setUserList(data);
      if (data.length > 0) {
        manageCount(data);
      }
    });
  };

  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];

    let dd = master_data.filter((e) => e.state_list)[0]["state_list"];

    console.log("Dd", dd);

    setRegionList(dd);
  };
  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
    dispatch({
      type: "USER_LIST_SELECTED_REGION",
      payload: { selected_region: event.target.value },
    });

    let requestData = {
      mobile: 1,
      region_id: event.target.value,
    };
    getListData(requestData);
  };

  const handleView = (selectedRow) => {
    navigate(`/user-details`, {
      state: { data: selectedRow.row, parentRoute: window.location.pathname },
    });
  };

  const manageCount = (inputList) => {
    let approvedItem = inputList.filter((element) => {
      return element.is_verified === 1;
    });
    setApproved(approvedItem.length);
    let pendingItem = inputList.filter((element) => {
      return element.is_verified === 0;
    });
    setPending(pendingItem.length);

    let rejectedItem = inputList.filter((element) => {
      return element.is_verified === 2;
    });
    setRejected(rejectedItem.length);

    let activeUserItem = inputList.filter((element) => {
      return parseInt(element.row_status) === 1;
    });
    setProfileNeedToApprove(activeUserItem.length);
  };

  const handleTabPending = (e) => {
    let updatedItem = userList.filter((element) => {
      return element.is_verified === 0;
    });
    setItems(updatedItem);
  };
  const handleTabApproved = (e) => {
    let updatedItem = userList.filter((element) => {
      return element.is_verified === 1;
    });
    setApproved(updatedItem.length);
    setItems(updatedItem);
  };
  const handleTabRejected = (e) => {
    let updatedItem = userList.filter((element) => {
      return element.is_verified === 2;
    });
    setItems(updatedItem);
  };
  const handleTabVerifyPhoto = () => {
    let updatedItem = userList.filter((element) => {
      return (
        parseInt(element.is_profile_img_newly_uploded) === 1 &&
        element.registration_status === "Approved"
      );
    });
    setItems(updatedItem);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                return navigate(`/admin`, {});
              }}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button> Users </Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          {/* <Box display="flex" justifyContent="right">
            <Button>Right</Button>
          </Box> */}
        </Grid>
      </Grid>
      <div className={classes.marginTopGrid}>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="demo-select-small">Region</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={region}
                  label="Region"
                  variant="outlined"
                  color="primary"
                  onChange={handleChangeRegion}
                >
                  <MenuItem key="0" value="0">
                    Select
                  </MenuItem>
                  {Object.keys(regionList).map((item, pos) => {
                    return (
                      <MenuItem key={pos} value={item}>
                        {regionList[item]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          {user && user.roles == "member" ? (
            <></>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  //label="Pending"
                  label={"Pending (" + pending + ")"}
                  onClick={handleTabPending}
                  {...a11yProps(0)}
                />
                <Tab
                  // label="Approved"
                  label={"Approved (" + approved + ")"}
                  onClick={handleTabApproved}
                  {...a11yProps(1)}
                />
                <Tab
                  //label="Rejected"
                  label={"Rejected (" + rejected + ")"}
                  onClick={handleTabRejected}
                  {...a11yProps(2)}
                />
              </Tabs>

              <TabPanel value={value} index={0}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
            </Box>
          )}
        </Grid>
      </div>
    </>
  );
};

export default UserList;
