import React, { useState } from "react";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const Main = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <h1> Welcome to Main page </h1>
    </Container>
  );
};

export default Main;
