import React from "react";
import { Helmet } from "react-helmet-async";
export default function Landing() {
  return (
    <div>
      <Helmet>
        <title>Vivah Melava</title>

        <link rel="shortcut icon" href="/logo.png" type="image/x-icon" />
      </Helmet>
    </div>
  );
}
