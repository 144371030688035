import { SET_STORE_ID, CLEAR_STORE_ID } from "../actions/types";

const initialState = {
  storeData: {},
};

const storeObjectReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_STORE_ID:
      return {
        ...state,
        storeData: payload.storeObject,
      };

    case CLEAR_STORE_ID:
      return {
        ...state,
        storeData: {},
      };

    default:
      return state;
  }
};
export default storeObjectReducer;
