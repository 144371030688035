import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Textfield from "../../components/FormsUI/Textfield";
import { useDispatch, useSelector } from "react-redux";
import { login, otpVerificationWhileLogin, logout } from "../../actions/auth";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Navigate, Redirect } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
import { Backdrop } from "@mui/material";
import Spinner from "react-spinner-material";
import ShareIcon from "@mui/icons-material/Share";
import { BrowserView, MobileView } from "react-device-detect";
import { styled } from "@mui/material/styles";
import Register from "./Register";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">ABN Scripts Technologies</Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const customDiv = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  fontSize: 15,
  padding: theme.spacing(1),
}));

export default function Login() {
  const { aid } = useParams();
  const formikRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn, roles, loginLoading } = useSelector(
    (state) => state.auth
  );
  const { vadhuVarRedirectionUrl } = useSelector(
    (state) => state.saveRedirectedUrlReducer
  );

  var redirectionUrl = useLocation().state;
  const [stateDataFromURL, setStateDataFromURL] = React.useState(
    redirectionUrl == null ? null : redirectionUrl.parentRoute
  );

  // const { message } = useSelector((state) => state.message);
  const [optVerification, setOptVerification] = useState(false);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userEnteredValue, setUserEnteredValue] = useState({});
  const [otp_ref, setOtp_ref] = useState({});

  const [isVerified, setIsVerified] = useState(3);
  const [isUserActiveted, setIsUserActiveted] = useState(3);

  const [isRegisterDetails, setIsRegisterDetails] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      //localStorage.setItem("aid", aid);
      return navigate("/user/bride-groom-list");
    } else {
      // localStorage.clear();
      localStorage.removeItem("user");
      //localStorage.setItem("aid", aid);
    }
  }, []);

  const dispatch = useDispatch();
  const INITIAL_FORM_STATE = {
    mobile: "",
    otp: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    mobile: Yup.string().required(t("login_page.err_mobile_requried")),
    otp: Yup.string(),
  });

  const [openSpinner, setOpenSpinner] = React.useState(true);

  const styles = {
    paperContainer: {
      // height: 1356,
      // backgroundImage: `url(${"static/src/img/main.jpg"})`,
      backgroundImage: `url(${"images/1.JPG"})`,
      maxWidth: "100%",
      height: "auto",
      backgroundRepeat: "no-repeat",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        // style={styles.paperContainer}
        component="main"
        maxWidth="sx"
      >
        {loginLoading ? (
          <>
            {" "}
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openSpinner}
            >
              <Spinner
                radius={50}
                color={"#e60e96"}
                stroke={2}
                visible={true}
              />
            </Backdrop>{" "}
          </>
        ) : (
          ""
        )}
        <CssBaseline />
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>

          <Typography component="h1" variant="h5">
            {t("login_page.lbl_header_login")}
          </Typography>
          <Box sx={{ mt: 1, width: 250, maxWidth: "100%" }}>
            <Formik
              innerRef={formikRef}
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                // setIsUserDetails(false);
                const isEmail = values.mobile.includes("@");
                if (isEmail) {
                  dispatch(
                    showSuccessSnackbar({
                      hideDuration: 5000,
                      status: "error",
                      message:
                        "Email login not available yet,Please enter your mobile no",
                    })
                  );

                  return;
                } else {
                  if (optVerification == false) {
                    let formbody = {
                      mobile_no: values.mobile,
                    };
                    const isEmail = values.mobile.includes("@");
                    setUserEnteredValue({
                      ...userEnteredValue,
                      mobile: values.mobile,
                      isEmail: isEmail,
                    });
                    dispatch(login(formbody, t))
                      .then((res) => {
                        setOtp_ref(res);
                        setTimeout(function() {
                          setSuccess(true);
                          setLoading(false);
                          setOptVerification(true);
                        }, 1000);
                      })
                      .catch((error) => {
                        setSuccess(true);
                        setLoading(false);
                        if (
                          error.message === "login_page.err_user_not_exists"
                        ) {
                          return navigate(`/register`, {
                            state: {
                              data: "",
                              parentRoute:
                                stateDataFromURL == null
                                  ? null
                                  : stateDataFromURL,
                            },
                          });
                        }
                      });
                  } else {
                    if (values.otp == "" || values.otp == undefined) {
                      dispatch(
                        showSuccessSnackbar({
                          hideDuration: 5000,
                          status: "error",
                          message: "Enter your mobile otp",
                        })
                      );
                    } else {
                      let data = {
                        mobile_no: values.mobile,
                        otp: values.otp,
                        otp_reference_id: otp_ref.otp_reference_id,
                      };
                      dispatch(otpVerificationWhileLogin(data, t))
                        .then((verificationResult) => {
                          console.log("verificationResult", verificationResult);

                          if (verificationResult["roles"] == "associate") {
                            return navigate("/user/my-profile");
                          } else if (verificationResult["is_redirected"] == 0) {
                            //setIsRegisterDetails(true);
                            return navigate(`/register`, {
                              state: {
                                data: values.mobile,
                                parentRoute:
                                  stateDataFromURL == null
                                    ? null
                                    : stateDataFromURL,
                              },
                            });
                          } else if (verificationResult["row_status"] == 0) {
                            setIsVerified(verificationResult.is_verified);
                            setIsUserActiveted(verificationResult.row_status);
                            dispatch(logout());
                          } else if (verificationResult["is_verified"] == 2) {
                            setIsVerified(verificationResult.is_verified);
                            setIsUserActiveted(verificationResult.row_status);
                            dispatch(logout());
                          } else {
                            if (
                              verificationResult.is_verified == 1 ||
                              isUserActiveted.row_status == 1
                            ) {
                              setTimeout(function() {
                                setSuccess(true);
                                setLoading(false);
                                if (stateDataFromURL == null) {
                                  return navigate("/user/bride-groom-list");
                                } else {
                                  return navigate(stateDataFromURL);
                                }
                              }, 1000);
                            } else if (verificationResult.is_verified == 0) {
                              setTimeout(function() {
                                setSuccess(true);
                                setLoading(false);
                                return navigate("/user/create-profile");
                              }, 1000);
                            }
                          }
                        })
                        .catch(() => {
                          setTimeout(function() {
                            setSuccess(true);
                            setLoading(false);
                          }, 1000);
                        });
                    }
                  }
                }
              }}
            >
              <Form autoComplete="off">
                {isRegisterDetails == false ? (
                  <>
                    {isVerified == 0 ||
                    isVerified == 2 ||
                    isUserActiveted == 0 ? (
                      <>
                        <Grid container spacing={1}>
                          <Grid item lg={12} xs={12} md={12}>
                            {isVerified == 0 ? (
                              <>
                                {" "}
                                <Typography
                                  fullWidth
                                  style={{ color: "red" }}
                                  align="center"
                                  variant="subtitle1"
                                  gutterBottom
                                >
                                  {t("login_page.lbl_verification")}
                                </Typography>
                              </>
                            ) : (
                              <></>
                            )}

                            {isVerified == 2 ? (
                              <>
                                {" "}
                                <Typography
                                  fullWidth
                                  style={{ color: "red" }}
                                  align="center"
                                  variant="subtitle1"
                                  gutterBottom
                                >
                                  {t("login_page.lbl_verification_failed")}
                                </Typography>{" "}
                              </>
                            ) : (
                              <></>
                            )}
                            {isUserActiveted == 0 ? (
                              <>
                                {" "}
                                <Typography
                                  fullWidth
                                  style={{ color: "red" }}
                                  align="center"
                                  variant="subtitle1"
                                  gutterBottom
                                >
                                  Your account is deactivated,please contact to
                                  administrator.
                                </Typography>{" "}
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Grid container spacing={1}>
                          {optVerification == false && (
                            <>
                              <Grid item xs={12}>
                                <Textfield
                                  name="mobile"
                                  label={t("login_page.lbl_mobile_no")}
                                  margin="normal"
                                  fullWidth
                                  id="email"
                                />
                              </Grid>
                            </>
                          )}
                          {optVerification == true && (
                            <>
                              <Grid item xs={12}>
                                <Typography
                                  style={{ color: "blue" }}
                                  align="center"
                                  variant="subtitle1"
                                  gutterBottom
                                >
                                  {userEnteredValue.isEmail
                                    ? t("signup_page.lbl_otp_sent_on_email")
                                    : t(
                                        "signup_page.lbl_otp_sent_on_mobile"
                                      )}{" "}
                                  {userEnteredValue.mobile}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Textfield
                                  name="otp"
                                  autoComplete="nope"
                                  fullWidth
                                  label={t("login_page.lbl_otp")}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          loading="true"
                        >
                          {loading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "white",
                              }}
                            />
                          )}
                          {optVerification
                            ? t("login_page.btn_continue")
                            : t("login_page.lbl_btn_login")}
                        </Button>
                        {optVerification ? (
                          <>
                            <Button
                              size="small"
                              fullWidth
                              color="primary"
                              onClick={() => {
                                setOptVerification(false);
                                formikRef.current.setFieldValue(
                                  "otp",
                                  "",
                                  false
                                );
                              }}
                            >
                              {userEnteredValue.isEmail
                                ? t("login_page.lbl_change_email")
                                : t("login_page.lbl_change_mobile")}
                            </Button>
                          </>
                        ) : (
                          ""
                          // <>
                          //   {" "}
                          //   <Grid container justify="flex-end">
                          //     <Grid item>
                          //       <Link
                          //         onClick={() => {
                          //           navigate("/register");
                          //         }}
                          //         variant="body2"
                          //       >
                          //         {t("login_page.lbl_btn_sign_up")}
                          //       </Link>
                          //     </Grid>
                          //   </Grid>
                          // </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Register></Register>
                  </>
                )}
              </Form>
            </Formik>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
