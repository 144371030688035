import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as appConstant from "../../helpers/appConstant";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";

import {
  postedJobActions,
  getPostedJobDetailsById,
} from "../../actions/job-actions";

import { Box } from "@mui/material";
import { SRLWrapper } from "simple-react-lightbox";
import ReadOnly from "../commonComponent/ReadOnly";
const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
}));

const ViewPostedJob = ({ img, title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const businessObjectLocation = useLocation().state;
  const [actionMessage, setActionMessage] = React.useState({});
  const [businessObject, setBusinessObject] = React.useState(
    businessObjectLocation.data
  );
  const [jobDescription, setJobDescription] = React.useState(
    JSON.parse(businessObject.job_description)
  );

  const { isLoggedIn } = useSelector((state) => state.auth);
  const IMAGE_API_URL =
    process.env.REACT_APP_ENV == "DEV"
      ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
      : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

  const banner_image = IMAGE_API_URL + "banner?type=1&id=";

  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const [open, setOpen] = React.useState(false);
  const [remark, setRemark] = useState({
    value: "",
  });

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      let jobDetails = {
        jm_job_posted_id: businessObject.jm_job_posted_id,
        title: businessObject.title,
        company_name: businessObject.company_name,
        location: businessObject.job_location,
      };
      dispatch(getPostedJobDetailsById(jobDetails, t)).then((res) => {});
    }
  }, []);

  const handleChangeRemark = (e) => {
    setRemark({ value: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const action = () => {
    setOpen(true);
  };
  const submitActionData = () => {
    if (true) {
      //var rejected_remark = "";
      // if (actionMessage.action == appConstant.ACTION_PENDING) {
      // } else if (actionMessage.action == appConstant.ACTION_APPROVED) {
      //   rejected_remark = vadhuVarObject.image_path;
      // } else if (actionMessage.action == appConstant.ACTION_REJECTED) {
      //   rejected_remark = remark.value;
      // }
      var rejected_remark = "";
      let data = {
        jm_job_posted_id: businessObject.jm_job_posted_id,
        acton_data: actionMessage.action,
        action_type: actionMessage.actionType,
        // acton_remark: rejected_remark,
      };
      dispatch(postedJobActions(data, t))
        .then((res) => {
          return navigate(businessObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid></Grid>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12} md={12}>
                {actionMessage.confirmation}
              </Grid>
              {actionMessage &&
              actionMessage.action == appConstant.ACTION_REJECTED ? (
                <Grid item lg={12} xs={12} md={12}>
                  <TextField
                    id="txtRemark"
                    onChange={handleChangeRemark}
                    // id="outlined-basic"
                    label="Enter remark"
                    variant="outlined"
                    fullWidth
                    multiline={true}
                    rows={2}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<HighlightOffIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={submitActionData}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                return navigate("/job-updates");
              }}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button>View Job Details</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right"></Box>
        </Grid>
      </Grid>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item lg={6} xs={12} md={12}>
              <Typography variant="h5" gutterBottom>
                {businessObject.title}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Company name :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.company_name}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Employment Type:
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.employment_type_name}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Education :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.education}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block" }}
              >
                Experience :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.experience}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block" }}
              >
                Salary :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.salary_offered}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography variant="subtitle2">Job Description</Typography>
              <ReadOnly initialTextValue={jobDescription} />
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block" }}
              >
                Skill :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.key_skill}
              </Typography>
            </Grid>

            {/* {businessObject.other_info != "" ? (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Typography variant="subtitle2">Other Information</Typography>
                  <Typography variant="body2">
                    {businessObject.other_info}
                  </Typography>
                </Grid>
              </>
            ) : (
              ""
            )} */}

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Contact person :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.contact_person_name}
              </Typography>
            </Grid>
            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Contact :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.contact_person_number}
              </Typography>
            </Grid>
            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Email :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.contact_person_email}
              </Typography>
            </Grid>

            <Grid item lg={3} xs={6} md={6}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block" }}
              >
                Location :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.job_location}
              </Typography>
            </Grid>
            <Grid item lg={9} xs={6} md={6}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block" }}
              >
                Address :
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.job_address}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {/* </CardActionArea> */}
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              return navigate("/job-updates");
            }}
          >
            Back
          </Button>

          {businessObject.is_edit ? (
            <>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  return navigate("/job-posting", {
                    state: {
                      data: businessObject,
                      parentRoute: window.location.pathname,
                    },
                  });
                }}
              >
                Edit
              </Button>
            </>
          ) : (
            ""
          )}
          {businessObject.is_approval ? (
            <>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  let data = {
                    action: "Approved",
                    confirmation: "Are you sure you want to approve ?",
                    actionType: appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                  };
                  setActionMessage(data);
                  action();
                }}
              >
                Approve
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  let data = {
                    action: "Rejected",
                    confirmation: "Are you sure you want to reject ?",
                    actionType: appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                  };
                  setActionMessage(data);
                  action();
                }}
              >
                Reject
              </Button>
            </>
          ) : (
            ""
          )}
        </CardActions>
      </Card>{" "}
    </>
  );
};

export default ViewPostedJob;
