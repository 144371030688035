import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Grid, Avatar, Paper } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getApproveList } from "../../actions/committee-member-action";
import { getMasterData } from "../../actions/master-data-action";
import InputLabel from "@mui/material/InputLabel";
//import configData from "../../config/configData.json";
import Box from "@mui/material/Box";
import Spinner from "react-spinner-material";
import Backdrop from "@mui/material/Backdrop";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import master_data from "../../masterData/master_data.json";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import Alert from "@material-ui/lab/Alert";
const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  marginTopGrid: {
    marginTop: "8px",
  },
  topGridMargin: {
    marginTop: 2,
    // flexGrow: 1,
  },
  root: {
    // marginTop: 100,
    // flexGrow: 1,
  },
  paper: {
    // height: 220,
    // width: 275,
    // backgroundColor: "#ebebeb",
  },
  avatarImage: {
    width: 200,
    height: 200,
    borderRadius: 100,
  },
}));

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [getMasters, setMasters] = useState();
  const regionData = master_data[4];
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { commiteeMembers, loading, loaded } = useSelector(
    (state) => state.committeeMemberReducers
  );
  const { selected_region_id } = useSelector(
    (state) => state.commiteeRegionDataReducers
  );
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );
  const [regionList, setRegionList] = useState(1);
  const [items, setItems] = useState([]);
  const [region, setRegion] = React.useState("");
  const [refreshKey, setRefreshKey] = useState(100);
  const [organizationTeam, setOrganizationTeam] = useState([]);
  const default_profile_images =
    IMAGE_API_URL + "committee/thumbnail/get-member-image";
  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    }
    getLoginUserInfo();
    if (loaded == false) {
      let requestData = {
        user_id: 1,
      };
      dispatch(getApproveList(requestData)).then((res) => {
        setTimeout(function() {
          const numAscending = [...res].sort((a, b) => a.order_by - b.order_by);
          let getWithOutOrganizationTeam = res.filter((element) => {
            return element.designation != "Organizer";
          });

          setItems(getWithOutOrganizationTeam);

          let getOrganizationTeam = res.filter((element) => {
            return element.designation === "Organizer";
          });
          setOrganizationTeam(getOrganizationTeam);

          // let selected_ddl_region_id =
          //   selected_region_id == 0 ? region : selected_region_id;
          // setRegion(selected_ddl_region_id);
        }, 1000);
      });
      // setRegion(100);
    } else {
      // setRegion(100);
      let selected_ddl_region_id =
        selected_region_id == 0 ? region : selected_region_id;

      let getWithOutOrganizationTeam = commiteeMembers.filter((element) => {
        return (
          element.designation != "Organizer" &&
          parseInt(element.region_id) === parseInt(selected_ddl_region_id)
        );
      });

      let getOrganizationTeam = commiteeMembers.filter((element) => {
        return element.designation === "Organizer";
      });
      setOrganizationTeam(getOrganizationTeam);
      setItems(getWithOutOrganizationTeam);

      //setRegion(selected_ddl_region_id);
    }

    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }
  }, [refreshKey]);

  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];

    setRegionList(region_list);
  };

  const getLoginUserInfo = () => {
    const promiseResult = new Promise((resolve, reject) => {
      const getLoginUserDetails = localStorage.getItem("user");
      if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
        let userObject = {};
        userObject = JSON.parse(getLoginUserDetails);
        resolve(userObject);
      }
    });
    promiseResult.then((result) => {
      let selected_ddl_region_id =
        parseInt(selected_region_id) == parseInt("0")
          ? result.region_id
          : selected_region_id;

      setTimeout(function() {
        setRegion(selected_ddl_region_id);
      }, 1000);
      // setRegion(selected_region_id);
    });
  };

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChangeRegion = (event) => {
    setRegion(event.target.value);

    dispatch({
      type: "COMMITEE_REGION_ID",
      payload: { selected_region_id: event.target.value },
    });

    let updatedItem = commiteeMembers.filter((element) => {
      return parseInt(element.region_id) === parseInt(event.target.value);
    });
    if (event.target.value == "0") {
      updatedItem = commiteeMembers.filter((element) => {
        return element;
      });
    }
    setItems(updatedItem);
  };
  return (
    <>
      {loading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
            onClick={handleClose}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>
        </>
      ) : (
        <>
          <Grid
            container
            spacing={1}
            style={{
              background: "#cfd8dc",
            }}
          >
            <Grid item lg={2} xs={2} md={2}>
              <Box display="flex" justifyContent="left">
                {/* <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    return navigate();
                  }}
                >
                  Back
                </Button> */}
              </Box>
            </Grid>
            <Grid item lg={8} xs={8} md={8}>
              <Box display="flex" justifyContent="center">
                <Button>Our Team</Button>
              </Box>
            </Grid>
            <Grid item lg={2} xs={2} md={2}>
              <Box display="flex" justifyContent="right"></Box>
            </Grid>
          </Grid>
          <div className={classes.marginTopGrid}>
            <Grid container spacing={1}>
              <Grid item lg={6} xs={6} md={6}>
                <FormControl sx={{ minWidth: 100 }} size="small">
                  <InputLabel id="demo-select-small">
                    {t("signup_page.lbl_region_name")}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={region}
                    label="Region"
                    variant="outlined"
                    color="primary"
                    onChange={handleChangeRegion}
                  >
                    {Object.keys(regionList).map((item, pos) => {
                      return (
                        <MenuItem key={pos} value={item}>
                          {regionList[item]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={6} md={6}>
                {organizationTeam.length != 0 &&
                organizationTeam[0]["is_edit"] == 1 ? (
                  <>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<PersonAddAltOutlinedIcon />}
                        onClick={(event) => {
                          return navigate(`/committee-member-create`, {
                            state: {
                              data: "",
                              parentRoute: window.location.pathname,
                            },
                          });
                        }}
                      >
                        Create
                      </Button>
                    </Box>{" "}
                  </>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item lg={12} xs={12} md={12}>
                <Divider>
                  <Chip label="व्यवस्थापक" />
                </Divider>
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                {items.length == 0 && organizationTeam.length == 0 ? (
                  <>
                    <Grid item lg={12} xs={12} md={12}>
                      <Alert size="small" variant="outlined" severity="warning">
                        {t("committee_member_list_page.lbl_no_data_found")}
                      </Alert>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item className={classes.root} xs={12}>
                      <Grid container spacing={1}>
                        {organizationTeam.map((item) => (
                          <Grid key={item.id} item lg={3} xs={12} md={12}>
                            <Paper className={classes.paper} elevation={2}>
                              <Grid
                                container
                                justify="center"
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Avatar
                                  alt={item.first_name}
                                  className={classes.avatarImage}
                                  src={
                                    default_profile_images +
                                    item.appended_image_path
                                  }
                                />
                                <CardContent>
                                  <Typography
                                    align="center"
                                    // gutterBottom variant="h6" component="div"
                                    gutterBottom
                                    variant="headline"
                                    component="h4"
                                  >
                                    {item.mr_ms +
                                      " " +
                                      item.first_name +
                                      " " +
                                      item.last_name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    align="center"
                                    color="text.secondary"
                                  >
                                    {item.designation} <br />
                                    {t(
                                      "committee_member_list_page.lbl_mobile"
                                    )}{" "}
                                    : {item.mobile_number} <br />
                                    <Button
                                      size="small"
                                      id={item.id}
                                      onClick={(event) => {
                                        let selectedData = commiteeMembers.filter(
                                          (e) => e.id == event.currentTarget.id
                                        );
                                        return navigate(
                                          `/committee-member-details`,
                                          {
                                            state: {
                                              data: selectedData[0],
                                              parentRoute:
                                                window.location.pathname,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      Details
                                    </Button>
                                  </Typography>
                                  <Grid lg={12}></Grid>
                                </CardContent>
                              </Grid>
                            </Paper>
                          </Grid>
                        ))}

                        {items.length != 0 ? (
                          <>
                            {" "}
                            <Grid item lg={12} xs={12} md={12}>
                              <Divider>
                                <Chip label="संघटक" />
                              </Divider>
                            </Grid>
                          </>
                        ) : (
                          <> </>
                        )}

                        {items.map((item) => (
                          <Grid key={item.id} item lg={3} xs={12} md={12}>
                            <Paper className={classes.paper} elevation={2}>
                              <Grid
                                container
                                justify="center"
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Avatar
                                  alt={item.first_name}
                                  className={classes.avatarImage}
                                  src={
                                    default_profile_images +
                                    item.appended_image_path
                                  }
                                />
                                <CardContent>
                                  <Typography
                                    align="center"
                                    // gutterBottom variant="h6" component="div"
                                    gutterBottom
                                    variant="headline"
                                    component="h4"
                                  >
                                    {item.mr_ms +
                                      " " +
                                      item.first_name +
                                      " " +
                                      item.last_name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    align="center"
                                    color="text.secondary"
                                  >
                                    {item.designation} <br />
                                    {t(
                                      "committee_member_list_page.lbl_mobile"
                                    )}{" "}
                                    : {item.mobile_number} <br />
                                    <Button
                                      size="small"
                                      id={item.id}
                                      onClick={(event) => {
                                        let selectedData = commiteeMembers.filter(
                                          (e) => e.id == event.currentTarget.id
                                        );
                                        return navigate(
                                          `/committee-member-details`,
                                          {
                                            state: {
                                              data: selectedData[0],
                                              parentRoute:
                                                window.location.pathname,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      Details
                                    </Button>
                                  </Typography>
                                  <Grid lg={12}></Grid>
                                </CardContent>
                              </Grid>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default List;
