import {
  VADHUVAR_LIST_REQUEST,
  VADHUVAR_LIST_SUCCESS,
  VADHUVAR_LIST_FAIL,
  VADHUVAR_LIST_REFRESH,
  VADHUVAR_WISH_LIST_UPDATE,
  VADHUVAR_LIST_CLEAR,
  VADHUVAR_LIST_APPROVAL_SUCCESS,
} from "../actions/types";
const initialState = {
  loaded: false,
  loading: false,
  error: false,
  vadhuVar: [],
  responseMessage: {},
  updateObject: {},
  registrationFormLoading: false,
  genderAccess: {},
};

const vadhuVarReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case VADHUVAR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VADHUVAR_LIST_SUCCESS:
      return {
        ...state,
        vadhuVar: payload.vadhuVar,
        error: false,
        loading: false,
        loaded: true,
      };
    case VADHUVAR_LIST_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        loaded: false,
      };

    case VADHUVAR_LIST_REFRESH:
      return {
        ...state,
        vadhuVar: [],
        error: false,
        loading: false,
        loaded: false,
      };
    case VADHUVAR_WISH_LIST_UPDATE:
      return {
        ...state,
        vadhuVar: state.vadhuVar.map((item) => {
          if (parseInt(item.id) === parseInt(action.payload.updateObject.id)) {
            const updatedItem = {
              ...item,
              is_wish_list: item.is_wish_list == 0 ? 1 : 0,
            };
            return updatedItem;
          }

          return item;
        }),
      };

    case VADHUVAR_LIST_CLEAR:
      return {
        ...state,
        vadhuVar: [],
        error: false,
        loading: false,
        loaded: false,
      };

    case "VADHUVAR_REGISTRATION_SUBMIT_REQUEST":
      return {
        ...state,
        registrationFormLoading: true,
      };
    case "VADHUVAR_REGISTRATION_SUBMIT_SUCCESS":
      return {
        ...state,
        registrationFormLoading: false,
      };
    case "VADHUVAR_REGISTRATION_SUBMIT_FAILED":
      return {
        ...state,
        registrationFormLoading: false,
      };

    case "VADHUVAR_APPROVAL_REQUEST":
      return {
        ...state,
        registrationFormLoading: true,
      };
    case "VADHUVAR_APPROVAL_REQUEST_SUCCESS":
      return {
        ...state,
        registrationFormLoading: false,
      };

    case "GENDER_ACCESS":
      console.log("GENDER_ACCESS", payload.data);
      return {
        ...state,
        genderAccess: payload.data,
      };

    case "VADHUVAR_APPROVAL_REQUEST_FAILED":

    default:
      return state;
  }
};
export default vadhuVarReducer;
