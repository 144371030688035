import React, { useState, useEffect } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { getPaymentStatus } from "../../actions/vadhuVar-action";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const PaymentStatus = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { tid } = useParams();
  const [paymentStatus, setPaymentStatus] = useState();
  const { isLoggedIn } = useSelector((state) => state.auth);
  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      if (tid != "undefined") {
        getPaymentStatusDetails();
      }
    }
  }, []);

  const getPaymentStatusDetails = () => {
    let details = {
      bride_groom_id: 0,
      transactionId: tid,
    };
    dispatch(getPaymentStatus(details, t)).then(
      (paymentStatus) => {
        setPaymentStatus(paymentStatus);
      },
      (err) => {}
    );
  };

  return (
    <Container className={classes.container}>
      {paymentStatus &&
      paymentStatus.data.payment_status === "PAYMENT_SUCCESS" ? (
        <>
          <Card sx={{ maxWidth: "100%" }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Payment success
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("payment_page.lbl_after_payment_success")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="medium"
                variant="outlined"
                onClick={() => {
                  return navigate(`/user/my-profile`, {});
                }}
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </>
      ) : (
        <>
          <Card sx={{ maxWidth: "100%" }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Payment Failed
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("payment_page.lbl_after_payment_failed")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="medium"
                variant="outlined"
                onClick={() => {
                  return navigate(`/user/my-profile`, {});
                }}
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </>
      )}
    </Container>
  );
};

export default PaymentStatus;
