import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Snackbar,
  Fab,
  Tooltip,
  Button,
} from "@material-ui/core";
import {
  BrowserRouter,
  Switch,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Add as AddIcon } from "@material-ui/icons";
import Feed from "./components/PublicNoAuth/Feed";
import Leftbar from "./components/menu/Leftbar";
import Navbar from "./components/menu/Navbar";
import Rightbar from "./components/PublicNoAuth/Rightbar";
import ShareIcon from "@mui/icons-material/Share";

import DrawerAppBar from "./components/menu/DrawerAppBar";
import Home from "./components/PublicNoAuth/Home";
import About from "./components/PublicNoAuth/About";
import Contact from "./components/PublicNoAuth/Contact";
import { HelmetProvider } from "react-helmet-async";
import PhotoGalleryComponent from "./components/PublicNoAuth/PhotoGalleryComponent";
import Login from "./components/PublicNoAuth/Login";
import Register from "./components/PublicNoAuth/Register";
import ForgotPassword from "./components/PublicNoAuth/ForgotPassword";
import Dashboard from "./components/dashboard/Dashboard";
import Matrimony from "./components/vadhuVar/Matrimony";
import VadhuVarRegistration from "./components/vadhuVar/VadhuVarRegistration";
import VadhuVarList from "./components/vadhuVar/VadhuVarList";
import VadhuVarDetails from "./components/vadhuVar/VadhuVarDetails";
import PageNotFound from "./components/PublicNoAuth/PageNotFound";
import fileUploderService from "./components/fileUplod/GenericFileUpload";
import { useDispatch, useSelector } from "react-redux";
import SuccessSnackbar from "./components/commonComponent/SuccessSnackbar";
import { showSuccessSnackbar } from "./actions/snackbarActions";
import { default as CommiteeList } from "./components/commitee/List";
import { default as CommiteeMemberDetails } from "./components/commitee/View";
import { default as CommiteeMemberCreate } from "./components/commitee/Create";
import RazorpayPayment from "./components/RazorpayPayment/RazorpayPayment";
import PrivacyPolicy from "./components/PublicNoAuth/PrivacyPolicy";
import Spinner from "react-spinner-material";
import Backdrop from "@mui/material/Backdrop";
import UserList from "./components/users/UserList";

import Landing from "./components/PublicNoAuth/Landing";
import tEn from "./locales/en/transaltion.json";
import tHi from "./locales/hi/transaltion.json";
import tmr_IN from "./locales/mr_IN/transaltion.json";
import UserDetails from "./components/users/UserDetails";
import Admin from "./components/admin/admin";
import BusinessList from "./components/business/BusinessList";
import BusinessDetails from "./components/business/BusinessDetails";
import BusinessCreate from "./components/business/BusinessCreate";
import CreateJobProfile from "./components/jobModule/CreateJobProfile";
import JobProfileList from "./components/jobModule/JobProfileList";
import JobUpdates from "./components/jobModule/JobUpdates";
import JobPosting from "./components/jobModule/JobPosting";
import ViewPostedJob from "./components/jobModule/ViewPostedJob";
import MyJobPost from "./components/jobModule/MyJobPost";
import SamplePage from "./components/PublicNoAuth/SamplePage";
import { BrowserView, MobileView } from "react-device-detect";
import ContactUsList from "./components/contact-us/ContactUsList";
import ReviewAndRating from "./components/reviewandrating/ReviewAndRating";
import Footer from "./components/PublicNoAuth/Footer";
import TermAndCondition from "./components/PublicNoAuth/TermAndCondition";
import PaymentStatus from "./components/PublicNoAuth/PaymentStatus";

const helmetContext = {};
const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  fab: {
    position: "fixed",
    bottom: 20,
    right: 20,
  },

  root: {},
  //container: { marginLeft: 100 },
  container: {
    paddingTop: theme.spacing(10),
    // position: "fixed",
    width: "100%",
    height: "100%",
  },
  tt: {
    display: "block",
    // margin: "0 auto",
    margin: "100px,0,0,0",
    borderColor: "red",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // marginTop: -"50px",
    // marginLeft: -"50px",
    // width: "100px",
    // height: "100px",
  },
}));

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     // the translations
//     // (tip move them in a JSON file and import them,
//     // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
//     resources: {
//       en: {
//         translation: tEn,
//       },
//       // de: {
//       //   translation: tHi,
//       // },
//       hi: {
//         translation: tmr_IN,
//       },
//     },
//     lng: "en", // if you're using a language detector, do not define the lng option
//     fallbackLng: "en",

//     interpolation: {
//       escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//     },
//   });

const changeLang = (selectedLang) => {
  //alert('OK');
  return () => {
    //alert('ok '+l);
    //Now change the language
    //object.member();
    //i18n.changeLanguage(selectedLang);
    //Now set the current language in local storage
    // localStorage.setItem("lang", selectedLang);
  };
};

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  useEffect(() => {
    let currentLang = localStorage.getItem("lang");
    if (currentLang) {
      i18n.changeLanguage(currentLang);
    }
    //setLoading(true);
    setTimeout(function() {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <Container className={classes.container}>
        <SuccessSnackbar />
        {loading ? (
          <>
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <Spinner
                  className={classes.tt}
                  radius={50}
                  color={"#e60e96"}
                  stroke={2}
                  visible={true}
                />
              </Backdrop>
            </div>
          </>
        ) : (
          <>
            <HelmetProvider context={helmetContext}>
              <BrowserRouter>
                <Landing />
                <DrawerAppBar />
                <Routes basename="/bariapps">
                  <Route path="/home" element={<Home />} />
                  <Route path="/" element={<Navigate replace to="/home" />} />

                  <Route path="/home/:aid" element={<Home />} />

                  <Route path="/about" element={<ReviewAndRating />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/gallery" element={<PhotoGalleryComponent />} />
                  <Route path="/committee" element={<CommiteeList />} />
                  <Route
                    path="/committee-member-details"
                    element={<CommiteeMemberDetails />}
                  />
                  <Route
                    path="/committee-member-create"
                    element={<CommiteeMemberCreate />}
                  />
                  <Route path="/matrimony" element={<Matrimony />} />
                  <Route
                    path="/user/bride-groom-list"
                    element={<Matrimony />}
                  />
                  <Route path="/details" element={<VadhuVarDetails />} />
                  <Route path="/details/:id" element={<VadhuVarDetails />} />

                  <Route
                    path="/profile/details"
                    element={<VadhuVarDetails />}
                  />
                  <Route
                    path="/profile/details/:id"
                    element={<VadhuVarDetails />}
                  />

                  <Route path="/vadhuvar/:id" element={<VadhuVarDetails />} />

                  <Route path="/my-registration" element={<VadhuVarList />} />

                  <Route path="/user/my-profile" element={<VadhuVarList />} />

                  <Route
                    path="/registration"
                    element={<VadhuVarRegistration />}
                  />
                  <Route
                    path="/user/create-profile"
                    element={<VadhuVarRegistration />}
                  />

                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot" element={<ForgotPassword />} />
                  <Route path="/dashboard/*" element={<Dashboard />} />
                  <Route path="/payment" element={<RazorpayPayment />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/term-and-condition"
                    element={<TermAndCondition />}
                  />
                  <Route path="/admin" element={<Admin />} />
                  <Route
                    path="/payment-status/:tid"
                    element={<PaymentStatus />}
                  />
                  <Route path="/users" element={<UserList />} />
                  <Route path="/user-details" element={<UserDetails />} />

                  <Route path="/get-contact-us" element={<ContactUsList />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
                <Footer />
              </BrowserRouter>{" "}
            </HelmetProvider>
          </>
        )}
        <Tooltip title="Share on whatsApp" aria-label="Share on WhatsApp">
          <Fab color="primary" className={classes.fab}>
            <BrowserView>
              <ShareIcon
                onClick={() => {
                  window.open(
                    "https://web.whatsapp.com/send?text=Please Visit  " +
                      process.env.REACT_APP_PROD_URL +
                      "home"
                  );
                }}
              />
            </BrowserView>
            <MobileView>
              <ShareIcon
                onClick={() => {
                  window.open(
                    "whatsapp://send?text=Please Visit " +
                      process.env.REACT_APP_PROD_URL
                  );
                }}
              />
            </MobileView>
          </Fab>
        </Tooltip>
      </Container>
    </>
  );
};

export default App;
