const initialState = {
  businessRegionId: 0,
  businessLoaded: false,
  businessListReducer: [],
  businessLoading: false,
};

const businessReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "BUSINESS_LIST_REQUEST":
      return {
        ...state,
        businessLoading: true,
      };
    case "BUSINESS_LIST_SUCCESS":
      return {
        ...state,
        businessListReducer: payload.businessList,
        businessLoaded: true,
        businessLoading: false,
      };
    case "BUSINESS_LIST_FAILED":
      return {
        ...state,
        businessLoading: false,
      };

    case "SET_BUSINESS_LIST_REGION_ID":
      return {
        ...state,
        businessRegionId: payload.selected_region,
      };
    case "BUSINESS_LIST_CLEAR":
      return {
        ...state,
        businessLoaded: false,
        businessListReducer: [],
      };

    case "VADHUVAR_APPROVAL_REQUEST_SUCCESS":
      return {
        ...state,
        registrationFormLoading: false,
      };
    case "VADHUVAR_APPROVAL_REQUEST_FAILED":

    default:
      return state;
  }
};
export default businessReducer;
