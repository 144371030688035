const initialState = {
  selected_region: 0,
};

const userListRegionDataReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "USER_LIST_SELECTED_REGION":
      return {
        ...state,
        selected_region: payload.selected_region,
      };
    case "USER_LIST_SELECTED_REGION_CLEAR":
      return {
        ...state,
        selected_region: 0,
      };
    default:
      return state;
  }
};
export default userListRegionDataReducers;
