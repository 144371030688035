import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as appConstant from "../../helpers/appConstant";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  businessAction,
  getBusinessDetailsById,
} from "../../actions/business-action";
import { Box } from "@mui/material";
import { SRLWrapper } from "simple-react-lightbox";
const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
}));

const BusinessDetails = ({ img, title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const businessObjectLocation = useLocation().state;
  const [actionMessage, setActionMessage] = React.useState({});
  const [businessObject, setBusinessObject] = React.useState(
    businessObjectLocation.data
  );
  const { isLoggedIn } = useSelector((state) => state.auth);
  const IMAGE_API_URL =
    process.env.REACT_APP_ENV == "DEV"
      ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
      : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

  const banner_image = IMAGE_API_URL + "banner?type=1&id=";

  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const [open, setOpen] = React.useState(false);
  const [remark, setRemark] = useState({
    value: "",
  });

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      let businessDetails = {
        business_id: businessObject.business_id,
        business_name: businessObject.business_name,
        business_owner_name: businessObject.business_owner_name,
        region_name: businessObject.region_name,
      };
      dispatch(getBusinessDetailsById(businessDetails, t)).then((res) => {});
    }
  }, []);

  const handleChangeRemark = (e) => {
    setRemark({ value: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const action = () => {
    setOpen(true);
  };
  const submitActionData = () => {
    if (true) {
      //var rejected_remark = "";
      // if (actionMessage.action == appConstant.ACTION_PENDING) {
      // } else if (actionMessage.action == appConstant.ACTION_APPROVED) {
      //   rejected_remark = vadhuVarObject.image_path;
      // } else if (actionMessage.action == appConstant.ACTION_REJECTED) {
      //   rejected_remark = remark.value;
      // }
      var rejected_remark = "";
      let data = {
        business_id: businessObject.business_id,
        acton_data: actionMessage.action,
        action_type: actionMessage.actionType,
        // acton_remark: rejected_remark,
      };
      dispatch(businessAction(data, t))
        .then((res) => {
          return navigate(businessObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid></Grid>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12} md={12}>
                {actionMessage.confirmation}
              </Grid>
              {actionMessage &&
              actionMessage.action == appConstant.ACTION_REJECTED ? (
                <Grid item lg={12} xs={12} md={12}>
                  <TextField
                    id="txtRemark"
                    onChange={handleChangeRemark}
                    // id="outlined-basic"
                    label="Enter remark"
                    variant="outlined"
                    fullWidth
                    multiline={true}
                    rows={2}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<HighlightOffIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={submitActionData}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Card className={classes.card}>
        {/* <CardActionArea> */}
        {/* <CardMedia
          className={classes.media}
          image={banner_image + businessObject.banner_image}
          title={businessObject.business_name}
        /> */}
        <SRLWrapper>
          <Box
            component="img"
            sx={{
              height: 300,
              display: "block",
              // maxWidth: 400,
              overflow: "hidden",
              width: "100%",
            }}
            src={banner_image + businessObject.banner_image}
            alt={businessObject.business_name}
          />
        </SRLWrapper>
        <CardContent>
          {/* <Typography gutterBottom variant="h5">
        Automobiles parts
      </Typography> */}

          <Grid container spacing={0.5}>
            <Grid item lg={6} xs={12} md={12}>
              <Typography variant="h5" gutterBottom>
                {businessObject.business_name}
              </Typography>
            </Grid>

            <Grid item lg={6} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Catergory
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.cat_name}{" "}
                {businessObject.category_name != "" ? (
                  <> ({businessObject.category_name}) </>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Owner
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.business_owner_name}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block" }}
              >
                Region
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.region_name}
                {businessObject.city_village != "" ? (
                  <> ({businessObject.city_village}) </>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block" }}
              >
                Contact
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.contact_no}
              </Typography>
            </Grid>

            <Grid item lg={12} xs={12} md={12}>
              <Typography variant="subtitle2">About</Typography>
              <Typography variant="body2">{businessObject.about}</Typography>
            </Grid>

            {businessObject.other_info != "" ? (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Typography variant="subtitle2">Other Information</Typography>
                  <Typography variant="body2">
                    {businessObject.other_info}
                  </Typography>
                </Grid>
              </>
            ) : (
              ""
            )}

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Address
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.address}
              </Typography>
            </Grid>
            {businessObject.site_url != "" ? (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Typography variant="subtitle2">Website URL</Typography>
                  {/* <>
                <Link
                  key="8989"
                  onClick={() => {
                    window.open(businessObject.site_url, "_blank");
                  }}
                >
                 
                </Link>
              </> */}

                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(businessObject.site_url, "_blank");
                    }}
                  >
                    {businessObject.site_url}
                  </Link>

                  {/* <Button
                size="small"
                color="primary"
                onClick={() => {
                  window.open(businessObject.site_url, "_blank");
                }}
              ></Button> */}

                  {/* <Typography variant="body2">
                {businessObject.site_url}
              </Typography> */}
                </Grid>
              </>
            ) : (
              ""
            )}

            <Grid item lg={12} xs={12} md={12}>
              <Typography
                style={{ display: "inline-block" }}
                variant="subtitle2"
              >
                Status
              </Typography>
              <Typography
                variant="body2"
                style={{ display: "inline-block", marginLeft: 6 }}
              >
                {businessObject.business_status}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {/* </CardActionArea> */}
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              return navigate("/business");
            }}
          >
            Back
          </Button>

          {businessObject.is_edit ? (
            <>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  return navigate("/business-create", {
                    state: {
                      data: businessObject,
                      parentRoute: window.location.pathname,
                    },
                  });
                }}
              >
                Edit
              </Button>
            </>
          ) : (
            ""
          )}
          {businessObject.is_approval ? (
            <>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  let data = {
                    action: "Approved",
                    confirmation: "Are you sure you want to approve ?",
                    actionType: appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                  };
                  setActionMessage(data);
                  action();
                }}
              >
                Approve
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  let data = {
                    action: "Rejected",
                    confirmation: "Are you sure you want to reject ?",
                    actionType: appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                  };
                  setActionMessage(data);
                  action();
                }}
              >
                Reject
              </Button>
            </>
          ) : (
            ""
          )}
        </CardActions>
      </Card>{" "}
    </>
  );
};

export default BusinessDetails;
