import reviewServices from "../services/review-services";
import { showSuccessSnackbar } from "./snackbarActions";

export const createReview = (requestData, t) => (dispatch) => {
  return reviewServices.createReview(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const getReview = (requestData, t) => (dispatch) => {
  return reviewServices.getReview(requestData).then(
    (response) => {
      // dispatch(
      //   showSuccessSnackbar({
      //     status: response.data.status,
      //     message: response.data.message,
      //   })
      // );
      return Promise.resolve(response.data.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
