import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Textfield from "../FormsUI/Textfield";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp, otpVerification, register } from "../../actions/auth";
import { getMasterData } from "../../actions/master-data-action";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "../FormsUI/Select";
import { useTranslation } from "react-i18next";
import { Backdrop } from "@mui/material";
import master_data from "../../masterData/master_data.json";
import Spinner from "react-spinner-material";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">ABN Scripts Technologies</Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Register() {
  const formikRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn, loginLoading } = useSelector((state) => state.auth);
  const [optVerification, setOptVerification] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sendOtpResponse, setSendOtpResponse] = useState([]);
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );
  const [regionList, setRegionList] = useState(1);
  const [getMasters, setMasters] = useState();
  const [userEnteredValue, setUserEnteredValue] = useState({});
  const [isVerified, setIsVerified] = useState(3);
  const [openSpinner, setOpenSpinner] = React.useState(true);

  var redirectionUrl = useLocation().state;
  const [stateDataFromURL, setStateDataFromURL] = React.useState(
    redirectionUrl == null ? null : redirectionUrl.parentRoute
  );
  const [castList, setCastList] = useState(1);
  const [districtList, setDistrictList] = useState(1);
  const [stateList, setStateList] = useState(
    master_data.filter((e) => e.state_list)[0]["state_list"]
  );
  const dispatch = useDispatch();
  const INITIAL_FORM_STATE = {
    fname: "",
    lname: "",
    mobile_no: "",
    region_id: "",
    city_village_name: "",
    cast_id: "",
    state_id: "",
    district_id: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    fname: Yup.string().required(t("signup_page.err_fname_requried")),
    lname: Yup.string().required(t("signup_page.err_lname_requried")),
    mobile_no: Yup.string().required(t("signup_page.err_mobile_no_requried")),
    // region_id: Yup.string().required(t("signup_page.err_req_region_name")),
    city_village_name: Yup.string(),

    region_id: Yup.string(),

    cast_id: Yup.string(),

    state_id: Yup.string().required(t("signup_page.err_req_state_name")),

    district_id: Yup.string().required(t("signup_page.err_req_district_name")),
  });

  useEffect(() => {
    formikRef.current.setFieldValue("mobile_no", redirectionUrl.data, false);

    formikRef.current.setFieldValue("state_id", "20", true);
    ddlCastOnChanges(20);

    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }
  }, []);
  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];
    setRegionList(region_list);
    // const cast_list = inputMasterData.filter((e) => e.cast_list)[0][
    //   "cast_list"
    // ];
    // setCastList(cast_list);
    // const state_list = inputMasterData.filter((e) => e.state_list)[0][
    //   "state_list"
    // ];
    // setStateList(state_list);
  };

  const ddlCastOnChanges = (event) => {
    formikRef.current.setFieldValue("district_id", "", false);
    const get_state_list = master_data.filter((e) => e.states)[0]["states"];
    let getSelectedData = get_state_list.data.filter((e) => e.id == event);
    if (getSelectedData) {
      getSelectedData.forEach((element) => {
        setDistrictList(
          element.districts.reduce(function(result, current) {
            return Object.assign(result, current);
          }, {})
        );
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        {loginLoading ? (
          <>
            {" "}
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openSpinner}
            >
              <Spinner
                radius={50}
                color={"#e60e96"}
                stroke={2}
                visible={true}
              />
            </Backdrop>{" "}
          </>
        ) : (
          ""
        )}

        <CssBaseline />
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar> */}

          {isVerified == 0 || isVerified == 2 ? (
            <></>
          ) : (
            <>
              {" "}
              <Typography component="h4" variant="h5">
                {t("signup_page.lbl_header_sigup")}
              </Typography>
            </>
          )}

          <Box sx={{ mt: 1 }}>
            <Formik
              innerRef={formikRef}
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const isEmail = values.mobile_no.includes("@");
                if (isEmail) {
                  dispatch(
                    showSuccessSnackbar({
                      hideDuration: 5000,
                      status: "error",
                      message:
                        "Email login not available yet,Please enter your mobile no",
                    })
                  );
                  return;
                } else {
                  console.log();

                  if (values.state_id == 0) {
                    dispatch(
                      showSuccessSnackbar({
                        hideDuration: 5000,
                        status: "error",
                        message: "Please select the state.",
                      })
                    );
                    return;
                  }

                  if (values.district_id == 0) {
                    dispatch(
                      showSuccessSnackbar({
                        hideDuration: 5000,
                        status: "error",
                        message: "Please select the district.",
                      })
                    );
                    return;
                  }
                  let data = {
                    fname: values.fname,
                    lname: values.lname,
                    mobile_no: values.mobile_no,
                    password: values.password,
                    region_id: "0",
                    city_village_name: values.city_village_name,
                    cast_id: "0",
                    cast_name: "no",
                    state_id: values.state_id,
                    state_name: stateList[values.state_id],
                    district_id: values.district_id,
                    district_name: districtList[values.district_id],
                  };
                  dispatch(otpVerification(data, t))
                    .then((verificationResult) => {
                      if (verificationResult.is_verified == 1) {
                        setTimeout(function() {
                          if (stateDataFromURL == null) {
                            return navigate("/user/bride-groom-list");
                          } else {
                            return navigate(stateDataFromURL);
                          }
                        }, 1000);
                      } else {
                        setTimeout(function() {
                          return navigate("/user/create-profile");
                        }, 1000);
                      }
                    })
                    .catch((error) => {
                      setTimeout(function() {
                        setSuccess(true);
                        setLoading(false);
                      }, 1000);
                    });
                }
              }}
            >
              <Form>
                {isVerified == 0 || isVerified == 2 ? (
                  <>
                    {" "}
                    <Grid container spacing={1}>
                      <Grid item lg={12} xs={12} md={12}>
                        <Typography
                          fullWidth
                          style={{ color: "red" }}
                          align="center"
                          variant="subtitle1"
                          gutterBottom
                        >
                          {t("login_page.lbl_user_registration_message")}
                        </Typography>
                      </Grid>
                      <Grid item lg={12} xs={12} md={12}>
                        <Box display="flex" justifyContent="center">
                          <Button
                            onClick={() => {
                              return navigate("/home");
                            }}
                          >
                            Go to home
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid container spacing={2}>
                      {optVerification == false && (
                        <>
                          <Grid item lg={6} xs={12} md={12}>
                            <Textfield
                              name="fname"
                              autoComplete="nope"
                              label={t("signup_page.lbl_fname")}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12} md={12}>
                            <Textfield
                              name="lname"
                              autoComplete="nope"
                              label={t("signup_page.lbl_lname")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Textfield
                              disabled
                              name="mobile_no"
                              autoComplete="nope"
                              label={t("signup_page.lbl_mobile_no")}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                            <Select
                              name="cast_id"
                              label={t("signup_page.lbl_cast")}
                              options={castList}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <Select
                              name="state_id"
                              label={t("signup_page.lbl_state")}
                              options={stateList}
                              onChangeHandle={ddlCastOnChanges}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Select
                              name="district_id"
                              label={t("signup_page.lbl_region_name")}
                              options={districtList}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Textfield
                              name="city_village_name"
                              autoComplete="nope"
                              label={t("signup_page.lbl_city_village")}
                            />
                          </Grid>
                        </>
                      )}
                      {optVerification == true && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              style={{ color: "blue" }}
                              align="center"
                              variant="subtitle1"
                              gutterBottom
                            >
                              {userEnteredValue.isEmail
                                ? t("signup_page.lbl_otp_sent_on_email")
                                : t("signup_page.lbl_otp_sent_on_mobile")}{" "}
                              {userEnteredValue.mobile}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Textfield name="otp" label="Enter Otp" />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      loading="true"
                    >
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "white",
                          }}
                        />
                      )}
                      {optVerification
                        ? "Continue"
                        : t("signup_page.lbl_btn_sign_up")}
                    </Button>
                    {optVerification == true ? (
                      <>
                        <Button
                          size="small"
                          fullWidth
                          color="primary"
                          onClick={() => {
                            setOptVerification(false);
                            formikRef.current.setFieldValue("otp", "", false);
                          }}
                        >
                          {userEnteredValue.isEmail
                            ? t("login_page.lbl_change_email")
                            : t("login_page.lbl_change_mobile")}
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Form>
            </Formik>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
