import {
  CATEGORY_ITEM_LIST_REQUEST,
  CATEGORY_ITEM_LIST_SUCCESS,
  CATEGORY_ITEM_LIST_FAIL,
  CATEGORY_ITEM_CREATE,
  CATEGORY_ITEM_UPDATE,
  CATEGORY_ITEM_DELETE,
  CATEGORY_ITEM_ENABLE_DISABLE,
} from "../actions/types";
const initialState = {
  loaded: false,
  loading: false,
  error: false,
  categories: [],
};

const categoryItemReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CATEGORY_ITEM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_ITEM_LIST_SUCCESS:
      return {
        ...state,
        categories: payload.categories,
        error: false,
        loading: false,
        loaded: true,
      };
    // case CATEGORY_MASTER:
    //   let data = payload.categories((e) => e.parent === null || e.parent === 0);
    //   let result = {};
    //   for (var i = 0; i < data.length; i++) {
    //     result[`${data[i]["cat_id"]}`] = `${data[i]["label"]}`;
    //   }
    //   return {
    //     ...state,
    //     categories: data,
    //     error: false,
    //     loading: false,
    //     loaded: true,
    //   };

    case CATEGORY_ITEM_LIST_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case CATEGORY_ITEM_CREATE:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case CATEGORY_ITEM_UPDATE:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case CATEGORY_ITEM_DELETE:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: false,
        categories: [],
      };
    default:
      return state;
  }
};
export default categoryItemReducer;
