import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import { clearSnackbar } from "../../actions/snackbarActions";

import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function SuccessSnackbar() {
  const dispatch = useDispatch();

  const { successSnackbarMessage, successSnackbarOpen } = useSelector(
    (state) => state.uiReducer
  );
  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={
          successSnackbarMessage.hideDuration
            ? successSnackbarMessage.hideDuration
            : 5000
        }
        onClose={handleClose}
        // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          onClose={handleClose}
          severity={
            successSnackbarMessage.status === "error" ? "error" : "success"
          }
        >
          {successSnackbarMessage.message}
        </Alert>
      </Snackbar>
    </>
    // <Snackbar
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "left",
    //   }}
    //   open={successSnackbarOpen}
    //   autoHideDuration={4000}
    //   onClose={handleClose}
    //   aria-describedby="client-snackbar"
    //   message={
    //     <span id="client-snackbar">
    //       {/* <Icon>check_circle</Icon> */}
    //       {successSnackbarMessage}
    //     </span>
    //   }
    //   action={[
    //     <IconButton
    //       key="close"
    //       aria-label="close"
    //       color="inherit"
    //       onClick={handleClose}
    //     >
    //       <Icon>close</Icon>
    //     </IconButton>,
    //   ]}
    // />
  );
}
