import axiosInstance from "../middleware/axiosConfig";

const getApproveList = (requestData) => {
  return axiosInstance.post("committee/get-approve-list", requestData);
};
const create = (requestData) => {
  return axiosInstance.post("committee/create", requestData);
};
const updateProfilePhoto = (requestData) => {
  return axiosInstance.post("committee/update-profile-photo", requestData);
};
const enableDisable = (requestData) => {
  return axiosInstance.post("committee/enable-disable", requestData);
};

export default {
  getApproveList,
  create,
  updateProfilePhoto,
  enableDisable,
};
