export const ACTION_PENDING = "Pending";
export const ACTION_APPROVED = "Approved";
export const ACTION_REJECTED = "Rejected";
export const ACTION_DELETED = "2";
export const ACTION_ENABLED = "1";
export const ACTION_DISABLED = "0";
export const ACTIONT_TYPE_APPROVE_REJECT = "approv_reject";
export const ACTIONT_TYPE_ENABLE_DISABLE = "enable_disable";
export const ACTIONT_TYPE_PROFILE_PHOTO = "profile_photo";

export const ACTIONT_TYPE_IS_VADHU_VAR_PAGE_ACCESS = "is_vadhu_var_access";
export const ACTIONT_Is_VADHU_VAR_PROFILE_LOCK = "is_vadhu_var_profile_lock";
