import userServices from "../services/user.service";
import { showSuccessSnackbar } from "./snackbarActions";

export const getUserList = (requestData, t) => (dispatch) => {
  return userServices.getUserList(requestData).then(
    (response) => {
      return Promise.resolve(response.data.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};

export const userAction = (requestData, t) => (dispatch) => {
  return userServices.getUserAction(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      // dispatch({
      //   type: VADHUVAR_LIST_REFRESH,
      // });
      // dispatch({
      //   type: VADHUVAR_LIST_APPROVAL_REFRESH,
      // });
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getContactUsList = (requestData, t) => (dispatch) => {
  return userServices.getContactUsList(requestData).then(
    (response) => {
      return Promise.resolve(response.data.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};
