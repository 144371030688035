import axiosInstance from "../middleware/axiosConfig";

const createReview = (requestData) => {
  return axiosInstance.post("review/create", requestData);
};

const getReview = (requestData) => {
  return axiosInstance.post("reviews", requestData);
};

const getUserAction = (requestData) => {
  return axiosInstance.post("user-actions", requestData);
};

const getContactUsList = (requestData) => {
  return axiosInstance.post("get-contact-us", requestData);
};

export default {
  createReview,
  getUserAction,
  getContactUsList,
  getReview,
};
