import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import Textfield from "../FormsUI/Textfield";
import Select from "../FormsUI/Select";
import master_data from "../../masterData/master_data.json";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { default as StyledButton } from "../FormsUI/Button";
import { getMasterData } from "../../actions/master-data-action";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box } from "@mui/material";
import { SRLWrapper } from "simple-react-lightbox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Spinner from "react-spinner-material";
import Backdrop from "@mui/material/Backdrop";
import { saveAs } from "file-saver";
// import {
//   getMasterCategories,
//   updateBannerImage,
// } from "../../actions/business-action";

import { createUpdateJobProfile } from "../../actions/job-actions";

import { showSuccessSnackbar } from "../../actions/snackbarActions";

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import GenFileUpload from "../fileUplod/GenFileUpload";
import GenDocFileUpload from "../fileUplod/GenDocFileUpload";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },

    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    width: "100%",

    // width: '100%',
    //objectFit: "contain",
    //width: "auto",
    //maxHeight: "250",

    // height: 250,
    // paddingTop: "56.25%", // 16:9,
    // marginTop: "30",
  },
  contain: {
    // objectFit: "contain",
    // height: "250px",
    // width: "200px",

    height: 250,
    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
}));

const CreateJobProfile = ({ img, title }) => {
  const [isAdd, setIsAdd] = useState(true);
  const [regionList, setRegionList] = useState(1);
  const [getMasters, setMasters] = useState();
  const [categories, setCategories] = useState([]);
  const [passDataToChild, setPassDataToChild] = useState({});
  const { isLoggedIn } = useSelector((state) => state.auth);

  const [uploadedResult, setUploadedResult] = useState("");
  const [educationList, setEducatioList] = useState(1);

  const [isPageLoaded, setIsPageLoaded] = useState(false);
  //***file upload */

  //***end file upload */
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );
  const [openSpinner, setOpenSpinner] = React.useState(true);
  const { commonLoading } = useSelector((state) => state.commonLoadingReducer);

  const formikRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const businessLocationObject = useLocation().state;
  var businessObject = null;
  var job_profile_id = 0;
  if (businessLocationObject) {
    businessObject = businessLocationObject.data;
    job_profile_id = businessObject.job_profiles_id;
  }

  const [jobProfilesId, seJobProfilesId] = React.useState(job_profile_id);

  const IMAGE_API_URL =
    process.env.REACT_APP_ENV == "DEV"
      ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
      : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

  const banner_image = IMAGE_API_URL + "banner?type=1&id=";

  const dispatch = useDispatch();

  const INITIAL_FORM_STATE = {
    region_id: "",
    fname: "",
    mname: "",
    lname: "",
    gender: "",
    married_status: "",
    dob: "",
    current_address: "",
    permanent_address: "",
    pincode: "",
    city_or_village: "",
    contact_no: "",
    email_id: "",

    education_id: "",
    specialization: "",
    collage_name: "",
    university_name: "",
    passing_year: "",
    // if_any_certification: "",
    name_of_certification: "",
    key_skill: "",
    know_language: "",
    experience: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    region_id: Yup.string()
      .max(10)
      .required("Required"),
    fname: Yup.string()
      .max(30)
      .required("Required"),
    mname: Yup.string()
      .max(30)
      .required("Required"),
    lname: Yup.string()
      .max(30)
      .required("Required"),
    gender: Yup.string()
      .max(20)
      .required("Required"),
    married_status: Yup.string()
      .max(20)
      .required("Required"),
    dob: Yup.string()
      .max(30)
      .required("Required"),
    contact_no: Yup.number()
      .integer()
      .typeError("Please enter a valid phone number")
      .required("Required"),
    current_address: Yup.string()
      .max(250)
      .required("Required"),
    permanent_address: Yup.string()
      .max(250)
      .required("Required"),
    pincode: Yup.string()
      .max(10)
      .required("Required"),
    city_or_village: Yup.string()
      .max(30)
      .required("Required"),

    education_id: Yup.string()
      .max(35)
      .required("Required"),
    specialization: Yup.string().max(40),
    collage_name: Yup.string()
      .max(50)
      .required("Required"),
    university_name: Yup.string()
      .max(50)
      .required("Required"),
    passing_year: Yup.string()
      .max(30)
      .required("Required"),
    name_of_certification: Yup.string().max(100),

    //name_of_certification: "",
    key_skill: Yup.string().max(30),

    know_language: Yup.string()
      .max(50)
      .required("Required"),
    experience: Yup.string()
      .max(50)
      .required("Required"),
    // language_proficiency: "",

    // region_id: Yup.string()
    //   .max(10)
    //   .required("Required"),
    // category_id: Yup.string()
    //   .max(10)
    //   .required("Required"),

    // business_name: Yup.string()
    //   .max(150)
    //   .required("Required"),
    // business_owner_name: Yup.string()
    //   .max(100)
    //   .required("Required"),

    // business_about: Yup.string()
    //   .max(5000)
    //   .required("Required"),

    // contact_no: Yup.number()
    //   .integer()
    //   .typeError("Please enter a valid phone number")
    //   .required("Required"),

    // address: Yup.string()
    //   .max(500)
    //   .required("Required"),
    // city_village: Yup.string()
    //   .max(75)
    //   .required("Required"),
    // pincode: Yup.string()
    //   .max(250)
    //   .required("Required"),
  });

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    }

    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }

    // let getCategory = {
    //   mobile_no: "9730996626",
    // };
    // dispatch(getMasterCategories(getCategory)).then((catRes) => {
    //   let resultCategory = {};
    //   const cnames1 = catRes.map((cname) => {
    //     var key = cname["id"];
    //     let data = {
    //       [key]: cname["cat_name"],
    //     };
    //     Object.assign(resultCategory, data);
    //   });
    //   setCategories(resultCategory);
    // });

    if (businessObject != null) {
      setUploadedResult({ file_path: businessObject.resume_path });
      setIsAdd(false);
      const result = {
        region_id: businessObject.region_id,
        fname: businessObject.fname,
        mname: businessObject.mname,
        lname: businessObject.lname,
        dob: businessObject.dob,
        contact_no: businessObject.contact_no,
        email_id: businessObject.email_id,
        current_address: businessObject.current_address,
        permanent_address: businessObject.permanent_address,
        city_or_village: businessObject.city_or_village,
        pincode: businessObject.pincode,
        education_id: businessObject.education_id,

        specialization: businessObject.spacialization,
        collage_name: businessObject.collage_name,
        university_name: businessObject.university_name,

        passing_year: businessObject.passing_year,
        name_of_certification: businessObject.any_cartification,
        key_skill: businessObject.key_skill,

        know_language: businessObject.know_language,
        experience: businessObject.experience,

        gender: businessObject.gender,
        married_status: businessObject.married_status,
      };
      const fields = [
        "region_id",
        "fname",
        "mname",
        "lname",
        "dob",
        "contact_no",
        "email_id",
        "current_address",
        "permanent_address",
        "city_or_village",
        "pincode",
        "education_id",
        "specialization",
        "collage_name",
        "university_name",
        "passing_year",
        "key_skill",
        "name_of_certification",
        "know_language",
        "experience",
        "gender",
        "married_status",
      ];
      fields.forEach((field) =>
        formikRef.current.setFieldValue(field, result[field], false)
      );
    }
    setIsPageLoaded(true);
  }, []);

  const createNewObject = (item) => {
    let data = {};
    return [data].join(",");
  };
  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];
    setRegionList(region_list);
    const education_list = inputMasterData.filter((e) => e.education)[0][
      "education"
    ];
    setEducatioList(education_list);
  };

  const submitHandle = (formData) => {
    if (uploadedResult == "") {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: "Please upload your resume with doc/pdf file format",
        })
      );
    } else {
      if (isAdd == true) {
        formData.operation_type = "add";
        formData.job_profile_id = 0;
        formData.resume_file_path = uploadedResult.uploaded_file_path;
      } else {
        formData.operation_type = "update";
        formData.job_profile_id = businessObject.job_profiles_id;
        formData.resume_file_path = "file path";
      }
      dispatch(createUpdateJobProfile(formData, t)).then((res) => {
        return navigate("/job-profile-list");
      });
    }
  };

  const getResponseFromUpload = (data) => {
    setUploadedResult(data);
  };

  const parentToChild = () => {
    setPassDataToChild(businessObject);
  };

  return (
    <>
      {commonLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>
        </>
      ) : (
        ""
      )}
      <Card className={classes.card}>
        <Grid
          container
          spacing={2}
          style={{
            background: "#cfd8dc",
          }}
        >
          <Grid item lg={2} xs={2} md={2}>
            <Box display="flex" justifyContent="left"></Box>
          </Grid>
          <Grid item lg={8} xs={8} md={8}>
            <Box display="flex" justifyContent="center">
              <Button>Upload resume</Button>
            </Box>
          </Grid>
          <Grid item lg={2} xs={2} md={2}>
            <Box display="flex" justifyContent="right"></Box>
          </Grid>
        </Grid>
        {/* <Divider>
          <Chip label="Create job profile" />
        </Divider> */}
        {isPageLoaded ? (
          <>
            <GenDocFileUpload
              parentToChild={jobProfilesId}
              childToParent={getResponseFromUpload}
            />
            {/* src={banner_image + uploadedResult.image_path} */}
          </>
        ) : (
          ""
        )}

        {uploadedResult ? (
          <>
            <Box display="flex" justifyContent="center">
              {jobProfilesId != 0 ? (
                <>
                  {" "}
                  <Button
                    size="samll"
                    color="primary"
                    onClick={(event) => {
                      let getFileExtension = businessObject.resume_path
                        .split(".")
                        .pop();
                      let fileUrl =
                        IMAGE_API_URL +
                        "download-resume?id=" +
                        businessObject.resume_path;
                      saveAs(
                        fileUrl,
                        businessObject.fname +
                          "_" +
                          businessObject.lname +
                          "_resume." +
                          getFileExtension
                      );
                    }}
                  >
                    Download resume
                  </Button>{" "}
                </>
              ) : (
                ""
              )}
            </Box>
          </>
        ) : (
          ""
        )}
        {/* <img
          className="contain"
          src={banner_image + uploadedResult.image_path}
          alt="MDN Logo"
        /> */}
        <CardContent>
          <Formik
            innerRef={formikRef}
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => {
              submitHandle(values);
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12} md={12}>
                  <Divider>
                    <Chip label="Personal Details" />
                  </Divider>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Select
                    name="region_id"
                    label="Select Region"
                    options={regionList}
                  />
                </Grid>

                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="fname" label="First name" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="mname" label="Middle name" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="lname" label="Last name" />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Select
                    name="gender"
                    label="Select gender"
                    options={master_data[0]}
                  />
                </Grid>

                <Grid item lg={2} xs={12} md={12}>
                  <Select
                    name="married_status"
                    label="Married status"
                    options={master_data[2]}
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Textfield name="dob" label="Date of birth(dd/mm/yy)" />
                </Grid>

                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="contact_no" label="Contact no" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="email_id" label="Email-id" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield
                    name="current_address"
                    rows={3}
                    multiline={true}
                    label="Current address"
                  />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield
                    name="permanent_address"
                    rows={3}
                    multiline={true}
                    label="Permanent address"
                  />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield
                    name="city_or_village"
                    label="City or village name"
                  />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="pincode" label="Pincode" />
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                  <Divider>
                    <Chip label="Educational Details" />
                  </Divider>
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Select
                    name="education_id"
                    label="Education"
                    options={educationList}
                  />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="specialization" label="Specialization" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="collage_name" label="Collage name" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="university_name" label="University name" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield name="key_skill" label="Key skill" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="passing_year" label="Passing year" />
                </Grid>

                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="experience" label="Experience" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield name="know_language" label="Know language" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield
                    name="name_of_certification"
                    label="Any certification"
                  />
                </Grid>

                {/* <Grid item lg={12} xs={12} md={12}>
                  <Textfield
                    name="business_about"
                    label="About your business"
                    multiline={true}
                    rows={6}
                  />
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                  <Textfield
                    name="business_other_info"
                    label="Other Information"
                    multiline={true}
                    rows={4}
                  />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield
                    name="business_web_site_link"
                    label="Web site link (https://...)"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <CardActions>
                    <StyledButton
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      {isAdd ? "Create" : "Update"}
                    </StyledButton>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        return navigate("/job-profile-list");
                      }}
                    >
                      Back
                    </Button>
                  </CardActions>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

export default CreateJobProfile;
