import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_CREATE,
  PRODUCT_UPDATE,
  PRODUCT_DELETE,
  PRODUCT_ENABLE_DISABLE,
  PRODUCT_CLEAR_FORM,
} from "../actions/types";
const initialState = {
  loaded: false,
  loading: false,
  error: false,
  products: [],
};

const productReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        products: payload.products,
        error: false,
        loading: false,
        loaded: true,
      };
    case PRODUCT_LIST_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case PRODUCT_CREATE:
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    case PRODUCT_UPDATE:
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    case PRODUCT_DELETE:
      return {
        ...state,
        products: [],
        loaded: false,
        loading: false,
      };
    case PRODUCT_ENABLE_DISABLE:
      return {
        ...state,
      };
    case PRODUCT_CLEAR_FORM:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default productReducer;
