import axios from "axios";
// const API_URL = configData.SERVER_URL;

let headers = {};
const baseURL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_SERVER_URL
    : process.env.REACT_APP_PROD_SERVER_URL;
// const jwtToken = JSON.parse(localStorage.getItem("user"));
// if (jwtToken && jwtToken.data.access_token) {
//   headers.Authorization = "Bearer " + jwtToken.data.access_token;
// }

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
  // timeout: 1000,
});

export default axiosInstance;
