import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import Textfield from "../FormsUI/Textfield";
import Select from "../FormsUI/Select";
import master_data from "../../masterData/master_data.json";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { default as StyledButton } from "../FormsUI/Button";
import { getMasterData } from "../../actions/master-data-action";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box } from "@mui/material";
import { SRLWrapper } from "simple-react-lightbox";
// import {
//   getMasterCategories,
//   updateBannerImage,
// } from "../../actions/business-action";

import { createUpdateJobPost } from "../../actions/job-actions";

import { showSuccessSnackbar } from "../../actions/snackbarActions";

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import GenFileUpload from "../fileUplod/GenFileUpload";
import RichEditor from "../commonComponent/RichEditor";
import ReadOnly from "../commonComponent/ReadOnly";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Spinner from "react-spinner-material";
import Backdrop from "@mui/material/Backdrop";
const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },

    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    width: "100%",

    // width: '100%',
    //objectFit: "contain",
    //width: "auto",
    //maxHeight: "250",

    // height: 250,
    // paddingTop: "56.25%", // 16:9,
    // marginTop: "30",
  },
  contain: {
    // objectFit: "contain",
    // height: "250px",
    // width: "200px",

    height: 250,
    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
}));

const JobPosting = ({ img, title }) => {
  const [isAdd, setIsAdd] = useState(true);
  const [regionList, setRegionList] = useState(1);
  const [getMasters, setMasters] = useState();
  const [categories, setCategories] = useState([]);
  const [passDataToChild, setPassDataToChild] = useState({});
  const { isLoggedIn } = useSelector((state) => state.auth);

  const [uploadedResult, setUploadedResult] = useState("");
  const [employmentType, setEmploymentType] = useState({});
  const [openSpinner, setOpenSpinner] = React.useState(true);
  const { commonLoading } = useSelector((state) => state.commonLoadingReducer);

  // const initialTextValue = [
  //   {
  //     type: "paragraph",
  //     children: [{ text: "" }],
  //   },
  // ];

  const [isPageLoaded, setIsPageLoaded] = useState(false);
  //***file upload */

  //***end file upload */
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );

  const formikRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const businessLocationObject = useLocation().state;
  var businessObject = null;
  if (businessLocationObject) {
    businessObject = businessLocationObject.data;
  }

  const IMAGE_API_URL =
    process.env.REACT_APP_ENV == "DEV"
      ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
      : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

  const banner_image = IMAGE_API_URL + "banner?type=1&id=";

  const dispatch = useDispatch();

  const INITIAL_FORM_STATE = {
    job_title: "",
    company_name: "",
    requried_exp: "",
    salary_offered: "",
    job_description: "",
    education: "",
    key_skill: "",
    employment_type: "",
    job_location: "",
    job_address: "",
    contact_person: "",
    contact_number: "",
    contact_email: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    job_title: Yup.string()
      .max(50)
      .required("Required"),
    company_name: Yup.string()
      .max(50)
      .required("Required"),
    requried_exp: Yup.string()
      .max(30)
      .required("Required"),
    salary_offered: Yup.string()
      .max(45)
      .required("Required"),
    education: Yup.string()
      .max(45)
      .required("Required"),
    key_skill: Yup.string()
      .max(100)
      .required("Required"),

    employment_type: Yup.string()
      .max(50)
      .required("Required"),

    job_location: Yup.string()
      .max(50)
      .required("Required"),
    job_address: Yup.string()
      .max(250)
      .required("Required"),

    contact_person: Yup.string()
      .max(250)
      .required("Required"),

    contact_number: Yup.number()
      .integer()
      .typeError("Please enter a valid phone number")
      .required("Required"),

    contact_email: Yup.string()
      .max(250)
      .required("Required"),
  });

  var jobDescDetails = [];
  if (businessObject != null) {
    jobDescDetails = JSON.parse(businessObject.job_description);
  } else {
    jobDescDetails = [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
  }
  const [input, setInput] = useState(jobDescDetails);
  const [jobDescription, setJobDescription] = useState(jobDescDetails);

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    }

    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }

    // let getCategory = {
    //   mobile_no: "9730996626",
    // };
    // dispatch(getMasterCategories(getCategory)).then((catRes) => {
    //   let resultCategory = {};
    //   const cnames1 = catRes.map((cname) => {
    //     var key = cname["id"];
    //     let data = {
    //       [key]: cname["cat_name"],
    //     };
    //     Object.assign(resultCategory, data);
    //   });
    //   setCategories(resultCategory);
    // });

    if (businessObject != null) {
      // setUploadedResult({ image_path: businessObject.banner_image });
      setIsAdd(false);
      const result = {
        job_title: businessObject.title,
        company_name: businessObject.company_name,
        requried_exp: businessObject.experience,
        salary_offered: businessObject.salary_offered,
        education: businessObject.education,
        key_skill: businessObject.key_skill,
        employment_type: businessObject.employment_type,
        job_location: businessObject.job_location,
        job_address: businessObject.job_address,
        contact_person: businessObject.contact_person_name,
        contact_number: businessObject.contact_person_number,
        contact_email: businessObject.contact_person_email,
      };
      const fields = [
        "job_title",
        "company_name",
        "requried_exp",
        "salary_offered",
        "education",
        "key_skill",
        "employment_type",
        "job_location",
        "job_address",
        "pincode",
        "contact_person",
        "contact_number",
        "contact_email",
      ];
      fields.forEach((field) =>
        formikRef.current.setFieldValue(field, result[field], false)
      );
    } else {
    }

    setIsPageLoaded(true);
  }, []);

  const createNewObject = (item) => {
    let data = {};
    return [data].join(",");
  };
  const bindDDLList = (inputMasterData) => {
    const employment_type = inputMasterData.filter((e) => e.employment_type)[0][
      "employment_type"
    ];
    setEmploymentType(employment_type);
  };

  const submitHandle = (formData) => {
    if (
      false
      //uploadedResult == ""
    ) {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: "Please upload banner image",
        })
      );
    } else {
      if (isAdd == true) {
        formData.operation_type = "add";
        formData.jm_job_posted_id = 0;
        formData.job_id = 0;
        //formData.business_status = "Pending";
        // formData.resume_file_path = "file path";
        //uploadedResult.image_path;
      } else {
        formData.operation_type = "update";
        formData.job_id = businessObject.job_id;
        formData.jm_job_posted_id = businessObject.jm_job_posted_id;
        formData.job_id = businessObject.jm_job_posted_id;
        //formData.business_status = businessObject.business_status;
        //formData.resume_file_path = businessObject.banner_image;
      }

      formData.job_description = JSON.stringify(jobDescription);

      dispatch(createUpdateJobPost(formData, t)).then((res) => {
        return navigate("/my-job-post");
      });
    }
  };

  const getResponseFromUpload = (data) => {
    setUploadedResult(data);

    if (isAdd == false) {
      // let reqData = {
      //   business_id: businessObject.business_id,
      //   action_type: "image_update",
      //   business_status: businessObject.business_status,
      //   old_image_path: businessObject.banner_image,
      //   image_path: data.image_path,
      // };
      // dispatch(updateBannerImage(reqData, t)).then((res) => {
      //   return navigate("/business");
      // });
    }
  };

  const parentToChild = () => {
    setPassDataToChild(businessObject);
  };

  return (
    <>
      {commonLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>
        </>
      ) : (
        ""
      )}
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                return navigate("/job-updates");
              }}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button>Post job openning</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right"></Box>
        </Grid>
      </Grid>
      <Card className={classes.card}>
        <CardContent>
          <Formik
            innerRef={formikRef}
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => {
              submitHandle(values);
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield name="job_title" label="Title" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield name="company_name" label="Company name" />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Textfield name="requried_exp" label="Experience" />
                </Grid>

                <Grid item lg={2} xs={12} md={12}>
                  <Textfield name="salary_offered" label="Salary offered" />
                </Grid>

                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="education" label="Education" />
                </Grid>

                <Grid item lg={5} xs={12} md={12}>
                  <Textfield name="key_skill" label="Key skill" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  {/* <Textfield name="employment_type" label="Employment Type" /> */}

                  <Select
                    name="employment_type"
                    label="Employment Type"
                    options={employmentType}
                  />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="contact_person" label="Contact person" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="contact_number" label="Contact number" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="contact_email" label="Email" />
                </Grid>

                <Grid item lg={4} xs={12} md={12}>
                  <Textfield name="job_location" label="Job Location" />
                </Grid>
                <Grid item lg={8} xs={12} md={12}>
                  <Textfield
                    name="job_address"
                    // rows={3}
                    // multiline={true}
                    label="Job Address"
                  />
                </Grid>

                {/* <Grid item lg={12} xs={12} md={12}>
                  <Divider>
                    <Chip label="Job Description" />
                  </Divider>
                </Grid> */}

                <Grid item lg={12} xs={12} md={12}>
                  <div>
                    <RichEditor
                      value={jobDescription}
                      setValue={setJobDescription}
                    />
                  </div>

                  {/* <ReadOnly initialTextValue={initialTextValue} /> */}
                </Grid>

                <Grid item xs={12}>
                  <CardActions>
                    <StyledButton
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      {isAdd ? "Create" : "Update"}
                    </StyledButton>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        return navigate("/my-job-post");
                      }}
                    >
                      Back
                    </Button>
                  </CardActions>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

export default JobPosting;
