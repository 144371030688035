import axiosInstance from "../middleware/axiosConfig";

const getUserList = (requestData) => {
  return axiosInstance.post("get-user-list", requestData);
};

const getUserAction = (requestData) => {
  return axiosInstance.post("user-actions", requestData);
};

const getContactUsList = (requestData) => {
  return axiosInstance.post("get-contact-us", requestData);
};

export default {
  getUserList,
  getUserAction,
  getContactUsList,
};
