import {
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  CATEGORY_INITIAL_STATE,
  VADHUVAR_LIST_REQUEST,
  VADHUVAR_LIST_SUCCESS,
  VADHUVAR_LIST_FAIL,
  VADHUVAR_LIST_REFRESH,
  VADHUVAR_WISH_LIST_UPDATE,
  VADHUVAR_LIST_APPROVAL_REQUEST,
  VADHUVAR_LIST_APPROVAL_SUCCESS,
  VADHUVAR_LIST_APPROVAL_FAIL,
  VADHUVAR_LIST_APPROVAL_REFRESH,
  VADHUVAR_WISH_APPROVAL_LIST_UPDATE,
  VADHUVAR_LIST__APPROVAL_CLEAR,
} from "./types";

import vadhuvarServices from "../services/vadhuVar-services";
import { showSuccessSnackbar } from "./snackbarActions";

export const vadhuVarRegistration = (requestData, t) => (dispatch) => {
  dispatch({
    type: "VADHUVAR_REGISTRATION_SUBMIT_REQUEST",
  });
  return vadhuvarServices.registration(requestData).then(
    (response) => {
      dispatch({
        type: "VADHUVAR_REGISTRATION_SUBMIT_SUCCESS",
      });
      if (parseInt(requestData.bride_groom_id) != 0) {
        let msg = response.data.message.toString();
        dispatch(
          showSuccessSnackbar({
            hideDuration: 10000,
            status: response.data.status,
            message: t(msg) + " " + response.data.data.reg_number.toString(),
          })
        );
      }
      dispatch({
        type: VADHUVAR_LIST_REFRESH,
      });
      dispatch({
        type: VADHUVAR_LIST_APPROVAL_REFRESH,
      });
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "VADHUVAR_REGISTRATION_SUBMIT_FAILED",
      });
      return Promise.reject(error.response.data);
    }
  );
};

export const getVadhuVarApprovedList = (requestData) => (dispatch) => {
  dispatch({
    type: VADHUVAR_LIST_REQUEST,
  });
  return vadhuvarServices.getVadhuVarApprovedList(requestData).then(
    (response) => {
      dispatch({
        type: VADHUVAR_LIST_SUCCESS,
        payload: { vadhuVar: response.data.data.vadhuVarList },
      });
      return Promise.resolve(response.data.data);
    },
    (errorResp) => {
      dispatch({
        type: VADHUVAR_LIST_FAIL,
      });
      return Promise.reject(errorResp);
    }
  );
};

export const approveReject = (requestData) => (dispatch) => {
  dispatch({
    type: "VADHUVAR_APPROVAL_REQUEST",
  });
  return vadhuvarServices.approveReject(requestData).then(
    (response) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
      });
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      dispatch({
        type: VADHUVAR_LIST_REFRESH,
      });
      dispatch({
        type: VADHUVAR_LIST_APPROVAL_REFRESH,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
      });
      return Promise.reject(error);
    }
  );
};

export const enableDisable = (requestData) => (dispatch) => {
  dispatch({
    type: "VADHUVAR_APPROVAL_REQUEST",
  });
  return vadhuvarServices.enableDisble(requestData).then(
    (response) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
      });
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      dispatch({
        type: VADHUVAR_LIST_REFRESH,
      });
      dispatch({
        type: VADHUVAR_LIST_APPROVAL_REFRESH,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
      });
      return Promise.reject(error);
    }
  );
};

export const wishListAddRemove = (requestData, t) => (dispatch) => {
  dispatch({
    type: "VADHUVAR_APPROVAL_REQUEST",
  });
  return vadhuvarServices.wishListAddRemove(requestData).then(
    (response) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
      });
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: requestData.registration_number + t(response.data.message),
        })
      );
      if (requestData.action_type === "bd_wish_list") {
        return Promise.resolve(response.data);
      } else {
        dispatch({
          type: VADHUVAR_LIST_REFRESH,
        });
        dispatch({
          type: VADHUVAR_LIST_APPROVAL_REFRESH,
        });
        return Promise.resolve(response.data);
      }
    },
    (error) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
      });
      return Promise.reject(error);
    }
  );
};

export const wishListUpdate = (requestData) => (dispatch) => {
  dispatch({
    type: VADHUVAR_WISH_LIST_UPDATE,
    payload: { updateObject: requestData },
  });
};

export const getListForApproval = (requestData) => (dispatch) => {
  dispatch({
    type: VADHUVAR_LIST_APPROVAL_REQUEST,
  });
  return vadhuvarServices.getListForApproval(requestData).then(
    (response) => {
      dispatch({
        type: VADHUVAR_LIST_APPROVAL_SUCCESS,
        payload: { vadhuVar: response.data.data },
      });
      return Promise.resolve(response.data.data);
    },
    (errorResp) => {
      dispatch({
        type: VADHUVAR_LIST_APPROVAL_FAIL,
      });
      return Promise.reject(errorResp);
    }
  );
};

export const approveRejectProfileImage = (requestData) => (dispatch) => {
  dispatch({
    type: "VADHUVAR_APPROVAL_REQUEST",
  });
  return vadhuvarServices.approveRejectProfileImage(requestData).then(
    (response) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
      });
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      dispatch({
        type: VADHUVAR_LIST_REFRESH,
      });
      dispatch({
        type: VADHUVAR_LIST_APPROVAL_REFRESH,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
      });
      return Promise.reject(error);
    }
  );
};

export const updateFilterDataPara = (requestData) => (dispatch) => {
  dispatch({
    type: "UPDATE_FILTER",
    payload: { filterPara: requestData },
  });
};

export const getOrderIdForPayment = (requestData) => (dispatch) => {
  dispatch({
    type: "VADHUVAR_APPROVAL_REQUEST",
  });
  return vadhuvarServices.getOrderIdForPayment(requestData).then(
    (response) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
      });
      return Promise.reject(error);
    }
  );
};
export const saveRazorPayPaymentDetails = (requestData) => (dispatch) => {
  dispatch({
    type: "VADHUVAR_APPROVAL_REQUEST",
  });
  return vadhuvarServices.savePaymentDetails(requestData).then(
    (response) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      dispatch({
        type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
      });
      return Promise.reject(error);
    }
  );
};

export const updateVadhuVarData = (requestData, t) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (requestData.refresh_or_clear == "refresh") {
      dispatch({
        type: VADHUVAR_LIST_REFRESH,
      });
      dispatch({
        type: VADHUVAR_LIST_APPROVAL_REFRESH,
      });
      resolve(true);
    } else {
      reject(false);
    }
  });
};

export const getVadhuVarDetailById = (requestData, t) => (dispatch) => {
  dispatch({
    type: "SUBMIT_REQUEST",
  });

  return vadhuvarServices.getVadhuVarDetailById(requestData).then(
    (response) => {
      dispatch({
        type: "SUBMIT_REQUEST_SUCCESS",
      });
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "SUBMIT_REQUEST_FAILED",
      });
      return Promise.reject(error.response.data);
    }
  );
};

export const getGenderAccess = (requestData, t) => (dispatch) => {
  return vadhuvarServices.getGenderAccess(requestData).then(
    (response) => {
      dispatch({
        type: "GENDER_ACCESS",
        payload: { data: response.data.data },
      });

      return Promise.resolve(response.data.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};
export const isRegistrationDone = (requestData, t) => (dispatch) => {
  return vadhuvarServices.isRegistrationDone(requestData).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};
export const makePayment = (requestData, t) => (dispatch) => {
  return vadhuvarServices.makePayment(requestData).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};
export const getPaymentStatus = (requestData, t) => (dispatch) => {
  return vadhuvarServices.getPaymentStatus(requestData).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};
