const initialState = {
  selected_region_id: 0,
};

const commiteeRegionDataReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "COMMITEE_REGION_ID":
      return {
        ...state,
        selected_region_id: payload.selected_region_id,
      };
    case "CLEAR_COMMITEE_REGION_ID":
      return {
        ...state,
        selected_region_id: 0,
      };
    default:
      return state;
  }
};
export default commiteeRegionDataReducers;
