import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config/configData.json";
//const API_URL = configData.SERVER_URL;

const API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_SERVER_URL
    : process.env.REACT_APP_PROD_SERVER_URL;

const getStoreList = () => {
  return axios.get(API_URL + "V1/get_all_stores", {
    headers: authHeader(),
  });
};

const upload = (file, data, onUploadProgress) => {
  var authorizationToken = "";
  const jwtToken = JSON.parse(localStorage.getItem("user"));
  if (jwtToken && jwtToken.access_token) {
    authorizationToken = "Bearer " + jwtToken.access_token;
  }
  let formData = new FormData();

  console.log("jwtToken", jwtToken);

  formData.append("file", file);
  formData.append("dataPara", JSON.stringify(data));

  return axios.post(API_URL + "upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: authorizationToken,
    },
    onUploadProgress,
  });
};

const uploadCommitteeMemberProfilePhoto = (file, data, onUploadProgress) => {
  var authorizationToken = "";
  const jwtToken = JSON.parse(localStorage.getItem("user"));
  if (jwtToken && jwtToken.access_token) {
    authorizationToken = "Bearer " + jwtToken.access_token;
  }

  let formData = new FormData();
  formData.append("file", file);
  formData.append("dataPara", JSON.stringify(data));

  return axios.post(API_URL + "committee/profile-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: authorizationToken,
    },
    onUploadProgress,
  });
};

const businessBannerImageUpload = (file, data, onUploadProgress) => {
  var authorizationToken = "";
  const jwtToken = JSON.parse(localStorage.getItem("user"));
  if (jwtToken && jwtToken.access_token) {
    authorizationToken = "Bearer " + jwtToken.access_token;
  }
  let formData = new FormData();
  formData.append("file", file);
  formData.append("dataPara", JSON.stringify(data));

  return axios.post(API_URL + "business/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: authorizationToken,
    },
    onUploadProgress,
  });
};

const uploadResume = (file, data, onUploadProgress) => {
  var authorizationToken = "";
  const jwtToken = JSON.parse(localStorage.getItem("user"));
  if (jwtToken && jwtToken.access_token) {
    authorizationToken = "Bearer " + jwtToken.access_token;
  }
  let formData = new FormData();
  formData.append("file", file);
  formData.append("dataPara", JSON.stringify(data));

  return axios.post(API_URL + "job/upload-resume", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: authorizationToken,
    },
    onUploadProgress,
  });
};

export default {
  upload,
  uploadCommitteeMemberProfilePhoto,
  businessBannerImageUpload,
  uploadResume,
};
