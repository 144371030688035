import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import Textfield from "../FormsUI/Textfield";
import Select from "../FormsUI/Select";
import master_data from "../../masterData/master_data.json";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { default as StyledButton } from "../FormsUI/Button";
import { getMasterData } from "../../actions/master-data-action";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box } from "@mui/material";
import { SRLWrapper } from "simple-react-lightbox";
import {
  getMasterCategories,
  registration,
  updateBannerImage,
} from "../../actions/business-action";

import { showSuccessSnackbar } from "../../actions/snackbarActions";

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import GenFileUpload from "../fileUplod/GenFileUpload";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },

    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    width: "100%",

    // width: '100%',
    //objectFit: "contain",
    //width: "auto",
    //maxHeight: "250",

    // height: 250,
    // paddingTop: "56.25%", // 16:9,
    // marginTop: "30",
  },
  contain: {
    // objectFit: "contain",
    // height: "250px",
    // width: "200px",

    height: 250,
    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
}));

const BusinessCreate = ({ img, title }) => {
  const [isAdd, setIsAdd] = useState(true);
  const [regionList, setRegionList] = useState(1);
  const [getMasters, setMasters] = useState();
  const [categories, setCategories] = useState([]);
  const [passDataToChild, setPassDataToChild] = useState({});
  const { isLoggedIn } = useSelector((state) => state.auth);

  const [uploadedResult, setUploadedResult] = useState("");

  const [isPageLoaded, setIsPageLoaded] = useState(false);
  //***file upload */

  //***end file upload */
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );

  const formikRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const businessLocationObject = useLocation().state;
  var businessObject = null;
  if (businessLocationObject) {
    businessObject = businessLocationObject.data;
  }

  const IMAGE_API_URL =
    process.env.REACT_APP_ENV == "DEV"
      ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
      : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

  const banner_image = IMAGE_API_URL + "banner?type=1&id=";

  const dispatch = useDispatch();

  const INITIAL_FORM_STATE = {
    region_id: "",
    category_id: "",
    category_name: "",
    business_name: "",
    business_owner_name: "",
    contact_no: "",

    address: "",
    city_village: "",
    pincode: "",

    business_about: "",
    business_other_info: "",
    business_web_site_link: "",
    image_path: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    region_id: Yup.string()
      .max(10)
      .required("Required"),
    category_id: Yup.string()
      .max(10)
      .required("Required"),

    business_name: Yup.string()
      .max(150)
      .required("Required"),
    business_owner_name: Yup.string()
      .max(100)
      .required("Required"),

    business_about: Yup.string()
      .max(5000)
      .required("Required"),

    contact_no: Yup.number()
      .integer()
      .typeError("Please enter a valid phone number")
      .required("Required"),

    address: Yup.string()
      .max(500)
      .required("Required"),
    city_village: Yup.string()
      .max(75)
      .required("Required"),
    pincode: Yup.string()
      .max(250)
      .required("Required"),
  });

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    }

    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }

    let getCategory = {
      mobile_no: "9730996626",
    };
    dispatch(getMasterCategories(getCategory)).then((catRes) => {
      let resultCategory = {};
      const cnames1 = catRes.map((cname) => {
        var key = cname["id"];
        let data = {
          [key]: cname["cat_name"],
        };
        Object.assign(resultCategory, data);
      });
      setCategories(resultCategory);
    });

    if (businessObject != null) {
      setUploadedResult({ image_path: businessObject.banner_image });
      setIsAdd(false);
      const result = {
        region_id: businessObject.region_id,
        category_id: businessObject.category_id,
        category_name: businessObject.category_name,
        business_name: businessObject.business_name,
        business_owner_name: businessObject.business_owner_name,
        contact_no: businessObject.contact_no,
        address: businessObject.address,
        city_village: businessObject.city_village,
        pincode: businessObject.pincode,
        business_about: businessObject.about,
        business_other_info: businessObject.other_info,
        business_web_site_link: businessObject.site_url,
      };
      const fields = [
        "region_id",
        "category_id",
        "category_name",
        "business_name",
        "business_owner_name",
        "contact_no",
        "address",
        "city_village",
        "pincode",
        "business_about",
        "business_other_info",
        "business_web_site_link",
      ];
      fields.forEach((field) =>
        formikRef.current.setFieldValue(field, result[field], false)
      );
    }
    setIsPageLoaded(true);
  }, []);

  const createNewObject = (item) => {
    let data = {};
    return [data].join(",");
  };
  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];

    setRegionList(region_list);
  };

  const submitHandle = (formData) => {
    if (uploadedResult == "") {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: "Please upload banner image",
        })
      );
    } else {
      if (isAdd == true) {
        formData.operation_type = "add";
        formData.business_id = 0;
        formData.business_status = "Pending";
        formData.image_path = uploadedResult.image_path;
      } else {
        formData.operation_type = "update";
        formData.business_id = businessObject.business_id;
        formData.business_status = businessObject.business_status;
        formData.image_path = businessObject.banner_image;
      }
      dispatch(registration(formData, t)).then((res) => {
        return navigate("/business");
      });
    }
  };

  const getResponseFromUpload = (data) => {
    setUploadedResult(data);

    if (isAdd == false) {
      let reqData = {
        business_id: businessObject.business_id,
        action_type: "image_update",
        business_status: businessObject.business_status,
        old_image_path: businessObject.banner_image,
        image_path: data.image_path,
      };
      dispatch(updateBannerImage(reqData, t)).then((res) => {
        return navigate("/business");
      });
    }
  };

  const parentToChild = () => {
    setPassDataToChild(businessObject);
  };

  return (
    <>
      <Card className={classes.card}>
        {isPageLoaded ? (
          <>
            <GenFileUpload
              parentToChild="Welcome"
              childToParent={getResponseFromUpload}
            />
          </>
        ) : (
          ""
        )}

        {uploadedResult ? (
          <>
            {/* <SRLWrapper>
              <CardMedia
                // sx={{ objectFit: "contain" }}
                className={classes.media}
                image={banner_image + uploadedResult.image_path}
              />
            </SRLWrapper> */}

            <SRLWrapper>
              <Box
                component="img"
                sx={{
                  height: 300,
                  display: "block",
                  // maxWidth: 400,
                  overflow: "hidden",
                  width: "100%",
                }}
                src={banner_image + uploadedResult.image_path}
              />
            </SRLWrapper>
          </>
        ) : (
          ""
        )}
        {/* <img
          className="contain"
          src={banner_image + uploadedResult.image_path}
          alt="MDN Logo"
        /> */}
        <CardContent>
          <Formik
            innerRef={formikRef}
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => {
              submitHandle(values);
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12} md={12}>
                  <Divider>
                    <Chip label="Business Details" />
                  </Divider>
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Select
                    name="region_id"
                    label="Select Region"
                    options={regionList}
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Select
                    name="category_id"
                    label="Select category"
                    options={categories}
                  />
                </Grid>
                <Grid item lg={2} xs={12} md={12}>
                  <Textfield name="category_name" label="category name" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield name="business_name" label="Business name" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield name="business_owner_name" label="Owner name" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield name="contact_no" label="Contact no" />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield
                    name="address"
                    rows={3}
                    multiline={true}
                    label="Address"
                  />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="city_village" label="City or village name" />
                </Grid>
                <Grid item lg={3} xs={12} md={12}>
                  <Textfield name="pincode" label="Pincode" />
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                  <Textfield
                    name="business_about"
                    label="About your business"
                    multiline={true}
                    rows={6}
                  />
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                  <Textfield
                    name="business_other_info"
                    label="Other Information"
                    multiline={true}
                    rows={4}
                  />
                </Grid>
                <Grid item lg={6} xs={12} md={12}>
                  <Textfield
                    name="business_web_site_link"
                    label="Web site link (https://...)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CardActions>
                    <StyledButton
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      {isAdd ? "Create" : "Update"}
                    </StyledButton>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        return navigate("/business");
                      }}
                    >
                      Back
                    </Button>
                  </CardActions>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

export default BusinessCreate;
