const initialState = {
  commonLoading: false,
};

const commonLoadingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SUBMIT_REQUEST":
      return {
        ...state,
        commonLoading: true,
      };
    case "SUBMIT_REQUEST_SUCCESS":
      return {
        ...state,
        commonLoading: false,
      };
    case "SUBMIT_REQUEST_FAILED":
      return {
        ...state,
        commonLoading: false,
      };

    default:
      return state;
  }
};
export default commonLoadingReducer;
