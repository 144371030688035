const initialState = {
  gender: 0,
  region: 0,
  education: 0,
  wishList: 0,
};

const vadhuVarFilterDataReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "UPDATE_FILTER":
      return {
        ...state,
        gender: payload.filterPara.gender,
        region: payload.filterPara.region,
        education: payload.filterPara.education,
      };
    case "CLEAR_FILTER_PARA":
      return {
        ...state,
        gender: 0,
        region: 0,
        education: 0,
      };
    default:
      return state;
  }
};
export default vadhuVarFilterDataReducers;
