import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import Post from "./Post";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const Feed = () => {
  const [value, setValue] = React.useState(3);
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          "& > legend": { mt: 2 },
        }}
      >
        <div>
          <Typography component="legend">Ratings and reviews</Typography>
          <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
        </div>
        <div>
          <Button variant="outlined">Write review</Button>
        </div>

        {/* <Typography component="legend">Read only</Typography>
        <Rating name="read-only" value={value} readOnly />
        <Typography component="legend">Disabled</Typography>
        <Rating name="disabled" value={value} disabled />
        <Typography component="legend">No rating given</Typography>
        <Rating name="no-value" value={null} /> */}
      </Box>

      <Post
        title="Choose the perfect design"
        img="https://images.pexels.com/photos/7319337/pexels-photo-7319337.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
      />
      <Post
        title="Simply Recipes Less Stress. More Joy"
        img="https://images.pexels.com/photos/7363671/pexels-photo-7363671.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
      />
      <Post
        title="What To Do In London"
        img="https://images.pexels.com/photos/7245535/pexels-photo-7245535.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
      />
      <Post
        title="Recipes That Will Make You Crave More"
        img="https://images.pexels.com/photos/7245477/pexels-photo-7245477.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
      />
      <Post
        title="Shortcut Travel Guide to Manhattan"
        img="https://images.pexels.com/photos/7078467/pexels-photo-7078467.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
      />
      <Post
        title="Killer Actions to Boost Your Self-Confidence"
        img="https://images.pexels.com/photos/7833646/pexels-photo-7833646.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
      />
    </>
  );
};

export default Feed;
