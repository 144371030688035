// import React, { useState } from "react";
// import { Container, makeStyles } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     paddingTop: theme.spacing(10),
//   },
// }));

// const About = () => {
//   const classes = useStyles();
//   return (
//     <Container className={classes.container}>
//       <h1> Welcome to about page</h1>
//     </Container>
//   );
// };

// export default About;

import React, { useState } from "react";
import { Container, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import fileUploderService from "../../services/file-uploder-services";

import {
  Box,
  Typography,
  Button,
  ListItem,
  withStyles,
} from "@material-ui/core";

import FileUpload from "react-mui-fileuploader";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);
export default function GenericFileUpload() {
  const classes = useStyles();
  const [fileObject, setFileObject] = useState({
    selectedFiles: undefined,
    currentFile: undefined,
    progress: 0,
    message: "",
    isError: false,
    fileInfos: [],
  });

  const handleSelectFile = (event) => {
    setFileObject({
      selectedFiles: event.target.files,
    });
  };

  const handleUpload = () => {
    let currentFile = fileObject.selectedFiles[0];

    setFileObject({
      progress: 0,
      currentFile: currentFile,
    });

    fileUploderService
      .upload(currentFile, (event) => {
        setFileObject({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
      .then((response) => {
        setFileObject({
          message: response.data.message,
          isError: false,
        });
        // return UploadService.getFiles();
      })
      .then((files) => {
        setFileObject({
          fileInfos: files.data,
        });
      })
      .catch(() => {
        setFileObject({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });

    // setFileObject({
    //   selectedFiles: undefined,
    // });
  };

  const handleTest = (event) => {};

  const handleFileUploadError = (error) => {
    // Do something...
  };

  const handleFilesChange = (files) => {
    // Do something...
    setTimeout(function() {}, 5000);
  };
  return (
    <>
      <h3>Welcome to page </h3>

      <div className="mg20">
        {fileObject.currentFile && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress
                variant="determinate"
                value={fileObject.progress}
              />
            </Box>
            <Box minWidth={35}>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`${fileObject.progress}%`}</Typography>
            </Box>
          </Box>
        )}

        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            onChange={handleSelectFile}
          />
          <Button className="btn-choose" variant="outlined" component="span">
            Choose Files
          </Button>
        </label>
        <div className="file-name">
          {fileObject.selectedFiles && fileObject.selectedFiles.length > 0
            ? fileObject.selectedFiles[0].name
            : null}
        </div>
        <Button
          className="btn-upload"
          color="primary"
          variant="contained"
          component="span"
          disabled={!fileObject.selectedFiles}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </div>

      {/* <FileUpload
        multiFile={true}
        disabled={false}
        title="My awesome file uploader"
        header="[Drag to drop]"
        leftLabel="or"
        rightLabel="to select files"
        buttonLabel="click here"
        buttonRemoveLabel="Remove all"
        maxFileSize={10}
        maxUploadFiles={0}
        maxFilesContainerHeight={357}
        errorSizeMessage={"fill it or move it to use the default error message"}
        allowedExtensions={["jpg", "jpeg"]}
        onSelect={handleTest}
        onFilesChange={handleFilesChange}
        onError={handleFileUploadError}
        bannerProps={{ elevation: 0, variant: "outlined" }}
        containerProps={{ elevation: 0, variant: "outlined" }}
      /> */}

      {/* </Container> */}
    </>
  );
}
