import {
  VADHUVAR_LIST_APPROVAL_REQUEST,
  VADHUVAR_LIST_APPROVAL_SUCCESS,
  VADHUVAR_LIST_APPROVAL_FAIL,
  VADHUVAR_LIST_APPROVAL_REFRESH,
  VADHUVAR_WISH_APPROVAL_LIST_UPDATE,
  VADHUVAR_LIST_APPROVAL_CLEAR,
} from "../actions/types";
const initialState = {
  loaded: false,
  loading: false,
  error: false,
  vadhuVar: [],
  responseMessage: {},
  updateObject: {},
};

const vadhuVarApprovalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case VADHUVAR_LIST_APPROVAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VADHUVAR_LIST_APPROVAL_SUCCESS:
      return {
        ...state,
        vadhuVar: payload.vadhuVar,
        error: false,
        loading: false,
        loaded: true,
      };

    case VADHUVAR_LIST_APPROVAL_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        loaded: false,
      };

    case VADHUVAR_LIST_APPROVAL_REFRESH:
      return {
        ...state,
        vadhuVar: [],
        error: false,
        loading: false,
        loaded: false,
      };
    case VADHUVAR_WISH_APPROVAL_LIST_UPDATE:
      return {
        ...state,
        vadhuVar: state.vadhuVar.map((item) => {
          if (parseInt(item.id) === parseInt(action.payload.updateObject.id)) {
            const updatedItem = {
              ...item,
              is_wish_list: item.is_wish_list == 0 ? 1 : 0,
            };
            return updatedItem;
          }

          return item;
        }),
      };

    case VADHUVAR_LIST_APPROVAL_CLEAR:
      return {
        ...state,
        vadhuVar: [],
        error: false,
        loading: false,
        loaded: false,
      };

    default:
      return state;
  }
};
export default vadhuVarApprovalReducer;
