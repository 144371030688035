import {
  COMMITTEE_MEMBER_LIST_REQUEST,
  COMMITTEE_MEMBER_LIST_SUCCESS,
  COMMITTEE_MEMBER_LIST_FAIL,
  COMMITTEE_MEMBER_LIST_REFRESH,
  COMMITTEE_MEMBER_LIST_UPDATE,
} from "./types";

import committeeMemberService from "../services/CommitteeMemberService";
import { showSuccessSnackbar } from "./snackbarActions";

export const getApproveList = (requestData) => (dispatch) => {
  dispatch({
    type: COMMITTEE_MEMBER_LIST_REQUEST,
  });
  return committeeMemberService.getApproveList(requestData).then(
    (response) => {
      dispatch({
        type: COMMITTEE_MEMBER_LIST_SUCCESS,
        payload: { commiteeMembers: response.data.data },
      });
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: COMMITTEE_MEMBER_LIST_FAIL,
      });
      return Promise.reject(error);
    }
  );
};
export const create = (requestData) => (dispatch) => {
  return committeeMemberService.create(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: response.data.status,
          message: response.data.data[0].message,
        })
      );
      dispatch({
        type: COMMITTEE_MEMBER_LIST_REFRESH,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};

export const updateProfilePhoto = (requestData) => (dispatch) => {
  return committeeMemberService.updateProfilePhoto(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: response.data.status,
          message: response.data.data[0].message,
        })
      );
      dispatch({
        type: COMMITTEE_MEMBER_LIST_REFRESH,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};

export const enableDisable = (requestData) => (dispatch) => {
  return committeeMemberService.enableDisable(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: response.data.status,
          message: response.data.data[0].message,
        })
      );
      dispatch({
        type: COMMITTEE_MEMBER_LIST_REFRESH,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};
