import authService from "../services/auth.service";
export const getMasterData = (requestData) => (dispatch) => {
  dispatch({
    type: "MASTER_LIST_REQUEST",
  });
  return authService.getMasterData(requestData).then(
    (response) => {
      dispatch({
        type: "MASTER_LIST_SUCCESS",
        payload: { masterData: response.data.data },
      });

      return Promise.resolve(response.data.data);
    },
    (errorResp) => {
      return Promise.reject(errorResp);
    }
  );
};
