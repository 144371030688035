import React, { useState } from "react";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const SamplePage = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <h1> Welcome to Sample page</h1>
    </Container>
  );
};

export default SamplePage;
