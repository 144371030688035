import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles, Grid, CardActions } from "@material-ui/core";
import Textfield from "../FormsUI/Textfield";
import Select from "../FormsUI/Select";
import master_data from "../../masterData/master_data.json";
import { getMasterData } from "../../actions/master-data-action";
import { default as StyledButton } from "../FormsUI/Button";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { create } from "../../actions/committee-member-action";
import { SRLWrapper } from "simple-react-lightbox";
import CardMedia from "@mui/material/CardMedia";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import CropEasy from "../crop/CropEasy";
import fileUploderService from "../../services/file-uploder-services";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Cancel } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  marginTopGrid: {
    marginTop: "10px",
  },
}));

const Create = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formikRef = useRef();
  const vadhuVarLocationObject = useLocation().state;
  var memberObject = null;
  if (vadhuVarLocationObject) {
    memberObject = vadhuVarLocationObject.data.data;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [progressFlag, setProgressFlag] = useState(false);
  const [refreshKey, setRefreshKey] = useState(1);
  const [fileUploaded, setFileUploaded] = useState({});
  const [isAdd, setIsAdd] = useState(true);
  const [regionList, setRegionList] = useState(1);
  const [getMasters, setMasters] = useState();
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );

  const mr_ms_list = master_data.filter((e) => e.mr_ms)[0]["mr_ms"];
  const designation_list = master_data.filter((e) => e.designation)[0][
    "designation"
  ];

  const INITIAL_FORM_STATE = {
    region_id: "",
    region_name: "",
    mr_ms_id: "",
    mr_ms_name: "",
    fname: "",
    mname: "",
    lname: "",
    designation_id: "",
    designation_name: "",
    mobile_no: "",

    occupation: "",
    company_with_working_address: "",
    current_address: "",
    permanent_address: "",
    image_path: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    region_id: Yup.string()
      .max(10)
      .required("Required"),
    mr_ms_id: Yup.string()
      .max(10)
      .required("Required"),

    fname: Yup.string()
      .max(30)
      .required("Required"),
    mname: Yup.string()
      .max(30)
      .required("Required"),
    lname: Yup.string()
      .max(30)
      .required("Required"),
    designation_id: Yup.string()
      .max(30)
      .required("Required"),
    mobile_no: Yup.number()
      .integer()
      .typeError("Please enter a valid phone number")
      .required("Required"),
    occupation: Yup.string()
      .max(75)
      .required("Required"),
    company_with_working_address: Yup.string()
      .max(250)
      .required("Required"),
    current_address: Yup.string()
      .max(500)
      .required("Required"),
    permanent_address: Yup.string()
      .required("Required")
      .max(500),
  });

  const handlerGotoBack = () => {
    if (isAdd) {
      return navigate(`/committee`);
    } else {
      return navigate(vadhuVarLocationObject.data.parentRoute);
    }
  };
  const handlerSubmit = (formValue) => {
    if (isAdd) {
      formValue.image_path = fileUploaded["image_path"];
      formValue.committee_member_id = 0;
    } else {
      formValue.committee_member_id = memberObject.id;
    }
    dispatch(create(formValue)).then((res) => {
      if (isAdd) {
        return navigate(`/committee`);
      } else {
        return navigate(vadhuVarLocationObject.data.parentRoute);
      }
    });
  };

  useEffect(() => {
    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }

    if (memberObject != null) {
      setIsAdd(false);
      const result = {
        region_id: memberObject.region_id,
        fname: memberObject.first_name,
        mname: memberObject.middle_name,
        lname: memberObject.last_name,
        mr_ms_id: memberObject.mr_ms_id,
        mobile_no: memberObject.mobile_number,
        designation_id: memberObject.designation_id,
        occupation: memberObject.occupation,
        company_with_working_address: memberObject.working_address,
        current_address: memberObject.current_address,
        permanent_address: memberObject.permanant_address,
      };
      const fields = [
        "region_id",
        "fname",
        "mname",
        "lname",
        "mr_ms_id",
        "designation_id",
        "mobile_no",
        "occupation",
        "company_with_working_address",
        "current_address",
        "permanent_address",
      ];
      fields.forEach((field) =>
        formikRef.current.setFieldValue(field, result[field], false)
      );
    }
  }, [refreshKey]);

  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];
    setRegionList(region_list);
  };

  const handleChooseFileChange = (e) => {
    const file = e.target.files[0];
    var pattern = /image-*/;
    if (!file.type.match(pattern)) {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: "Please select image only.",
        })
      );
      return;
    } else {
      setProgress(0);
      if (file) {
        setFile(file);
        setPhotoURL(URL.createObjectURL(file));
        setOpenCrop(true);
      }
    }
  };

  const handleFileUpload = (e) => {
    let currentSelectedFile = file;
    setProgressFlag(true);
    setCurrentFile(currentSelectedFile);
    let data = {
      committee_member_id: 0,
      actionType: "add_comittee_member",
    };
    fileUploderService
      .uploadCommitteeMemberProfilePhoto(currentSelectedFile, data, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then((response) => {
        setFileUploaded(response.data.data[0]);
        setProgressFlag(false);
        dispatch(
          showSuccessSnackbar({
            status: response.data.status,
            message: response.data.data[0].message,
          })
        );
      })
      .catch((err) => {
        if (err.response.status == 401) {
          progressFlag(false);
          showSuccessSnackbar({
            status: "error",
            message: "You are not authorized user.",
          });
        } else {
          dispatch(
            showSuccessSnackbar({
              status: "error",
              message: "Error occured while crop, please try again later",
            })
          );
        }
      });
  };
  const handleFileUploadCancle = () => {
    setFile(null);
    setPhotoURL("");
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={handlerGotoBack}
            >
              {t("common_lbl.btn_back")}
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button>Member Details</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right">
            {/* <Button>Right</Button> */}
          </Box>
        </Grid>
      </Grid>

      <div className={classes.marginTopGrid}>
        {isAdd ? (
          <>
            {" "}
            {openCrop ? (
              <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />
            ) : (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Divider>
                    <Chip label="UPLOAD PHOTO" />
                  </Divider>

                  {progressFlag ? (
                    <>
                      {" "}
                      <Grid item lg={12}>
                        {currentFile && (
                          <>
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                              />
                            </Box>
                          </>
                        )}
                      </Grid>{" "}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item lg={12}>
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={handleChooseFileChange}
                      />
                      <Button
                        startIcon={<PhotoCameraIcon />}
                        className="btn-choose"
                        variant="outlined"
                        component="span"
                        size="small"
                        style={{ marginTop: 5 }}
                      >
                        Choose photo
                      </Button>
                    </label>
                  </Grid>

                  {photoURL ? (
                    <>
                      <Grid item lg={12}>
                        <SRLWrapper>
                          <CardMedia
                            component="img"
                            sx={{ borderRadius: "10%" }}
                            image={photoURL}
                            height="225"
                          />
                        </SRLWrapper>
                      </Grid>

                      <Grid item lg={12}>
                        <CardActions>
                          <Button
                            startIcon={<CloudUploadIcon />}
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            size="small"
                            onClick={handleFileUpload}
                          >
                            Upload
                          </Button>

                          <Button
                            startIcon={<Cancel />}
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            color="secondary"
                            size="small"
                            onClick={handleFileUploadCancle}
                          >
                            cancel
                          </Button>
                        </CardActions>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item lg={12}></Grid>
                </Grid>
              </>
            )}
          </>
        ) : (
          ""
        )}

        <Formik
          innerRef={formikRef}
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            if (values.region_id != "") {
              values.region_name = regionList[values.region_id];
            }
            if (values.mr_ms_id != "") {
              values.mr_ms_name = mr_ms_list[values.mr_ms_id];
            }
            if (values.designation_id != "") {
              values.designation_name = designation_list[values.designation_id];
            }
            handlerSubmit(values);
          }}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12} md={12}>
                <Divider>
                  <Chip label="PERSONAL DETAILS" />
                </Divider>
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select name="mr_ms_id" label="Select" options={mr_ms_list} />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield name="fname" label="First name" />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield name="mname" label="Middle name" />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield name="lname" label="Last name" />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="region_id"
                  label="Select Region"
                  options={regionList}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="designation_id"
                  label="Designation"
                  options={designation_list}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield name="mobile_no" label="Mobile no" />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield name="occupation" label="Occupation" />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="company_with_working_address"
                  label="Company name / address"
                  multiline={true}
                  rows={3}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="current_address"
                  label="Current address"
                  multiline={true}
                  rows={3}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="permanent_address"
                  label="Permanent address"
                  multiline={true}
                  rows={3}
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.item}>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    {isAdd ? "Create" : "Update"}
                  </StyledButton>
                  {/* <Button
                    onClick={handlerGotoBack}
                    variant="outlined"
                    color="secondary"
                  >
                    Back
                  </Button> */}
                </div>
              </Grid>
              <Grid item lg={12} xs={12} md={12}></Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default Create;
