import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Box,
  Button,
  CardActions,
} from "@material-ui/core";
import { Link, Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";

import VadhuVarRegistration from "../vadhuVar/VadhuVarRegistration";
import Leftbar from "../menu/Leftbar";
import Main from "../PublicNoAuth/Main";
import SwipeableTextMobileStepper from "../../components/commonComponent/SwipeableTextMobileStepper";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const Admin = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [loginUser, setLoginUser] = useState({});

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/home");
    } else {
      getLoginUserInfo();
    }
  }, []);

  const getLoginUserInfo = () => {
    const promiseResult = new Promise((resolve, reject) => {
      const getLoginUserDetails = localStorage.getItem("user");
      if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
        let userObject = {};
        userObject = JSON.parse(getLoginUserDetails);
        resolve(userObject);
      }
    });
    promiseResult.then((result) => {
      setLoginUser(result);
      if (result.roles == "super_admin" || result.roles == "admin") {
      } else {
        return navigate("/home");
      }
    });
  };

  return (
    <>
      <Grid lg={12}>
        <Typography variant="h6" style={{ color: "blue" }} align="center">
          <Button size="large" color="primary">
            Admin Dashboard
          </Button>
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item lg={12} xs={12} md={12}>
          <Box display="flex" justifyContent="center">
            <CardActions>
              {(loginUser && loginUser.roles == "super_admin") ||
              loginUser.roles == "admin" ? (
                <>
                  {" "}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      return navigate(`/users`, {});
                    }}
                  >
                    User Lists
                  </Button>{" "}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      return navigate(`/get-contact-us`, {});
                    }}
                  >
                    Contacts
                  </Button>
                  {/* <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                    }}
                  >
                    Logs
                  </Button> */}
                </>
              ) : (
                ""
              )}
            </CardActions>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Admin;
