import React, { useState } from "react";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const PageNotFound = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <h3> Page not found</h3>
    </Container>
  );
};

export default PageNotFound;
