import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_CREATE,
  CATEGORY_UPDATE,
  CATEGORY_DELETE,
  CATEGORY_ENABLE_DISABLE,
  CATEGORY_MASTER,
  CATEGORY_INITIAL_STATE,
} from "../actions/types";
const initialState = {
  loaded: false,
  loading: false,
  error: false,
  categories: [],
};

const categoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categories: payload.categories,
        error: false,
        loading: false,
        loaded: true,
      };
    case CATEGORY_MASTER:
      let data = payload.categories((e) => e.parent === null || e.parent === 0);
      let result = {};
      for (var i = 0; i < data.length; i++) {
        result[`${data[i]["cat_id"]}`] = `${data[i]["label"]}`;
      }
      return {
        ...state,
        categories: data,
        error: false,
        loading: false,
        loaded: true,
      };

    case CATEGORY_LIST_FAIL:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case CATEGORY_CREATE:
      return {
        ...state,
        categories: [],
        loaded: false,
      };
    case CATEGORY_UPDATE:
      return {
        ...state,
        categories: [],
        loaded: false,
      };
    case CATEGORY_DELETE:
      return {
        ...state,
        categories: [],
        loaded: false,
      };

    case CATEGORY_INITIAL_STATE:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: false,
        categories: [],
      };
    default:
      return state;
  }
};
export default categoryReducer;
