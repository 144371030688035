import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Textfield from "../FormsUI/Textfield";
import { useDispatch, useSelector } from "react-redux";
import { login, otpVerification } from "../../actions/auth";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Navigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import CircularProgress from "@mui/material/CircularProgress";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">ABN Scripts Technologies</Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgotPassword() {
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [optVerification, setOptVerification] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const INITIAL_FORM_STATE = {
    mobile: "",
    password: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    mobile: Yup.string().required("Mobile no or email id is required"),
    // password: Yup.string().required("Password is Required"),
    // otp: optVerification
    //   ? Yup.string().required("OTP is Required")
    //   : Yup.string(),
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                /*
                if (!loading) {
                  setSuccess(false);
                  setLoading(true);
                }

                if (optVerification == false) {
                  dispatch(login(values.mobile, values.password))
                    .then(() => {

                      setTimeout(function() {
                        setSuccess(true);
                        setLoading(false);
                        setOptVerification(true);
                      }, 1000);

                      //navigate("otp-verification");
                      // return <Navigate to="otp-verification" />;
                      // navigate("/home/feed");
                    })
                    .catch(() => {
                      console.log("login failed");
                    });
                } else {
                  const user = JSON.parse(localStorage.getItem("user"));
                  let data = {
                    country_code: "91",
                    mobile_no: values.mobile,
                    otp: values.otp,
                    otp_reference_id: user.data.otp_reference_id,
                  };
                  dispatch(otpVerification(data))
                    .then(() => {
                      console.log("otpVerification sucess login page ");
                      setTimeout(function() {
                        setSuccess(true);
                        setLoading(false);
                        navigate("/admin/feed");
                      }, 1000);
                    })
                    .catch(() => {
                      console.log("login failed");
                      setTimeout(function() {
                        setSuccess(true);
                        setLoading(false);
                      }, 1000);
                    });
                }
                */
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  {message && (
                    <Grid item xs={12}>
                      <Alert severity="error">{message}</Alert>
                    </Grid>
                  )}

                  {optVerification == false && (
                    <>
                      <Grid item xs={12}>
                        <Textfield name="mobile" label="Mobile or email id" />
                      </Grid>
                    </>
                  )}
                  {optVerification == true && (
                    <>
                      <Grid item xs={12}>
                        <Textfield name="otp" label="Enter Otp" />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading="true"
                  loadingPosition="start"
                  classes="red"
                  // disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      loadingPosition="start"
                      size={24}
                      sx={{
                        color: "white",
                      }}
                    />
                  )}
                  Submit
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      onClick={() => {
                        console.log("Click");
                        navigate("/login");
                      }}
                      variant="body2"
                    >
                      Sign In
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
