import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  CardContent,
  Avatar,
  CardActions,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import SwipeableTextMobileStepper from "../../components/commonComponent/SwipeableTextMobileStepper";
import { SRLWrapper } from "simple-react-lightbox";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Divider from "@mui/material/Divider";

import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const useStyles = makeStyles((theme) => ({
  container: {
    // paddingTop: theme.spacing(10),
  },
  paper: {
    // height: 220,
    // width: 275,
    // backgroundColor: "#ebebeb",
  },
  avatarImage: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
}));

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

// const steps = [
//   "Create complete Profile",
//   "Make payment and became member of Vadhu-Var list",
//   "Ask for a complimentary personal guidance from us",
//   "Explore right Matches",
//   "Shortlist and express interest to perfect matches",
//   "Contact on provided details",
// ];

const steps = [
  "Create Profile",
  "Make payment",
  "Guidance from us",
  // "Explore right Matches",
  // "Shortlist and express interest to perfect matches",
  // "Contact on provided details",
  "Explore and find right Matches",
  // "Find to perfect matches",
  "Contact on provided details",
];

const stepsNext = [];

const Home = () => {
  let navigate = useNavigate();
  const { aid } = useParams();
  localStorage.setItem("aid", aid);
  const { t } = useTranslation();
  const classes = useStyles();
  const options = {
    settings: {
      //overlayColor: "rgb(25, 136, 124)",
      //  overlayColor: "rgb(225, 225, 230)",
      overlayColor: "#fafafa",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "#1b5245",
      iconColor: "rgba(126, 172, 139, 0.8)",
    },
    caption: {
      captionColor: "#a6cfa5",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  };

  const serviceMenu = [
    {
      id: "01",
      lable: "Vadhu Var",
      imagePath:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbtFzYOUjBdRpw77oOHTcwf3dZJ8O5H06lMw&usqp=CAU",
      navigationPath: "/user/bride-groom-list",
    },
    {
      id: "02",
      lable: "Jobs",
      imagePath:
        "https://www.seekpng.com/png/detail/212-2129332_job-opening-it-support-officer-job.png",
      //"https://freepngimg.com/thumb/jobs/9-2-jobs-png-thumb.png",
      navigationPath: "/job-updates",
    },
    {
      id: "03",
      lable: "Business",
      imagePath:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqjCpAvZ2x7FH_ilHR_nFWc3ty2ssiazsedA&usqp=CAU",
      navigationPath: "/business",
    },
  ];
  return (
    <>
      {/* <Grid lg={12} xs={12} md={12}>
        <Typography variant="h6" style={{ color: "blue" }} align="center">
          <Button size="large" color="primary">
            {t("welcomet_to_home")}
          </Button>
        </Typography>
      </Grid> */}
      <Root>
        <Grid container spacing={2}>
          <Grid lg={12} xs={12} md={12}>
            <SwipeableTextMobileStepper></SwipeableTextMobileStepper>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Divider> </Divider>
          </Grid>

          {/* <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Button color="primary" size="medium">
                {t("menu_top.ourServices")}
              </Button>
            </Box>
          </Grid> */}
          {/* <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1" gutterBottom>
                Welcome to suryavanshi bari portal,one of the best platform for
                our community where we have provided some services
              </Typography>
            </Box>
          </Grid> */}

          <Divider />

          <Grid lg={12} xs={12} md={12}>
            <Box
              display="flex"
              sx={{ marginTop: "10px" }}
              justifyContent="center"
            >
              <Button
                // sx={{ color: "#de5499", borderColor: "#de5499" }}
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Create Pprofile
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Root>
    </>
  );
};

export default Home;
