import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CallIcon from "@mui/icons-material/Call";
import { Box } from "@mui/material";
import { SRLWrapper } from "simple-react-lightbox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SchoolIcon from "@mui/icons-material/School";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
}));

const JobTemplate = ({ img, businessObject }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <SRLWrapper>
        <CardActionArea>
          <CardContent>
            <Grid container spacing={0.5}>
              <Grid item lg={6} xs={12} md={12}>
                <Typography variant="h5" gutterBottom>
                  {businessObject.title}
                </Typography>
              </Grid>

              {/* <Grid item lg={6} xs={12} md={12}>
                <Typography
                  style={{ display: "inline-block" }}
                  variant="subtitle2"
                >
                  Catergory
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.cat_name}{" "}
                  {businessObject.category_name != "" ? (
                    <> ({businessObject.category_name}) </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid> */}

              <Grid item lg={12} xs={12} md={12}>
                <Typography
                  style={{ display: "inline-block" }}
                  variant="subtitle2"
                >
                  Company Name :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.company_name}
                </Typography>
              </Grid>

              <Grid item lg={6} xs={6} md={6}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Education :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.education}
                </Typography>
              </Grid>

              <Grid item lg={6} xs={6} md={6}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Experience :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.experience}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6} md={6}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Salary :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.salary_offered}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6} md={6}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Employment :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.employment_type_name}
                </Typography>
              </Grid>

              <Grid item lg={6} xs={6} md={6}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Location :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.job_location}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={6} md={6}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Job ID :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.job_id}
                </Typography>
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block" }}
                >
                  Skill :
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", marginLeft: 6 }}
                >
                  {businessObject.key_skill}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </SRLWrapper>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => {
            return navigate("/view-posted-job", {
              state: {
                data: businessObject,
                parentRoute: window.location.pathname,
              },
            });
          }}
        >
          Details
        </Button>
        <Button size="small" color="primary" startIcon={<AccessTimeIcon />}>
          {businessObject.day_ago == "0"
            ? "Today"
            : businessObject.day_ago + " : " + "Day ago"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default JobTemplate;
