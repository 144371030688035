import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles, Grid, CardActions } from "@material-ui/core";
import Textfield from "../FormsUI/Textfield";
import Select from "../FormsUI/Select";
import master_data from "../../masterData/master_data.json";
import { default as StyledButton } from "../FormsUI/Button";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { emphasize, styled } from "@mui/material/styles";
import {
  vadhuVarRegistration,
  isProfileCreated,
  isRegistrationDone,
} from "../../actions/vadhuVar-action";
import { SRLWrapper } from "simple-react-lightbox";
import CardMedia from "@mui/material/CardMedia";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import CropEasy from "../crop/CropEasy";
import fileUploderService from "../../services/file-uploder-services";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Cancel } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getMasterData } from "../../actions/master-data-action";
import { Backdrop } from "@mui/material";
import Spinner from "react-spinner-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  marginTopGrid: {
    marginTop: "10px",
  },
}));

const VadhuVarRegistration = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formikRef = useRef();
  const vadhuVarLocationObject = useLocation().state;
  var vadhuVarObject = null;
  if (vadhuVarLocationObject) {
    vadhuVarObject = vadhuVarLocationObject.data.data;
  }
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );
  const { registrationFormLoading } = useSelector(
    (state) => state.vadhuVarReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [progressFlag, setProgressFlag] = useState(false);
  const [refreshKey, setRefreshKey] = useState(1);
  const [fileUploaded, setFileUploaded] = useState({});
  const [isAdd, setIsAdd] = useState(true);
  const [regionList, setRegionList] = useState(1);
  const [educationList, setEducatioList] = useState(1);
  const [isPaymentEnable, setIsPaymentEnable] = useState(false);
  const [configsData, setConfigsData] = useState({});

  const [castList, setCastList] = useState(1);
  const [stateList, setStateList] = useState(
    master_data.filter((e) => e.state_list)[0]["state_list"]
  );

  const [districtList, setDistrictList] = useState(1);

  const [heightArr, setHeightArr] = useState(
    master_data.filter((e) => e.heightArr)[0]["heightArr"]
  );

  const [profileFor, setProfileFor] = useState(
    master_data.filter((e) => e.profileFor)[0]["profileFor"]
  );
  const [dates, setDateList] = useState(
    master_data.filter((e) => e.date_arr)[0]["date_arr"]
  );
  const [months, setMonthList] = useState(
    master_data.filter((e) => e.month_arr)[0]["month_arr"]
  );
  const [times, setTimesList] = useState(
    master_data.filter((e) => e.time_arr)[0]["time_arr"]
  );
  const [minutes, setMinutesList] = useState(
    master_data.filter((e) => e.minute_arr)[0]["minute_arr"]
  );
  const [ampm, setAmPm] = useState(
    master_data.filter((e) => e.ampm_arr)[0]["ampm_arr"]
  );

  const [annualIncome, setAnnualIncome] = useState(
    master_data.filter((e) => e.annualIncome)[0]["annualIncome"]
  );
  const [occupation, setOccupation] = useState(
    master_data.filter((e) => e.occupation)[0]["occupation"]
  );
  const [religionList, setReligionList] = useState(
    master_data.filter((e) => e.religions)[0]["religions"]
  );

  const [zodiacList, setZodiacList] = useState(
    master_data.filter((e) => e.zodiacList)[0]["zodiacList"]
  );

  const INITIAL_FORM_STATE = {
    fname: "",
    mname: "",
    lname: "",
    height: "",
    gender: "",
    blood_group_id: "",
    date_of_birth: "",
    birth_of_place: "",
    is_married: "",
    requirement: "",

    education_id: "",
    specialization: "",
    occupation: "",
    annual_income: "",

    region_id: "",
    uncle_name: "",
    city_village: "",
    father_full_name: "",
    father_mobile_number: "",
    father_occupation: "",

    current_address: "",
    permanent_address: "",

    contact_person_name_1: "",
    contact_person_mobile_1: "",

    contact_person_name_2: "",
    contact_person_mobile_2: "",
    image_path: "",
    registration_number: "",
    profile_for: "",
    religion_id: "",
    cast_id: "",
    state_id: "",
    district_id: "",
    time: "",
    minute: "",
    ampm: "",
    sub_cast_name: "",
    zodiac_id: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    profile_for: Yup.string()
      .max(30)
      .required(t("matrimony_registration_page.lbl_required_error")),

    region_id: Yup.string().max(10),
    //.required(t("matrimony_registration_page.lbl_required_error")),
    fname: Yup.string()
      .max(30)
      .required(t("matrimony_registration_page.lbl_required_error")),
    mname: Yup.string()
      .max(30)
      .required(t("matrimony_registration_page.lbl_required_error")),
    lname: Yup.string()
      .max(30)
      .required(t("matrimony_registration_page.lbl_required_error")),
    height: Yup.string()
      .max(10)
      .required(t("matrimony_registration_page.lbl_required_error")),
    gender: Yup.string()
      .max(10)
      .required(t("matrimony_registration_page.lbl_required_error")),
    blood_group_id: Yup.string()
      .max(10)
      .required(t("matrimony_registration_page.lbl_required_error")),
    date_of_birth: Yup.string()
      .max(20)
      .required(t("matrimony_registration_page.lbl_required_error")),
    birth_of_place: Yup.string()
      .max(40)
      .required(t("matrimony_registration_page.lbl_required_error")),
    is_married: Yup.string()
      .max(10)
      .required(t("matrimony_registration_page.lbl_required_error")),
    requirement: Yup.string().max(200),

    education_id: Yup.string()
      .max(10)
      .required(t("matrimony_registration_page.lbl_required_error")),
    specialization: Yup.string().max(50),
    occupation: Yup.string().max(50),
    annual_income: Yup.string().max(15),
    city_village: Yup.string().max(50),
    father_full_name: Yup.string()
      .max(75)
      .required(t("matrimony_registration_page.lbl_required_error")),
    father_occupation: Yup.string()
      .max(75)
      .required(t("matrimony_registration_page.lbl_required_error")),
    father_mobile_number: Yup.number()
      .integer()
      .typeError(t("matrimony_registration_page.lbl_valid_mobile_error"))
      .required(t("matrimony_registration_page.lbl_required_error")),
    uncle_name: Yup.string()
      .max(75)
      .required(t("matrimony_registration_page.lbl_required_error")),
    current_address: Yup.string()
      .max(500)
      .required(t("matrimony_registration_page.lbl_required_error")),
    permanent_address: Yup.string().max(500),
    contact_person_name_1: Yup.string()
      .max(100)
      .required(t("matrimony_registration_page.lbl_required_error")),
    contact_person_mobile_1: Yup.number()
      .integer()
      .typeError(t("matrimony_registration_page.lbl_valid_mobile_error"))
      .required(t("matrimony_registration_page.lbl_required_error")),
    contact_person_name_2: Yup.string().max(100),

    contact_person_mobile_2: Yup.number()
      .integer()
      .typeError(t("matrimony_registration_page.lbl_valid_mobile_error")),
    image_path: Yup.string().max(200),
    religion_id: Yup.string().required(
      t("matrimony_registration_page.lbl_required_error")
    ),

    cast_id: Yup.string().required(t("signup_page.err_req_cast_name")),

    state_id: Yup.string().required(t("signup_page.err_req_state_name")),

    district_id: Yup.string().required(t("signup_page.err_req_district_name")),

    zodiac_id: Yup.string().required(
      t("matrimony_registration_page.lbl_required_error")
    ),

    time: Yup.string().required(
      t("matrimony_registration_page.lbl_required_error")
    ),
    minute: Yup.string().required(
      t("matrimony_registration_page.lbl_required_error")
    ),
    ampm: Yup.string().required(
      t("matrimony_registration_page.lbl_required_error")
    ),
  });

  const handlerGotoBack = () => {
    if (isAdd) {
      return navigate(`/user/bride-groom-list`);
    } else {
      return navigate(vadhuVarLocationObject.data.parentRoute);
    }
  };
  const handlerSubmit = (formValue) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));

    if (isAdd) {
      if (fileUploaded["image_path"] != undefined) {
        formValue.image_path = fileUploaded["image_path"];
        formValue.bride_groom_id = 0;
      } else {
        dispatch(
          showSuccessSnackbar({
            status: "error",
            message: t(
              "matrimony_registration_page.lbl_required_photo_validation_error_msg"
            ),
          })
        );
        return;
      }
    } else {
      formValue.bride_groom_id = vadhuVarObject.id;
      formValue.registration_number = vadhuVarObject.registration_number;
      // formValue.occupation = occupation[formValue.occupation];
      // formValue.father_occupation = occupation[formValue.father_occupation];
      // formValue.registration_status = vadhuVarObject.registration_status;
    }
    delete formValue.time;
    delete formValue.minute;
    delete formValue.ampm;
    dispatch(vadhuVarRegistration(formValue, t)).then((res) => {
      if (isAdd) {
        if (configsData && configsData.is_payment_enabled == "1") {
          return navigate(`/payment`, {
            state: {
              data: {
                bride_groom_id: res.id,
                reg_number: res.reg_number,
                payment_type: "VADHU_VAR_REG",
                sourcePage: "VADHU_VAR_REG",
                //registrationFees: res.registrationFees,
              },
              parentRoute: window.location.pathname,
            },
          });
        } else {
          dispatch(
            showSuccessSnackbar({
              hideDuration: 10000,
              status: "success",
              message:
                t("matrimony_registration_page.lbl_success_message") +
                " " +
                res.reg_number.toString(),
            })
          );
          return navigate(`/user/my-profile`);
        }
      } else {
        return navigate(vadhuVarLocationObject.data.parentRoute);
      }
    });
  };

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      fetch(`../data/configs.json`)
        .then((res) => res.json())
        .then((data) => {
          setConfigsData({ ...data });
        })
        .catch((err) => {});
      getRegistrationStatus();

      // paymentModule();
      if (!masterLoaded) {
        let requestData = {
          id: "1",
        };
        dispatch(getMasterData(requestData)).then((res) => {
          setTimeout(function() {
            bindDDLList(res);
          }, 1000);
        });
      } else {
        bindDDLList(masterData);
      }

      if (vadhuVarObject != null) {
        setIsAdd(false);
        ddlReligionOnChanges(vadhuVarObject.religion_id);
        ddlStateOnChanges(vadhuVarObject.state_id);

        let birthTimeArr = vadhuVarObject.birth_time.split(":");

        const result = {
          fname: vadhuVarObject.first_name,
          mname: vadhuVarObject.middle_name,
          lname: vadhuVarObject.last_name,
          height: vadhuVarObject.height,
          gender: vadhuVarObject.gender,
          blood_group_id: vadhuVarObject.blood_group_id,
          date_of_birth: vadhuVarObject.date_of_birth,
          birth_of_place: vadhuVarObject.birth_place_time,
          is_married: vadhuVarObject.married_status_id,
          requirement: vadhuVarObject.requirement,

          education_id: vadhuVarObject.education_id,
          specialization: vadhuVarObject.specialization,
          occupation: vadhuVarObject.occupation,
          annual_income: vadhuVarObject.annual_income,

          region_id: "0",
          uncle_name: vadhuVarObject.uncle_surname,
          city_village: vadhuVarObject.city_village,
          father_full_name: vadhuVarObject.father_name,
          father_mobile_number: vadhuVarObject.father_mobile_number,
          father_occupation: vadhuVarObject.father_occupation,

          current_address: vadhuVarObject.father_current_address,
          permanent_address: vadhuVarObject.father_permanent_address,

          contact_person_name_1: vadhuVarObject.contact_person_name_first,
          contact_person_mobile_1:
            vadhuVarObject.contact_person_mobile_number_first,

          contact_person_name_2: vadhuVarObject.contact_person_name_second,
          contact_person_mobile_2:
            vadhuVarObject.contact_person_mobile_number_second,

          profile_for: profileFor[vadhuVarObject.profile_for],
          religion_id: vadhuVarObject.religion_id.toString(),
          cast_id: vadhuVarObject.cast_id.toString(),
          state_id: vadhuVarObject.state_id.toString(),
          district_id: vadhuVarObject.district_id.toString(),

          time: birthTimeArr[0],
          minute: birthTimeArr[1],
          ampm: birthTimeArr[2],
          sub_cast_name: vadhuVarObject.sub_cast_name,
          zodiac_id: vadhuVarObject.zodiac_id.toString(),
        };
        const fields = [
          "fname",
          "mname",
          "lname",
          "height",
          "gender",
          "blood_group_id",
          "date_of_birth",
          "birth_of_place",
          "is_married",
          "requirement",
          "education_id",
          "specialization",
          "occupation",
          "annual_income",
          "region_id",
          "uncle_name",
          "city_village",
          "father_full_name",
          "father_mobile_number",
          "father_occupation",
          "current_address",
          "permanent_address",
          "contact_person_name_1",
          "contact_person_mobile_1",
          "contact_person_name_2",
          "contact_person_mobile_2",
          "profile_for",
          "religion_id",
          "cast_id",
          "state_id",
          "district_id",
          "time",
          "minute",
          "ampm",
          "sub_cast_name",
          "zodiac_id",
        ];
        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, result[field], false)
        );
      } else {
        // dispatch(isProfileCreated(requestData)).then((res) => {
        //   return navigate(`/my-registration`);
        // });
      }
    }
  }, [refreshKey]);

  const getRegistrationStatus = () => {
    dispatch(isRegistrationDone({}, t)).then((res) => {
      let pageName = "";
      if (vadhuVarLocationObject != null) {
        if (typeof vadhuVarLocationObject.fromPage != "undefined") {
          pageName = vadhuVarLocationObject.fromPage;
        }
      }

      if (res.data["is_registration_done"] == 1) {
        if (pageName == "myRegistration" || pageName == "edit") {
        } else {
          return navigate("/user/my-profile");
        }
      }
    });
  };

  const paymentModule = () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (JSON.parse(userDetails.feature.isPaymentModuleEnable)) {
      setIsPaymentEnable(true);
    } else {
      setIsPaymentEnable(false);
    }
  };

  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];
    const education_list = inputMasterData.filter((e) => e.education)[0][
      "education"
    ];
    setRegionList(region_list);
    setEducatioList(education_list);
  };

  const ddlStateOnChanges = (event) => {
    formikRef.current.setFieldValue("district_id", "", false);
    const get_state_list = master_data.filter((e) => e.states)[0]["states"];
    let getSelectedData = get_state_list.data.filter(
      (e) => parseInt(e.id) === parseInt(event)
    );
    if (getSelectedData) {
      getSelectedData.forEach((element) => {
        setDistrictList(
          element.districts.reduce(function(result, current) {
            return Object.assign(result, current);
          }, {})
        );
      });
    }
  };

  const ddlReligionOnChanges = (event) => {
    formikRef.current.setFieldValue("cast_id", "", false);
    const reglionWiseData = master_data.filter((e) => e.reglionWiseData)[0][
      "reglionWiseData"
    ];
    reglionWiseData["list"]["caste"].list.forEach((element) => {
      if (parseInt(element["id"]) === parseInt(event)) {
        let makeCastList = {};
        element.castList.forEach((cast) => {
          Object.assign(makeCastList, {
            [cast.id]: cast.name,
          });
          setCastList(makeCastList);
        });
      }
    });
  };

  const handleChooseFileChange = (e) => {
    const file = e.target.files[0];
    var pattern = /image-*/;
    if (!file.type.match(pattern)) {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: t("matrimony_registration_page.lbl_valid_photo_error"),
        })
      );
      return;
    } else {
      setProgress(0);
      if (file) {
        setFile(file);
        setPhotoURL(URL.createObjectURL(file));
        setOpenCrop(true);
      }
    }
  };

  const handleFileUpload = (e) => {
    dispatch({
      type: "VADHUVAR_APPROVAL_REQUEST",
    });
    let currentSelectedFile = file;
    setProgressFlag(true);
    setCurrentFile(currentSelectedFile);
    let data = {
      bride_groom_id: 0,
      actionType: "add_vadhu_var",
    };
    fileUploderService
      .upload(currentSelectedFile, data, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then((response) => {
        dispatch({
          type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
        });
        setProgressFlag(false);
        setFileUploaded(response.data.data[0]);
        setProgressFlag(false);
        dispatch(
          showSuccessSnackbar({
            status: response.data.status,
            message: response.data.data[0].message,
          })
        );
      })
      .catch(() => {
        dispatch({
          type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
        });
        progressFlag(false);
        dispatch(
          showSuccessSnackbar({
            status: "error",
            message: t("common_lbl.lbl_some_error_occured"),
          })
        );
      });
  };
  const handleFileUploadCancle = () => {
    setFile(null);
    setPhotoURL("");
  };
  const [openSpinner, setOpenSpinner] = React.useState(true);
  return (
    <>
      {registrationFormLoading ? (
        <>
          {" "}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>{" "}
        </>
      ) : (
        ""
      )}

      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={handlerGotoBack}
            >
              {t("common_lbl.btn_back")}
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button>
              {" "}
              {isAdd
                ? t("matrimony_registration_page.title")
                : t("matrimony_registration_page.lbl_title_update")}
              {isAdd ? "" : " (" + vadhuVarObject.registration_number + ")"}
            </Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                return navigate(`/user/my-profile`);
              }}
            >
              {t("menu_top.my_registration")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <div className={classes.marginTopGrid}>
        {isAdd ? (
          <>
            {" "}
            {openCrop ? (
              <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />
            ) : (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Divider>
                    <Chip
                      label={t("matrimony_registration_page.lbl_upload_photo")}
                    />
                  </Divider>

                  {progressFlag ? (
                    <>
                      {" "}
                      <Grid item lg={12}>
                        {currentFile && (
                          <>
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                              />
                            </Box>
                          </>
                        )}
                      </Grid>{" "}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item lg={12}>
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: "none" }}
                        type="file"
                        onChange={handleChooseFileChange}
                      />
                      <Button
                        startIcon={<PhotoCameraIcon />}
                        className="btn-choose"
                        variant="outlined"
                        component="span"
                        size="small"
                        style={{ marginTop: 5 }}
                      >
                        Choose photo
                      </Button>
                    </label>
                  </Grid>

                  {photoURL ? (
                    <>
                      <Grid item lg={12}>
                        <SRLWrapper>
                          <CardMedia
                            component="img"
                            sx={{ borderRadius: "10%" }}
                            image={photoURL}
                            height="225"
                          />
                        </SRLWrapper>
                      </Grid>

                      <Grid item lg={12}>
                        <CardActions>
                          <Button
                            startIcon={<CloudUploadIcon />}
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            size="small"
                            onClick={handleFileUpload}
                          >
                            Upload
                          </Button>

                          <Button
                            startIcon={<Cancel />}
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            color="secondary"
                            size="small"
                            onClick={handleFileUploadCancle}
                          >
                            cancel
                          </Button>
                        </CardActions>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item lg={12}></Grid>
                </Grid>
              </>
            )}
          </>
        ) : (
          ""
        )}

        <Formik
          innerRef={formikRef}
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            values.associate_id = localStorage.getItem("aid");
            if (values.region_id != "") {
              values.region = regionList[values.region_id];
            }
            if (values.education_id != "") {
              values.education = educationList[values.education_id];
            }
            if (values.blood_group_id != "") {
              values.blood_group = master_data[1][values.blood_group_id];
            }

            values.cast_id = values.cast_id;
            values.state_id = values.state_id;
            values.district_id = values.district_id;

            let birthTime =
              times[values.time] +
              ":" +
              minutes[values.minute] +
              ":" +
              ampm[values.ampm];

            values.birth_time = birthTime;

            delete handlerSubmit(values);
          }}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12} md={12}>
                <Divider>
                  <Chip
                    label={t(
                      "matrimony_registration_page.lbl_personal_details"
                    )}
                  />
                </Divider>
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="profile_for"
                  label="Profile create for"
                  options={profileFor}
                />
              </Grid>

              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="fname"
                  label={t("matrimony_registration_page.lbl_fname")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="mname"
                  label={t("matrimony_registration_page.lbl_mname")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="lname"
                  label={t("matrimony_registration_page.lbl_lname")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="height"
                  label={t("matrimony_registration_page.lbl_height")}
                  options={heightArr}
                />
              </Grid>

              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="gender"
                  label={t("matrimony_registration_page.lbl_gender")}
                  options={master_data[0]}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="blood_group_id"
                  label={t("matrimony_registration_page.lbl_blood_grop")}
                  options={master_data[1]}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="date_of_birth"
                  label={t("matrimony_registration_page.lbl_date_of_birth")}
                />
              </Grid>

              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="religion_id"
                  label="Religion"
                  onChangeHandle={ddlReligionOnChanges}
                  options={religionList}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="cast_id"
                  label={t("signup_page.lbl_cast")}
                  options={castList}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="sub_cast_name"
                  label={t("matrimony_registration_page.lbl_sub_cast")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="zodiac_id"
                  label={t("matrimony_registration_page.lbl_zodiac")}
                  options={zodiacList}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="state_id"
                  label={t("signup_page.lbl_state")}
                  onChangeHandle={ddlStateOnChanges}
                  options={stateList}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="district_id"
                  label={t("signup_page.lbl_region_name")}
                  options={districtList}
                />
              </Grid>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker label="Basic date picker" />
                  </DemoContainer>
                </LocalizationProvider> */}

              {/*  */}

              <Grid item lg={12} xs={12} md={12}>
                <Divider>
                  <Chip
                    label={t("matrimony_registration_page.lbl_other_details")}
                  />
                </Divider>
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="is_married"
                  label={t("matrimony_registration_page.lbl_married_status")}
                  options={master_data[2]}
                />
              </Grid>
              {/* <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="birth_of_place"
                  label={t("matrimony_registration_page.lbl_birth_place_time")}
                />
              </Grid> */}
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="birth_of_place"
                  // label={t("matrimony_registration_page.lbl_birth_place_time")}
                  label="Birth place"
                />
              </Grid>

              <Grid item lg={2} xs={12} md={12}>
                <Select name="time" label="Birth time(hours)" options={times} />
              </Grid>
              <Grid item lg={2} xs={12} md={12}>
                <Select
                  name="minute"
                  label="Birth time(minute)"
                  options={minutes}
                />
              </Grid>
              <Grid item lg={2} xs={12} md={12}>
                <Select name="ampm" label="Birth time(am/pm)" options={ampm} />
              </Grid>

              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="uncle_name"
                  label={t("matrimony_registration_page.lbl_uncle_name")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="requirement"
                  label={t("matrimony_registration_page.lbl_requriement")}
                />
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Divider>
                  <Chip
                    label={t(
                      "matrimony_registration_page.lbl_educational_detail"
                    )}
                  />
                </Divider>
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="education_id"
                  label={t("matrimony_registration_page.lbl_education")}
                  options={educationList}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="specialization"
                  label={t("matrimony_registration_page.lbl_specialization")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                {/* <Textfield
                  name="occupation"
                  label={t("matrimony_registration_page.lbl_occupation")}
                /> */}
                <Select
                  name="occupation"
                  label={t("matrimony_registration_page.lbl_occupation")}
                  options={occupation}
                />
              </Grid>

              <Grid item lg={3} xs={12} md={12}>
                <Select
                  name="annual_income"
                  label={t("matrimony_registration_page.lbl_annual_income")}
                  options={annualIncome}
                />
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Divider>
                  <Chip
                    label={t("matrimony_registration_page.lbl_family_details")}
                  />
                </Divider>
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="father_full_name"
                  label={t("matrimony_registration_page.lbl_father_name")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                {/* <Textfield
                  name="father_occupation"
                  label={t("matrimony_registration_page.lbl_father_occupation")}
                /> */}
                <Select
                  name="father_occupation"
                  label={t("matrimony_registration_page.lbl_occupation")}
                  options={occupation}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="father_mobile_number"
                  label={t("matrimony_registration_page.lbl_father_mobile")}
                />
              </Grid>
              <Grid item lg={3} xs={12} md={12}>
                <Textfield
                  name="city_village"
                  label={t(
                    "matrimony_registration_page.lbl_father_village_city"
                  )}
                />
              </Grid>

              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="current_address"
                  label={t(
                    "matrimony_registration_page.lbl_father_current_address"
                  )}
                  multiline={true}
                  rows={3}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="permanent_address"
                  label={t(
                    "matrimony_registration_page.lbl_father_permanent_address"
                  )}
                  multiline={true}
                  rows={3}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="contact_person_name_1"
                  label={t(
                    "matrimony_registration_page.lbl_contat_person_name_1"
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="contact_person_mobile_1"
                  label={t(
                    "matrimony_registration_page.lbl_contat_person_mobile_1"
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="contact_person_name_2"
                  label={t(
                    "matrimony_registration_page.lbl_contat_person_name_2"
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                <Textfield
                  name="contact_person_mobile_2"
                  label={t(
                    "matrimony_registration_page.lbl_contat_person_mobile_2"
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.item}>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    {isAdd
                      ? t("common_lbl.btn_submit")
                      : t("common_lbl.btn_update")}
                  </StyledButton>
                  {/* <Button
                    onClick={handlerGotoBack}
                    variant="outlined"
                    color="secondary"
                  >
                    Back
                  </Button> */}
                </div>
              </Grid>

              <Grid item lg={12} xs={12} md={12}></Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default VadhuVarRegistration;
