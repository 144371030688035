import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import storeObjectReducer from "./storeObject-reducer";
import storeReducer from "./store-reducers";
import categoryReducer from "./category-reducers";
import productReducer from "./product-reducers";
import categoryItemReducer from "./item-categor-reducers";
import uiReducer from "./uiReducer";

import vadhuVarReducer from "./vadhuVar-reducers";
import vadhuVarApprovalReducer from "./vadhu-var-approval";
import committeeMemberReducers from "./committee-member-reducers";
import masterDataReducer from "./master-data-reducers";
import vadhuVarFilterDataReducers from "./vadhuVar-filterData-reducers";
import userListRegionDataReducers from "./user-lists-reducers";
import businessReducer from "./business-reducer";
import commiteeRegionDataReducers from "./commitee-region-reducer";
import postedJobReducer from "./job-posted-reducer";
import commonLoadingReducer from "./commonLoading-reducer";
import saveRedirectedUrlReducer from "./save-redirected-url-reducer";
export default combineReducers({
  auth,
  message,
  storeObjectReducer,
  storeReducer,
  categoryReducer,
  categoryItemReducer,
  productReducer,
  vadhuVarReducer,
  uiReducer,
  vadhuVarApprovalReducer,
  committeeMemberReducers,
  masterDataReducer,
  vadhuVarFilterDataReducers,
  userListRegionDataReducers,
  businessReducer,
  commiteeRegionDataReducers,
  postedJobReducer,
  commonLoadingReducer,
  saveRedirectedUrlReducer,
});
