import axiosInstance from "./axiosConfig";

import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/types";
import { showSuccessSnackbar } from "../actions/snackbarActions";
import { logout } from "../actions/auth";

export default {
  setupInterceptors: (store, history) => {
    axiosInstance.interceptors.request.use((request) => {
      const jwtToken = JSON.parse(localStorage.getItem("user"));
      if (jwtToken && jwtToken.access_token) {
        if (request.url != "contact-us") {
          let addedLoginUserDetails = {
            ["ui_token_userName"]:
              jwtToken["first_name"] + " " + jwtToken["last_name"],
            ["ui_token_mobile"]: jwtToken["mobile_or_email"],
            ["ui_token_roles"]: jwtToken["roles"],
            ["ui_token_region"]: jwtToken["region_id"],
          };
          Object.assign(request.data, addedLoginUserDetails);
        }
        request.headers.Authorization = "Bearer " + jwtToken.access_token;
      }
      return request;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log(" interceptors error", error);

        if (error.code == "ERR_NETWORK") {
          store.dispatch(
            showSuccessSnackbar({
              hideDuration: 10000,
              status: "error",
              message:
                "Error occured,something went wrong please try again ..!",
            })
          );
        } else {
          if (error.response.request.status === 401) {
            store.dispatch(logout());
            setTimeout(function() {
              window.location = "/login";
            }, 100);
          } else {
            store.dispatch(
              showSuccessSnackbar({
                status: error.response.data.status,
                message: error.response.data.message,
              })
            );
          }
        }
        return Promise.reject(error);
      }
    );
  },
};
