export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const RESPONSE_MESSAGE = "RESPONSE_MESSAGE";
export const RESPONSE_FAIL = "RESPONSE_FAIL";

export const STORE_LIST_REQUEST = "STORE_LIST_REQUEST";
export const STORE_LIST_SUCCESS = "STORE_LIST_SUCCESS";
export const STORE_LIST_FAIL = "STORE_LIST_FAIL";

export const STORE_CREATE = "STORE_CREATE";
export const STORE_UPDATE = "STORE_UPDATE";
export const STORE_DELETE = "STORE_DELETE";
export const STORE_ENABLE_DISABLE = "STORE_ENABLE_DISABLE";

export const CATEGORY_INITIAL_STATE = "CATEGORY_INITIAL_STATE";
export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_MASTER = "CATEGORY_MASTER";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const CATEGORY_CREATE = "CATEGORY_CREATE";
export const CATEGORY_UPDATE = "CATEGORY_UPDATE";
export const CATEGORY_DELETE = "CATEGORY_DELETE";
export const CATEGORY_ENABLE_DISABLE = "CATEGORY_ENABLE_DISABLE";

export const CATEGORY_ITEM_LIST_REQUEST = "CATEGORY_ITEM_LIST_REQUEST";
export const CATEGORY_ITEM_LIST_SUCCESS = "CATEGORY_ITEM_LIST_SUCCESS";
export const CATEGORY_ITEM_MASTER = "CATEGORY_ITEM_MASTER";
export const CATEGORY_ITEM_LIST_FAIL = "CATEGORY_ITEM_LIST_FAIL";

export const CATEGORY_ITEM_CREATE = "CATEGORY_ITEM_CREATE";
export const CATEGORY_ITEM_UPDATE = "CATEGORY_ITEM_UPDATE";
export const CATEGORY_ITEM_DELETE = "CATEGORY_ITEM_DELETE";
export const CATEGORY_ITEM_ENABLE_DISABLE = "CATEGORY_ITEM_ENABLE_DISABLE";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";

export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_CREATE = "PRODUCT_CREATE";
export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const PRODUCT_DELETE = "PRODUCT_DELETE";
export const PRODUCT_ENABLE_DISABLE = "PRODUCT_ENABLE_DISABLE";
export const PRODUCT_CLEAR_FORM = "PRODUCT_CLEAR_FORM";

export const SET_STORE_ID = "SET_STORE_ID";
export const CLEAR_STORE_ID = "CLEAR_STORE_ID";

export const VADHUVAR_LIST_REQUEST = "VADHUVAR_LIST_REQUEST";
export const VADHUVAR_LIST_SUCCESS = "VADHUVAR_LIST_SUCCESS";
export const VADHUVAR_LIST_FAIL = "VADHUVAR_LIST_FAIL";
export const VADHUVAR_LIST_REFRESH = "VADHUVAR_LIST_REFRESH";
export const VADHUVAR_WISH_LIST_UPDATE = "VADHUVAR_WISH_LIST_UPDATE";
export const VADHUVAR_LIST_CLEAR = "VADHUVAR_LIST_CLEAR";

export const VADHUVAR_LIST_APPROVAL_REQUEST = "VADHUVAR_LIST_APPROVAL_REQUEST";
export const VADHUVAR_LIST_APPROVAL_SUCCESS = "VADHUVAR_LIST_APPROVAL_SUCCESS";
export const VADHUVAR_LIST_APPROVAL_FAIL = "VADHUVAR_LIST_APPROVAL_FAIL";
export const VADHUVAR_LIST_APPROVAL_REFRESH = "VADHUVAR_LIST_APPROVAL_REFRESH";
export const VADHUVAR_WISH_APPROVAL_LIST_UPDATE =
  "VADHUVAR_WISH_APPROVAL_LIST_UPDATE";
export const VADHUVAR_LIST_APPROVAL_CLEAR = "VADHUVAR_LIST_APPROVAL_CLEAR";

export const COMMITTEE_MEMBER_LIST_REQUEST = "COMMITTEE_MEMBER_LIST_REQUEST";
export const COMMITTEE_MEMBER_LIST_SUCCESS = "COMMITTEE_MEMBER_LIST_SUCCESS";
export const COMMITTEE_MEMBER_LIST_FAIL = "COMMITTEE_MEMBER_LIST_FAIL";
export const COMMITTEE_MEMBER_LIST_REFRESH = "COMMITTEE_MEMBER_LIST_REFRESH";
export const COMMITTEE_MEMBER_LIST_UPDATE = "COMMITTEE_MEMBER_LIST_UPDATE";
