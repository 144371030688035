import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getContactUsList } from "../../actions/users-action";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(5),
  },
  media: {
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
}));

const ContactUsList = ({ img, title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [items, setItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(1);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const { selected_region } = useSelector(
    (state) => state.userListRegionDataReducers
  );
  var userObject = {};
  const getLoginUserDetails = localStorage.getItem("user");
  if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
    userObject = JSON.parse(getLoginUserDetails);
  }

  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );
  const [user, setUser] = useState(userObject);
  const [loginUser, setLoginUser] = useState({});
  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      getLoginUserInfo();
    }
  }, [refreshKey]);

  const getLoginUserInfo = () => {
    const promiseResult = new Promise((resolve, reject) => {
      const getLoginUserDetails = localStorage.getItem("user");
      if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
        let userObject = {};
        userObject = JSON.parse(getLoginUserDetails);
        resolve(userObject);
      }
    });
    promiseResult.then((result) => {
      setLoginUser(result);
      if (result.roles == "super_admin" || result.roles == "admin") {
        pageLoad();
      } else {
        return navigate("/home");
      }
    });
  };

  const pageLoad = () => {
    let requestData = {};
    getListData(requestData);
  };

  const getListData = (inputData) => {
    dispatch(getContactUsList(inputData, t)).then((data) => {
      setItems(data);
      setUserList(data);
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                return navigate(`/admin`, {});
              }}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button> Contact Us </Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          {/* <Box display="flex" justifyContent="right">
            <Button>Right</Button>
          </Box> */}
        </Grid>
      </Grid>

      <Card className={classes.card}>
        <CardActionArea>
          {/* <CardMedia className={classes.media} image={img} title="My Post" /> */}

          <CardContent>
            {items.map((contact) => (
              <>
                <List
                  sx={{
                    width: "100%",
                    // maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={contact.full_name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                            align="center"
                          >
                            {"Subject : "} {contact.subject_details} {" - "}
                            <br />
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                            align="center"
                          >
                            {"Message : "} {contact.description_details}
                            <br />
                            {"Date : "} {contact.created_at}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <Divider component="li" />
                </List>

                {/* <Typography gutterBottom variant="h5">
                  {contact.full_name}
                </Typography>
                <Typography variant="body2">
                  {contact.mobile_or_email}
                </Typography> */}
              </>
            ))}
          </CardContent>
        </CardActionArea>
        {/* <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              return navigate("/business-details");
            }}
          >
            Details
          </Button>
        </CardActions> */}
      </Card>
    </>
  );
};

export default ContactUsList;
