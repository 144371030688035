import React, { useState, useCallback } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import {
  Bookmark,
  List,
  ExitToApp,
  Home,
  Person,
  PhotoCamera,
  PlayCircleOutline,
  Settings,
  Storefront,
  TabletMac,
} from "@material-ui/icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    color: "white",
    paddingTop: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
      color: "#555",
      border: "1px solid #ece7e7",
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
      cursor: "pointer",
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
  },
  text: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Leftbar = () => {
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    // dispatch(clearMessage());
    dispatch(logout());
    setTimeout(function() {
      navigate("/login");
    }, 1000);
  }, [dispatch]);

  const classes = useStyles();
  let navigate = useNavigate();
  // const logOut = () => {
  //   navigate("/dashboard");
  // };
  return (
    <Container className={classes.container}>
      <Link style={{ textDecoration: "none" }} to={"/dashboard/a"}>
        <div className={classes.item}>
          <Home className={classes.icon} />
          <Typography className={classes.text}> Homepage</Typography>
        </div>
      </Link>
      <Link style={{ textDecoration: "none" }} to={"/dashboard/users"}>
        <div className={classes.item}>
          <Person className={classes.icon} />
          <Typography className={classes.text}>Users</Typography>
        </div>
      </Link>

      <div className={classes.item}>
        <List className={classes.icon} />
        <Typography className={classes.text}>Lists</Typography>
      </div>
      {/* <div className={classes.item}>
        <PhotoCamera className={classes.icon} />
        <Typography className={classes.text}>Camera</Typography>
      </div> */}
      {/* <div className={classes.item}>
        <PlayCircleOutline className={classes.icon} />
        <Typography className={classes.text}>Videos</Typography>
      </div> */}
      <div className={classes.item}>
        <TabletMac className={classes.icon} />
        <Typography className={classes.text}>Apps</Typography>
      </div>
      {/* <div className={classes.item}>
        <Bookmark className={classes.icon} />
        <Typography className={classes.text}>Collections</Typography>
      </div> */}
      <div className={classes.item}>
        <Storefront className={classes.icon} />
        <Typography className={classes.text}>Market Place</Typography>
      </div>
      <div className={classes.item}>
        <Settings className={classes.icon} />
        <Typography className={classes.text}>Settings</Typography>
      </div>
      <Link style={{ textDecoration: "none" }} to={"/login"} onClick={logOut}>
        <div className={classes.item}>
          <ExitToApp className={classes.icon} />
          <Typography className={classes.text}>Logout</Typography>
        </div>
      </Link>
    </Container>
  );
};

export default Leftbar;
