import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { Cancel } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { SRLWrapper } from "simple-react-lightbox";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { red } from "@mui/material/colors";
import CropEasy from "../crop/CropEasy";
import fileUploderServices from "../../services/file-uploder-services";
import {
  Grid,
  Paper,
  makeStyles,
  Avatar,
  Typography,
  CardContent,
  Button,
  CardActions,
  CardMedia,
  LinearProgress,
  Box,
  Toolbar,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import { enableDisable } from "../../actions/committee-member-action";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Divider from "@mui/material/Divider";

//import configData from "../../config/configData.json";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import * as appConstant from "../../helpers/appConstant";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

//import { updateProfilePhoto } from "../../actions/committee-member-action";
const useStyles = makeStyles((theme) => ({
  topGridMargin: {
    // marginTop: 2,
    // flexGrow: 1,
  },
  root: {
    marginTop: "10px",
    // flexGrow: 1,
  },

  paper: {
    // height: 220,
    // width: 275,
    // backgroundColor: "#ebebeb",
  },
  avatarImage: {
    width: 200,
    height: 200,
    borderRadius: 10,
  },
}));

export default function View() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const memberObjectLocation = useLocation().state;
  const [memberObject, setMemberObject] = React.useState(
    memberObjectLocation.data
  );
  const imgPath = "committee/get-member-image";
  const default_profile_images = IMAGE_API_URL + imgPath;

  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [progressFlag, setProgressFlag] = useState(false);
  const [fileUploaded, setFileUploaded] = useState({});
  const [isChangeProfilePhoto, setIsChangeProfilePhoto] = useState(false);
  const colorRed = red["500"];
  const [actionMessage, setActionMessage] = React.useState({});
  const [open, setOpen] = React.useState(false);
  useEffect(() => {}, []);
  const classes = useStyles();
  // const navigate = useNavigate();
  const handleChooseFileChange = (e) => {
    const file = e.target.files[0];
    var pattern = /image-*/;
    if (!file.type.match(pattern)) {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: "Please select image only.",
        })
      );
      return;
    } else {
      setIsChangeProfilePhoto(true);
      setProgress(0);
      if (file) {
        setFile(file);
        setPhotoURL(URL.createObjectURL(file));
        setOpenCrop(true);
      }
    }
  };

  const handleFileUpload = (e) => {
    let currentSelectedFile = file;
    setProgressFlag(true);
    setCurrentFile(currentSelectedFile);
    let data = {
      committee_member_id: memberObject.id,
      actionType: "update_committee_member",
      image_path: memberObject.image_path,
    };
    fileUploderServices
      .uploadCommitteeMemberProfilePhoto(currentSelectedFile, data, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then((response) => {
        dispatch({
          type: "COMMITTEE_MEMBER_LIST_REFRESH",
        });

        setProgressFlag(false);
        dispatch(
          showSuccessSnackbar({
            status: response.data.status,
            message: response.data.data[0].message,
          })
        );

        handleFileUploadCancle();
        return navigate(memberObjectLocation.parentRoute);
      })
      .catch((err) => {
        dispatch(
          showSuccessSnackbar({
            status: "error",
            message: "Error occured while crop, please try again later",
          })
        );
      });
  };

  const handleFileUploadCancle = () => {
    setFile(null);
    setPhotoURL("");
    setIsChangeProfilePhoto(false);
  };
  const action = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const submitActionData = () => {
    let data = {
      committee_member_id: memberObject.id,
      acton_name: actionMessage.action,
      member_name: memberObject.first_name + " " + memberObject.last_name,
      action_type: actionMessage.actionType,
    };
    dispatch(enableDisable(data))
      .then((res) => {
        return navigate(memberObjectLocation.parentRoute);
      })
      .catch(() => {
        setOpen(false);
      });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid></Grid>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12} md={12}>
                {actionMessage.confirmation}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<HighlightOffIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={submitActionData}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Box>
        <Grid
          alignContent="center"
          lg={12}
          style={{
            background: "#cfd8dc",
          }}
        >
          <Toolbar>
           
            <Typography
              align="center"
              component="div"
              color="primary"
              sx={{ flexGrow: 1 }}
            >
              {t("matrimony_details_page.title")}
            </Typography>
          </Toolbar>
        </Grid>
      </Box> */}

      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                return navigate(memberObjectLocation.parentRoute);
              }}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button> {t("matrimony_details_page.title")}</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right">
            {memberObject.is_edit ? (
              <>
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    return navigate(`/committee-member-create`, {
                      state: {
                        data: memberObjectLocation,
                        childRoute: window.location.pathname,
                      },
                    });
                  }}
                >
                  Edit
                </Button>
              </>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid item className={classes.root} lg={12} xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid key={1} item lg={8} xs={12} md={12}>
            <Paper className={classes.paper} elevation={2}>
              {/* <CardActions>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    return navigate(memberObjectLocation.parentRoute);
                  }}
                >
                  Back
                </Button>
              </CardActions> */}

              <Grid container direction="column" alignItems="center">
                {isChangeProfilePhoto == false ? (
                  <>
                    <SRLWrapper>
                      <Avatar
                        alt={memberObject.first_name}
                        className={classes.avatarImage}
                        src={
                          default_profile_images +
                          memberObject.appended_image_path
                        }
                      />
                    </SRLWrapper>
                  </>
                ) : (
                  ""
                )}

                {memberObject.is_edit ? (
                  <>
                    {" "}
                    {openCrop ? (
                      <CropEasy
                        {...{ photoURL, setOpenCrop, setPhotoURL, setFile }}
                      />
                    ) : (
                      <>
                        <Grid item lg={12} xs={12} md={12}>
                          {progressFlag ? (
                            <>
                              {" "}
                              <Grid item lg={12}>
                                {currentFile && (
                                  <>
                                    <Box sx={{ width: "100%" }}>
                                      <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                      />
                                    </Box>
                                  </>
                                )}
                              </Grid>{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid
                          container
                          spacing={2}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item lg={12}>
                            <label htmlFor="btn-upload">
                              <input
                                id="btn-upload"
                                name="btn-upload"
                                style={{ display: "none" }}
                                type="file"
                                onChange={handleChooseFileChange}
                              />
                              <Button
                                color="primary"
                                startIcon={<PhotoCameraIcon />}
                                className="btn-choose"
                                variant="outlined"
                                component="span"
                                size="small"
                                style={{ marginTop: 5 }}
                              >
                                Change photo
                              </Button>
                            </label>
                          </Grid>

                          {photoURL ? (
                            <>
                              <Grid item lg={12}>
                                <SRLWrapper>
                                  <CardMedia
                                    component="img"
                                    sx={{ borderRadius: "10%" }}
                                    image={photoURL}
                                    height="225"
                                  />
                                </SRLWrapper>
                              </Grid>

                              <Grid item lg={12}>
                                <CardActions>
                                  <Button
                                    startIcon={<CloudUploadIcon />}
                                    className="btn-choose"
                                    variant="outlined"
                                    component="span"
                                    size="small"
                                    color="primary"
                                    onClick={handleFileUpload}
                                  >
                                    Upload
                                  </Button>

                                  <Button
                                    startIcon={<Cancel />}
                                    className="btn-choose"
                                    variant="outlined"
                                    component="span"
                                    color="secondary"
                                    size="small"
                                    onClick={handleFileUploadCancle}
                                  >
                                    cancel
                                  </Button>
                                </CardActions>
                              </Grid>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Grid>
              <CardContent>
                <Typography
                  align="center"
                  gutterBottom
                  variant="headline"
                  component="h4"
                >
                  {memberObject.mr_ms +
                    " " +
                    memberObject.first_name +
                    " " +
                    memberObject.last_name}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color="text.secondary"
                >
                  {memberObject.designation} <br />
                  {t("committee_member_details_page.lbl_mobile")} :{" "}
                  {memberObject.mobile_number} <br />
                </Typography>

                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12} md={12}>
                    <Divider>
                      <Chip
                        label={t("committee_member_details_page.lbl_details")}
                      />
                    </Divider>
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("committee_member_details_page.lbl_occupation")}
                    </Typography>
                    <Typography variant="body2">
                      {memberObject.occupation}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("committee_member_details_page.lbl_working_address")} :
                    </Typography>
                    <Typography variant="body2">
                      {memberObject.working_address}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("committee_member_details_page.lbl_current_address")} :
                    </Typography>
                    <Typography variant="body2">
                      {memberObject.current_address}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("committee_member_details_page.lbl_permanent_address")}{" "}
                      :
                    </Typography>
                    <Typography variant="body2">
                      {memberObject.permanant_address}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              {memberObject.is_approval ? (
                <>
                  {" "}
                  <CardActions>
                    <Button
                      size="small"
                      startIcon={<AddTaskIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        let statuname =
                          memberObject.row_status == 1 ? "deactive" : "active";

                        let message =
                          "Are you sure you want to " + statuname + " ?";
                        let data = {
                          action:
                            memberObject.row_status == 1
                              ? appConstant.ACTION_DISABLED
                              : appConstant.ACTION_ENABLED,
                          confirmation: message,
                          actionType: appConstant.ACTIONT_TYPE_ENABLE_DISABLE,
                        };
                        setActionMessage(data);
                        action();
                      }}
                    >
                      {memberObject.row_status == 1 ? "Active" : "Deactive"}
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                      onClick={() => {
                        let data = {
                          action: appConstant.ACTION_DELETED,
                          confirmation: "Are you sure you want to delete ?",
                          actionType: appConstant.ACTIONT_TYPE_ENABLE_DISABLE,
                        };
                        setActionMessage(data);
                        action(appConstant.ACTION_DELETED);
                      }}
                    >
                      Delete
                    </Button>
                  </CardActions>{" "}
                </>
              ) : (
                ""
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
