import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import SimpleReactLightbox from "simple-react-lightbox";
import { Provider } from "react-redux";
import store from "./store";
import { createBrowserHistory } from "history";
import httpService from "./middleware/InjectAxiosInterceptors";

const history = createBrowserHistory();
httpService.setupInterceptors(store, history);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

//ReactDOM.render(<App />, document.getElementById("root"));
